// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import { Icon } from '../Icon'
// utils.
import { theme } from '../../styleguide'

/*****************************/
/*                           */
/*    List Item Component    */
/*                           */
/*****************************/

// partials.
const Index = styled(Body)({
  alignItems: 'center',
  border: (props) => !props.isLast && `1px solid ${theme.colors.ash}`,
  borderRadius: '50%',
  height: '32px',
  justifyContent: 'center',
  lineHeight: '32px',
  minWidth: '32px',
  width: '32px',
})

const StyledStatus = styled(Body)(
  {
    minWidth: '104px',
  },
  `@media print { min-width: 80px; }`
)

const StyledBody = styled(Body)({
  flexGrow: 1,
})

const StyledBodyWrapper = styled.div(
  {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-start',
    minWidth: '224px',
  },
  `@media print { min-width: 168px; }`
)

const StyledList = styled.div({
  alignItems: 'center',
  borderBottom: (props) => !props.isLast && `1px solid ${theme.colors.ash}`,
  display: 'flex',
  gap: '16px',
  padding: '8px 0',
  width: '100%',
})

const IconWrapper = styled.button({
  alignItems: 'center',
  backgroundColor: (props) => theme.colors[props.color || 'primary'],
  borderRadius: '50%',
  border: 0,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.normal})`,
  height: '32px',
  justifyContent: 'center',
  minHeight: '32px',
  minWidth: '32px',
  width: '32px',
})

// main component.
const Item = ({ index, status, copy, date, icon, isLast }) => {
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <StyledList isLast={isLast}>
      {index && <Index align="center">{index}</Index>}
      {status && <StyledStatus>{status}</StyledStatus>}
      {copy && <StyledBody>{copy}</StyledBody>}
      {date && (
        <StyledBodyWrapper>
          <Icon name="calendar" size={16} />
          <Body>{date}</Body>
        </StyledBodyWrapper>
      )}
      {icon && (
        <IconWrapper color={icon.color}>
          <Icon name={icon.name} color="white" size={16} />
        </IconWrapper>
      )}
    </StyledList>
  )
}

export default Item
