export const sortingUsers = (items, direction, sortDbKey) => {
  const sortedData = [...items]
  if (direction === null) return sortedData

  const isAscending = direction === 'caret-down'

  sortedData.sort((a, b) => {
    let aValue
    let bValue
    switch (sortDbKey) {
      default:
        aValue = a[sortDbKey]
        bValue = b[sortDbKey]
    }
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return isAscending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
    } else {
      return isAscending ? aValue - bValue : bValue - aValue
    }
  })
  return sortedData
}
