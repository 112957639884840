// dependencies.
import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
import versionInfo from './version.json'

// components.
import {
  Activate,
  ActivateOperator,
  AdminHome,
  AdminSettings,
  AdminAnomalies,
  AdminBudgets,
  AdminCards,
  Reset,
  Signin,
  Signup,
  Splash,
  AdminLocations,
  QrDestination,
  Reports,
  Users,
} from './screens'
// utils.
import { useDimensions } from './js/hooks'
import { useAppSelector } from './js/store/hooks'
// constants.
import { DEV_NAVIGATION_ITEMS, NAVIGATION_ITEMS, SIGN_UP_ENABLED } from './constants'
import { selectUserData } from './js/auth/authSelectors'
import { useState, useEffect } from 'react'
import { getCookie, setCookie } from './js/cookies'

/************************/
/*                      */
/*    App and Screen    */
/*                      */
/************************/

// partials:
const Wrapper = styled.div(
  {
    height: (props) => `${props.height}px`,
    width: (props) => `${props.width}px`,
  },
  `@media print { height: auto; width: auto; }`
)

// main component:
const App = () => {
  // hooks.
  const userData = useAppSelector(selectUserData)
  const dimensions = useDimensions()
  const [reloadApp, setReloadApp] = useState(false)
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build navigation menues.
  const NAV_ITEMS = (panel) => {
    if (!panel || userData?.access === 'developer') return DEV_NAVIGATION_ITEMS
    else return NAVIGATION_ITEMS[panel]
  }

  useEffect(() => {
    const currentVersion = versionInfo.version
    const storedVersion = getCookie('appVersion')

    if (!storedVersion || storedVersion !== currentVersion) {
      // Si la versión almacenada no coincide con la actual, hacer un hard refresh
      setCookie('appVersion', currentVersion, 30)
      if (!storedVersion) {
        window.location.reload(true)
      } else {
        setReloadApp(true)
      }
    } else {
      // Si la versión coincide
      setCookie('appVersion', currentVersion, 30)
    }
  }, [])

  // return content.
  return reloadApp ? (
    <div id="reload-app">
      <p>
        Hay una nueva versión disponible, <br />
        por favor recargue la aplicación.
      </p>
      <button onClick={() => window.location.reload(true)}>Recargar App</button>
    </div>
  ) : (
    <Wrapper {...dimensions}>
      <Routes>
        {/* Splash Screen */}
        <Route path="/" element={<Splash />} />
        <Route path="/redirect/:locationId" element={<QrDestination />} />

        {/* Auth Screens */}
        <Route path="/activar" element={<Activate />} />
        <Route path="/activar-funcionario" element={<ActivateOperator />} />

        <Route path="/forgot-password" element={<Reset />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />

        {SIGN_UP_ENABLED && <Route path="/signup" element={<Signup />} />}

        {/* Superadmin Screens */}
        <Route path="/admin">
          <Route
            index
            element={
              <AdminHome navMenu={NAV_ITEMS('admin')} navTitle="Inicio" />
              //
            }
          />
          <Route
            path="/admin/:locationId"
            element={
              <AdminHome navMenu={NAV_ITEMS('admin')} navTitle="Inicio" />
              //
            }
          />
          <Route
            path="/admin/ajustes"
            element={
              <AdminSettings navMenu={NAV_ITEMS('admin')} navTitle="Ajustes" />
              //
            }
          />

          <Route
            path="/admin/usuarios"
            element={
              <Users navMenu={NAV_ITEMS('admin')} navTitle="Usuarios" />
              //
            }
          />

          <Route
            path="/admin/anomalias"
            element={
              <AdminAnomalies navMenu={NAV_ITEMS('admin')} navTitle="Anomalías" />
              //
            }
          />

          <Route
            path="/admin/presupuestos"
            element={
              <AdminBudgets navMenu={NAV_ITEMS('admin')} navTitle="Presupuestos" />
              //
            }
          />

          <Route
            path="/admin/reportes"
            element={
              <Reports navMenu={NAV_ITEMS('admin')} navTitle="Reportes" />
              //
            }
          />

          {/* PLANTAS */}
          <Route
            path="/admin/ubicaciones"
            element={
              <AdminLocations navMenu={NAV_ITEMS('admin')} navTitle="Plantas" />
              //
            }
          />
          {/* SUB UBICACIONES */}
          <Route
            path="/admin/ubicaciones/:locationId"
            element={
              <AdminLocations navMenu={NAV_ITEMS('admin')} navTitle="Ubicaciones" />
              //
            }
          />

          <Route
            path="/admin/tarjetas"
            element={
              <AdminCards navMenu={NAV_ITEMS('admin')} navTitle="Tarjetas" />
              //
            }
          />
        </Route>
        <Route path="/funcionario">
          <Route
            index
            element={
              <AdminHome navMenu={NAV_ITEMS('operator')} navTitle="Inicio" />
              //
            }
          />
          <Route
            path="/funcionario/:locationId"
            element={
              <AdminHome navMenu={NAV_ITEMS('operator')} navTitle="Tarjetas" />
              //
            }
          />
          <Route
            path="/funcionario/ajustes"
            element={
              <AdminSettings navMenu={NAV_ITEMS('operator')} navTitle="Ajustes" />
              //
            }
          />
        </Route>
      </Routes>
    </Wrapper>
  )
}

export default App
