// dependencies.
import styled from 'styled-components'
// components.
import { Button } from '../../components/Button'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'
import { useDimensions, useWindowEvent } from '../../js/hooks'

/*******************************/
/*                             */
/*    Wrapper Box Component    */
/*                             */
/*******************************/

// helpers.
const topbar = {
  m: 48,
  d: 72,
}

// partials.
const BoxWrapper = styled.div(
  {
    alignItems: 'center',
    background: (props) => props.background,
    borderRadius: '16px 16px 0 0',
    boxShadow: theme.shadows.normal,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: (props) => (props.hasWrapperSibling ? 0 : 1),
    margin: (props) => (props.hasWrapperSibling ? '0 0 -24px' : 0),
    maxHeight: (props) =>
      props.isMobile ? `${props.height - topbar.m}px` : `${props.height - topbar.d}px`,
    padding: (props) => (props.isMobile ? '24px 16px 8px' : '24px 16px 48px'),
    position: 'relative',
    top: (props) => (props.isMobile ? `${topbar.m}px` : `${topbar.d}px`),
    width: '100%',
  },
  `@media print { max-height: auto; }`
)

const Content = styled.div({
  flex: 1,
  flexDirection: 'column',
  width: '100%',
})

const Actions = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px 24px',
  width: '100%',
})

const StyledButton = styled(Button)({
  width: (props) => (props.isMobile ? '100%' : '240px'),
})

// main component.
const Wrapper = ({ className, background, hasWrapperSibling, actionLabel, children, onClick }) => {
  // hooks.
  const { height, isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle wrapper acttion click..
  const handleClick = () => {
    if (onClick) onClick()
  }

  // handle onClick on press 'Enter' key.
  const handleEnterKeyDown = (e) => {
    if (e.key === 'Enter') handleClick()
  }
  useWindowEvent('keydown', handleEnterKeyDown)

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const backgroundColor = styleguideHasKey(background, colors, 'smoke')

  // build props object.
  const props = {
    background: colors[backgroundColor],
    className,
    hasWrapperSibling,
    height,
    isMobile,
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <BoxWrapper {...props}>
      <Content>{children}</Content>

      {!!actionLabel && (
        <Actions>
          <StyledButton isMobile={isMobile} disabled={!onClick} onClick={handleClick}>
            {actionLabel}
          </StyledButton>
        </Actions>
      )}
    </BoxWrapper>
  )
}

export default Wrapper
