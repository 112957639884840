import { collection, orderBy, query } from 'firebase/firestore'
import { db } from '../firebase/firebase'
import { createSlice } from '@reduxjs/toolkit'
import subscribeOnSnapshot from '../hooks/subscribeOnSnapshot'
import { createFetchThunk } from '../store/fetch'
const access = ['superadmin', 'operator', 'developer']
const q = query(collection(db, 'locations'), orderBy('name', 'asc'))

export const fetchLocations = createFetchThunk('locations', q, access)

export const subscribeToLocations = () => (dispatch, getState) => {
  const userData = getState().user.userData
  return subscribeOnSnapshot(q, access, userData, dispatch, locationsUpdated, 'locations')
}

const locationsSlice = createSlice({
  name: 'locations',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    locationsUpdated: (state, action) => {
      state.data = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const { locationsUpdated } = locationsSlice.actions
export default locationsSlice.reducer
