import Search from '../../Filters/Search'

// main component.
const SearchCardsById = ({ onSearch = () => {}, defaultValue = '' }) => {
  // return content.
  return (
    <Search
      label="Buscar por número de tarjeta"
      placeholder="Número..."
      defaultValue={defaultValue}
      onChange={(term) => onSearch(term)}
    />
  )
}

export default SearchCardsById
