// dependencies.
import styled from 'styled-components'
// components.
import { Body, Pill } from '../../../Typography'
// utils.
import cardStatusFormat from '../../../../js/cardStatusFormat'
import otStatusFormat from '../../../../js/reportOtStatus'
import dateFormat from '../../../../js/dateFormat'
import { getNameFull } from '../../../../js/usernameUtils'
import { theme } from '../../../../styleguide'
// constants.
import { DATE_TIME_FORMAT } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

const getCardInfo = {
  TM: theme.cards.tm,
  TO: theme.cards.to,
  TQ: theme.cards.tq,
  TS: theme.cards.ts,
}

export const COLUMN_LAYOUT = [
  { label: 'Número', dbKey: 'id', columns: 3, minWidth: 150 },
  { label: 'Tipo', dbKey: 'type', columns: 2, minWidth: 100 },
  { label: 'Ubicación', dbKey: 'locationRef', columns: 3, minWidth: 150 },
  { label: 'Responsable', dbKey: 'responsibleRef', columns: 4, minWidth: 200 },
  { label: 'Anomalía', dbKey: 'anomaliesRefs', columns: 4, minWidth: 200 },
  { label: 'Tópico/Hallazgo', dbKey: 'description', columns: 4, minWidth: 200 },
  { label: 'Acción Propuesta', dbKey: 'proposedImprovement', columns: 4, minWidth: 200 },
  { label: 'Acción Realizada', dbKey: 'improvementMade', columns: 4, minWidth: 200 },
  { label: 'Fecha Inicio', dbKey: 'createdDate', columns: 3, minWidth: 150 },
  { label: 'Fecha Compromiso', dbKey: 'extremeDatePlanDate', columns: 3, minWidth: 150 },
  { label: 'Fecha Cierre Real', dbKey: 'extremeDateReal', columns: 3, minWidth: 150 },
  { label: 'T/Cierre', dbKey: 'extremeDateReal', columns: 2, minWidth: 100 },
  { label: 'Estado', dbKey: 'status', columns: 2, minWidth: 100 },
  { label: 'Levantada por', dbKey: 'createdByRef', columns: 4, minWidth: 200 },
  { label: 'OT', dbKey: 'otStatus', columns: 2, minWidth: 100 },
  { label: 'MTTR', dbKey: 'mttr', columns: 2, minWidth: 100 },
  { label: 'Comentario', dbKey: 'comments', columns: 5, minWidth: 250 },
]

const totalColumns = COLUMN_LAYOUT.reduce((acc, column) => acc + column.columns, 0)
export const CELL_WIDTH = 100 / totalColumns

// partials.
const Wrapper = styled.li({
  backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
  borderBottom: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: baseUnit * 2.5,
  transition: 'background 200ms ease',
  width: '100%',
  '&:focus': {
    backgroundColor: theme.opacity.primary20,
    outline: 'none',
  },
})

const Cell = styled.div({
  alignItems: 'center',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  minWidth: (props) => `${props.minWidth}px`,
  padding: `${baseUnit / 4}px ${baseUnit / 4}px`,
  width: (props) => `${props.width}%`,
})

const StyledType = styled(Body)({
  '&:before': {
    backgroundColor: (props) => props.background,
    borderRadius: baseUnit / 4,
    content: '""',
    display: 'inline-block',
    filter: `drop-shadow(${theme.shadows.smaller})`,
    height: baseUnit / 2,
    marginRight: baseUnit / 4,
    width: baseUnit / 2,
  },
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
  maxWidth: '100%',
})

// main component.
const ReportsDesktopItem = ({ item, anomalies, isEven }) => {
  // only show if there's items.
  if (!item) return null

  const {
    anomaliesRefs,
    author,
    comments,
    createdDate,
    description,
    extremeDatePlanDate,
    extremeDateReal,
    id,
    improvementMade,
    location,
    mttr,
    otStatus,
    proposedImprovement,
    responsible,
    status,
    type,
  } = item

  const matchedAnomalies = anomalies.filter((anomaly) =>
    anomaliesRefs.some((ref) => anomaly.name === ref.id)
  )

  // return content.
  return (
    <Wrapper isEven={isEven} tabIndex={0}>
      <Cell minWidth={COLUMN_LAYOUT[0].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[0].columns}>
        <StyledBody size="smallest" nowrap>
          {id}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[1].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[1].columns}>
        <StyledType background={getCardInfo[type]} size="smaller" nowrap>
          {type}
        </StyledType>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[2].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[2].columns}>
        <StyledBody size="smallest" align="center">
          {location}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[3].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[3].columns}>
        <StyledBody size="smallest" nowrap>
          {getNameFull(responsible?.firstname, responsible?.lastname)}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[4].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[4].columns}>
        {matchedAnomalies.length > 0 &&
          matchedAnomalies.map((anomaly, index) => (
            <StyledBody key={index} size="smallest" align="center" nowrap>
              {anomaly.label}
            </StyledBody>
          ))}
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[5].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[5].columns}>
        <StyledBody size="smallest" align="center">
          {description}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[6].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[6].columns}>
        <StyledBody size="smallest" align="center">
          {proposedImprovement}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[7].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[7].columns}>
        <StyledBody size="smallest" align="center">
          {improvementMade}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[8].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[8].columns}>
        <StyledBody size="smallest" nowrap>
          {dateFormat(createdDate, DATE_TIME_FORMAT)}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[9].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[9].columns}>
        <StyledBody color={!extremeDatePlanDate && 'grey'} size="smallest" nowrap>
          {extremeDatePlanDate ? dateFormat(extremeDatePlanDate) : ''}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[10].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[10].columns}>
        <StyledBody color={!extremeDateReal && 'grey'} size="smallest" nowrap>
          {extremeDateReal ? dateFormat(extremeDateReal) : ''}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[11].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[11].columns}>
        {extremeDateReal ? <Pill type={2}>OK</Pill> : null}
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[12].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[12].columns}>
        <Pill type={status}>{cardStatusFormat(status)}</Pill>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[13].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[13].columns}>
        <StyledBody size="smallest" nowrap>
          {getNameFull(author?.firstname, author?.lastname) || 'Anónimo'}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[14].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[14].columns}>
        {otStatus ? <Pill type={otStatus}>{otStatusFormat(otStatus)}</Pill> : null}
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[15].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[15].columns}>
        <StyledBody color={mttr === '--' && 'ash'} size="smaller" nowrap>
          {mttr}
        </StyledBody>
      </Cell>

      <Cell minWidth={COLUMN_LAYOUT[16].minWidth} width={CELL_WIDTH * COLUMN_LAYOUT[16].columns}>
        <StyledBody size="smallest" align="center">
          {comments}
        </StyledBody>
      </Cell>
    </Wrapper>
  )
}

export default ReportsDesktopItem
