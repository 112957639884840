export const usersFilterByName = (array, searchTerm) => {
  if (!array) return []

  // Normalizar y transformar el término de búsqueda una vez
  const normalizedSearchTerm = searchTerm
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase()

  return array.filter((user) => {
    const normalizedFullName = (user.firstname + ' ' + user.lastname)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase()

    const normalizedDocument = user.document.toString().toUpperCase()

    const normalizedEmail = user.email.toString().toUpperCase()

    return (
      normalizedFullName.includes(normalizedSearchTerm) ||
      normalizedDocument.includes(normalizedSearchTerm) ||
      normalizedEmail.includes(normalizedSearchTerm)
    )
  })
}
