// dependencies.
import styled from 'styled-components'
// components.
import Header from './Header'
import Item from './Item'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

/*************************/
/*                       */
/*    Table Component    */
/*                       */
/*************************/

// partials.
const ScrollWrapper = styled.ul(
  {
    margin: '0 -16px',
    overflowX: 'scroll',
    width: 'calc(100% + 32px)',
  },
  `@media print { margin: 0; width: 100%; }`
)

const ContentWrapper = styled.ul({
  backgroundColor: theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  margin: 0,
  minWidth: '100%',
  padding: 0,
  width: 'fit-content',
})

// main component.
const Table = ({
  header = [],
  items = [],
  //
  onDetail = null,
  //
  enableRequest,
  onRequest = null,
  //
  enableEdit,
  onEdit = null,
  //
  enableCancel,
  onCancel = null,
  //
  enableDelete,
  onDelete = null,
  //
  onFocus = () => {},
  //
  enableShowQR,
  onShowQR = null,
  //
  enableChangeResponsible,
  onChangeResponsible = null,
}) => {
  // hooks.
  const { isMobile } = useDimensions()

  const handleItemFocus = (bool, obj) => {
    if (bool) return onFocus(bool, obj)
    else return onFocus(bool, {})
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <ScrollWrapper>
      {!isMobile && <Header items={header} />}
      <ContentWrapper>
        {items.length === 0 ? (
          <Item
            key={0}
            items={[{ copy: 'No se encontraron resultados.', grow: 2 }]}
            isMobile={isMobile}
            isEven
            isLast
          />
        ) : (
          items?.map((subItem, i) => (
            <Item
              key={i}
              items={subItem.items}
              //
              isMobile={isMobile}
              isEven={i % 2 === 0}
              isLast={i === items.length - 1}
              //
              onDetail={onDetail && (() => onDetail(subItem.raw))}
              //
              disableRequest={subItem.disableRequest}
              enableRequest={enableRequest}
              onRequest={onRequest && (() => onRequest(subItem.raw))}
              //
              disableEdit={subItem.disableEdit}
              enableEdit={enableEdit}
              onEdit={onEdit && (() => onEdit(subItem.raw))}
              //
              disableCancel={subItem.disableCancel}
              enableCancel={enableCancel}
              onCancel={onCancel && (() => onCancel(subItem.raw))}
              //
              disableDelete={subItem.disableDelete}
              enableDelete={enableDelete}
              onDelete={onDelete && (() => onDelete(subItem.raw))}
              //
              onFocus={(bool) => handleItemFocus(bool, subItem.raw)}
              //
              enableShowQR={enableShowQR}
              onShowQR={onShowQR && (() => onShowQR(subItem.raw))}
              //
              disableChangeResponsible={subItem.disableChangeResponsible}
              enableChangeResponsible={enableChangeResponsible}
              onChangeResponsible={onChangeResponsible && (() => onChangeResponsible(subItem.raw))}
            />
          ))
        )}
      </ContentWrapper>
    </ScrollWrapper>
  )
}

export default Table
