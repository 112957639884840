import { useState } from 'react'
import useWindowEvent from './useWindowEvent'

const useHideOnScroll = (offset = 200) => {
  const [isVisible, setIsVisible] = useState(true)
  const [height, setHeight] = useState(0)

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    setHeight(winScroll)

    if (winScroll > offset) {
      isVisible && setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  useWindowEvent('scroll', listenToScroll)

  return { height, isVisible }
}

export default useHideOnScroll
