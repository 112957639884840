// dependencies.
import { useState } from 'react'
import styled from 'styled-components'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'

/***************************************/
/*                                     */
/*    Hamburger Menu Icon Component    */
/*                                     */
/***************************************/

// helpers.
const UNIT_SIZE = 32

// partials.
const Bar = styled.span({
  background: (props) => props.color,
  display: (props) => props.display || 'block',
  height: (props) => `${props.size / 12}px`,
  left: (props) => `${props.left}px`,
  objectFit: 'cover',
  position: 'absolute',
  top: (props) => `${props.top}px`,
  width: (props) => `${props.size}px`,
  transform: (props) => `rotate(${props.deg || 0}deg)`,
  transition: 'all 200ms ease',
})

const Wrapper = styled.i({
  display: 'inline-block',
  height: (props) => `${props.size}px`,
  overflow: 'hidden',
  position: 'relative',
  width: (props) => `${props.size}px`,
})

const Menu = ({ size = UNIT_SIZE, color, onClick }) => {
  // state.
  const [active, setActive] = useState(false)

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle check / uncheck click.
  const handleClick = () => {
    setActive(!active)
    onClick(!active)
  }

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const iconColor = styleguideHasKey(color, colors, 'white')

  // move sprite to show desired icon.
  const position = {
    t: {
      left: active ? 0 : 0,
      top: active ? size / 8 + size / 3 : size / 8,
      deg: active ? 45 : 0,
    },
    m: {
      display: active ? 'none' : 'block',
      left: active ? 0 : 0,
      top: active ? size / 2 - size / 24 : size / 2 - size / 24,
    },
    b: {
      left: active ? 0 : 0,
      top: active ? size - size / 12 - size / 8 - size / 3 : size - size / 12 - size / 8,
      deg: active ? -45 : 0,
    },
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper size={size} onClick={() => handleClick()}>
      <Bar size={size} color={colors[iconColor]} {...position?.t} />
      <Bar size={size} color={colors[iconColor]} {...position?.m} />
      <Bar size={size} color={colors[iconColor]} {...position?.b} />
    </Wrapper>
  )
}

export default Menu
