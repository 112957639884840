import { collection, limit, orderBy, query, where } from 'firebase/firestore'
import { db } from '../firebase/firebase'
import { createSlice } from '@reduxjs/toolkit'
import { createFetchThunk } from '../store/fetch'
import { dateFormatUS } from '../dateFormat'

const access = ['superadmin', 'operator', 'developer']

const q = query(collection(db, 'cards'), orderBy('createdDate', 'desc'))
const closedCardsQuery = query(
  collection(db, 'cards'),
  where('status', '==', 2),
  orderBy('createdDate', 'desc'),
  limit(99)
)

const allClosedCardsQuery = query(
  collection(db, 'cards'),
  where('status', '==', 2),
  orderBy('createdDate', 'desc')
)
// Consulta para todas las tarjetas abiertas
const openCardsQuery = query(
  collection(db, 'cards'),
  where('status', '<', 2),
  orderBy('status', 'asc'),
  orderBy('createdDate', 'desc')
)

const today = new Date()
const beginMonth = new Date(today.getFullYear(), today.getMonth(), 1)
// const twoMonthAgo = new Date(new Date().setMonth(today.getMonth() - 2))
export const fetchCards = createFetchThunk('cards', q, access)
export const fetchClosedCards = createFetchThunk('closedCards', closedCardsQuery, access)
export const fetchAllClosedCards = createFetchThunk('closedCards', allClosedCardsQuery, access)
export const fetchOpenCards = createFetchThunk('openCards', openCardsQuery, access)

const initialState = {
  // fromDate: dateFormatUS(twoMonthAgo),
  fromDate: dateFormatUS(beginMonth),
  toDate: dateFormatUS(today),
  locationId: '',
  searchTerm: '',
  type: '',
  sort: {
    key: 'createdDate',
    direction: 'caret-up',
  },
  status: '',
  createdBy: '',
  otStatus: '',
}
const cardsSlice = createSlice({
  name: 'cards',
  initialState: {
    data: {
      open: [],
      closed: [],
    },
    status: {
      open: 'idle',
      closed: 'idle',
    },
    error: null,
    filters: {
      ...initialState,
    },
  },
  reducers: {
    cardAdded: (state, action) => {
      state.data.open.push(action.payload)
    },
    openCardsUpdated: (state, action) => {
      state.data.open = action.payload
    },
    closedCardsUpdated: (state, action) => {
      state.data.closed = action.payload
    },
    cardUpdated: (state, action) => {
      const index = state.data.open.findIndex((card) => card.id === action.payload.id)
      if (index !== -1) {
        if (action.payload.status === 2) {
          state.data.closed.push(action.payload)
          state.data.open.splice(index, 1)
        } else {
          state.data.open[index] = action.payload
        }
      }
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    clearFilters: (state) => {
      state.filters = initialState
    },
    removeFilter: (state, action) => {
      const { payload } = action
      if (payload === 'fromDate' || payload === 'toDate') {
        // preserve date filters
        // delete state.filters.fromDate
        // delete state.filters.toDate
      } else {
        delete state.filters[payload]
      }
    },
    reload: (state) => {
      state.status.open = 'idle'
      state.status.closed = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpenCards.pending, (state) => {
        state.status.open = 'loading'
      })
      .addCase(fetchOpenCards.fulfilled, (state, action) => {
        state.status.open = 'succeeded'
        state.data.open = action.payload
      })
      .addCase(fetchOpenCards.rejected, (state, action) => {
        state.status.open = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchClosedCards.pending, (state) => {
        state.status.closed = 'loading'
      })
      .addCase(fetchClosedCards.fulfilled, (state, action) => {
        state.status.closed = 'succeeded'
        state.data.closed = action.payload
      })
      .addCase(fetchClosedCards.rejected, (state, action) => {
        state.status.closed = 'failed'
        state.error = action.error.message
      })
  },
})

export const {
  cardAdded,
  cardUpdated,
  openCardsUpdated,
  closedCardsUpdated,
  setFilters,
  clearFilters,
  removeFilter,
  reload,
} = cardsSlice.actions
export default cardsSlice.reducer
