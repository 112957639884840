// depenmdencies.
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
export const ChartsContainer = styled.div({
  background: theme.colors.smoke,
  borderBottom: `2px solid ${theme.opacity.ash20}`,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: baseUnit,
  height: 'fit-content',
  padding: baseUnit,
})

const ChartCardWrapper = styled.div({
  alignItems: 'center',
  background: theme.colors.white,
  borderRadius: `${baseUnit}px`,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  flexDirection: 'column',
  height: 'fit-content',
  justifyContent: 'center',
  minHeight: baseUnit * 15,
  padding: baseUnit,
  width: (props) => `calc(${props.width} - ${baseUnit}px)` || '100%',
})

export const ChartCard = ({ label, width, children }) => (
  <ChartCardWrapper width={width}>
    {label && (
      <Body type="caption" color="grey">
        {label}
      </Body>
    )}
    {children}
  </ChartCardWrapper>
)
