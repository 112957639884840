// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
// constants.
import { BOTTOMBAR_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const ScrollWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 0,
    minHeight: `calc(100% - ${BOTTOMBAR_HEIGHT + HEADER_HEIGHT_MOBILE}px)`,
    overflowX: 'scroll',
    transition: 'all 200ms ease',
    width: '100%',
  },
  `@media print { margin: 0; width: 100%; }`
)

const ContentWrapper = styled.ul({
  backgroundColor: (props) => (props.isEmpty ? theme.colors.white : theme.colors.smoke),
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  margin: 0,
  minWidth: '100%',
  padding: 0,
  paddingBottom: BOTTOMBAR_HEIGHT + baseUnit / 2,
  paddingTop: baseUnit * 2,
  position: 'relative',
  width: '100%',
})

// main component.
const UsersMobileWrapper = ({ isEmpty, children }) => (
  <ScrollWrapper>
    <ContentWrapper isEmpty={isEmpty}>
      {isEmpty ? (
        <Body color="grey" align="center">
          No se encontraron resultados.
        </Body>
      ) : (
        children
      )}
    </ContentWrapper>
  </ScrollWrapper>
)

export default UsersMobileWrapper
