// dependencies.
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// helpers.
const toastConfig = {
  autoClose: 5000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: true,
  pauseOnHover: true,
  position: 'top-center',
  progress: undefined,
  theme: 'light',
}

// main component.
const notifyToast = (label, variant) => {
  switch (variant) {
    case 'success':
      return toast.success(label, toastConfig)
    case 'warn':
      return toast.warn(label, toastConfig)
    case 'error':
      return toast.error(label, toastConfig)
    default:
      return toast.info(label, toastConfig)
  }
}

export default notifyToast
