// dependencies.
import styled from 'styled-components'
// components.
import Body from './Body'
import Headline from './Headline'

// partials.
const elementStyles = {
  flexGrow: 1,
  whiteSpace: 'wrap',
  width: '50%',
}

const Wrapper = styled.li({
  alignItems: 'flex-start',
  display: 'flex',
  listStyle: 'none',
  margin: 0,
  padding: '4px 0',
})

const Element = ({ color, size, className, children }) => {
  switch (size) {
    case 'larger':
      return (
        <Headline color={color} className={className}>
          {children}
        </Headline>
      )
    default:
      return (
        <Body color={color} type="caption" className={className}>
          {children}
        </Body>
      )
  }
}

const KeyElement = styled(Element)(elementStyles)
const ValElement = styled(Body)(elementStyles)

// main component.
const Item = ({ className, color, label, value, size }) => {
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper className={className}>
      <KeyElement color={color} size={size}>
        {label}
      </KeyElement>
      <ValElement color={color}>{value}</ValElement>
    </Wrapper>
  )
}

export default Item
