// dependencies.
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import QRCode from 'react-qr-code'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Table from '../../components/Table'
import Header from '../../components/Layout/Header'
import BranchSelect from '../../components/Filters/BranchSelect'
import { Button } from '../../components/Button'
import { Drawer } from '../../components/Modal'
import { Breadcrumbs } from '../../components/Layout'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
// utils.
import notifyToast from '../../js/notifyToast'
import { db, getLocationById } from '../../js/firebase/firebase'
import { useDimensions } from '../../js/hooks'
// constants.
import FloatingButton from '../../components/Button/MobileButton'

// constants.
import { NEW_LOCATION_FORM } from './constants'
import {
  makeSelectLocationsForTable,
  selectLocationsTree,
  selectLocationsStatus,
} from '../../js/locations/locationsSelectors'
import { useAppDispatch, useAppSelector } from '../../js/store/hooks'
import { fetchLocations, subscribeToLocations } from '../../js/locations/locationsSlice'
import {
  selectUsersData,
  selectUsersForSelect,
  selectUsersStatus,
} from '../../js/users/usersSelectors'
import { fetchUsers } from '../../js/users/usersSlice'
/********************************/
/*                              */
/*    Admin Locations Screen    */
/*                              */
/********************************/

// helpers.
const tableHeader = [
  { label: 'Nombre', grow: 2 },
  { label: 'Responsable', grow: 2 },
  { label: 'Acciones', grow: 2 },
]

const buildBreadcrumbs = (array, refId, parents = []) => {
  if (!refId) return null

  for (let i = 0; i < array.length; i++) {
    const obj = array[i]

    if (obj.ref && obj.ref.id === refId) {
      parents.push(obj)
      return parents
    }

    if (Array.isArray(obj.children) && obj.children.length > 0) {
      parents.push(obj)
      const res = buildBreadcrumbs(obj.children, refId, parents)

      if (res) {
        return res
      } else {
        parents.pop()
      }
    }
  }

  return null
}

// main component.
const AdminLocationsScreen = ({ navMenu }) => {
  const params = useParams()

  // states.
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [parentRef, setParentRef] = useState(null)
  const [level, setLevel] = useState(1)
  // hooks.
  const { isMobile } = useDimensions()
  const users = useAppSelector(selectUsersData)
  const usersSelect = useAppSelector(selectUsersForSelect)
  const locationsSelectTree = useAppSelector(selectLocationsTree)
  const selectLocationsForTable = makeSelectLocationsForTable(parentRef, users)
  const locationsTable = useAppSelector(selectLocationsForTable)

  // data object.
  const responsiblesSelectOptions = usersSelect
  const locationsTableitems = locationsTable
  const selectItemData = locationsSelectTree

  const dispatch = useAppDispatch()
  const loaded = useAppSelector(selectLocationsStatus)
  const usersStatus = useAppSelector(selectUsersStatus)

  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers()).then(() => {
        // Wait until fetchusers is done
        if (loaded === 'idle') {
          dispatch(fetchLocations())
        }
      })
    } else if (usersStatus === 'succeeded' && loaded === 'idle') {
      dispatch(fetchLocations())
    }
    const unsubscribe = dispatch(subscribeToLocations())

    return () => unsubscribe() // Cleanup subscription on unmount
  }, [dispatch, loaded])

  useEffect(() => {
    const getParent = async () => {
      if (params.locationId) {
        const parent = await getLocationById(params.locationId)

        setBreadcrumbs(buildBreadcrumbs(selectItemData, params.locationId))

        setParentRef(parent.ref)
        setLevel(parent.level + 1)
      } else {
        setBreadcrumbs(buildBreadcrumbs(selectItemData, params.locationId))
        setParentRef(null)
        setLevel(1)
      }
    }

    getParent()
  }, [loaded, params.locationId])

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new location modal.
  const handleNewLocationModal = () => {
    setModalContent({
      title: `Agregar nueva ${pageTitle}`,
      component: (
        <Form
          items={NEW_LOCATION_FORM(responsiblesSelectOptions)}
          onClick={(obj) => handleCreateLocationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected location modal.
  const handleEditLocationModal = (originalObj) => {
    const obj = {
      ...originalObj,
      responsible: originalObj.responsibleRef ? originalObj.responsibleRef.id : null,
    }

    obj.responsible = obj.responsibleRef ? obj.responsibleRef.id : null
    setModalContent({
      title: `Actualizar ${pageTitle}`,
      component: (
        <Form
          items={NEW_LOCATION_FORM(responsiblesSelectOptions)}
          defaultValues={obj}
          onClick={(obj) => handleSaveLocationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  const handleShowLocationQR = (obj) => {
    setModalContent({
      title: `Código QR de ${obj.name}`,
      component: (
        <>
          <div style={{ height: 'auto', margin: '0 auto 40px', width: '60%' }}>
            <QRCode
              size={128}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`${process.env.REACT_APP_AUTH_DOMAIN}/redirect/${obj.ref.id}`}
            />
          </div>
          <Button onClick={() => window.print()}>Imprimir QR</Button>
        </>
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new location.
  const handleCreateLocationClick = async (obj) => {
    let responsibleRef = null
    if (obj.responsible) {
      responsibleRef = doc(db, 'users', obj.responsible)
    }
    const location = {
      name: obj.name,
      responsibleRef,
      level: level,
    }

    let parentId
    if (params.locationId) {
      parentId = params.locationId
    }
    if (parentId) {
      const parent = await getLocationById(parentId)
      if (parent) {
        location.parentRef = parent.ref
      }
    }
    location.createdDate = new Date()
    location.updatedDate = new Date()

    await addDoc(collection(db, 'locations'), location)
      .then(async (locationRef) => {
        await setDoc(locationRef, { ref: locationRef }, { merge: true })
        notifyToast(`${pageTitle} creada correctamente`, 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // handle location information update.
  const handleSaveLocationClick = async (obj) => {
    let responsibleRef = null
    if (obj.responsible) {
      responsibleRef = doc(db, 'users', obj.responsible)
    }
    const updateFields = {
      name: obj.name,
      responsibleRef,
      updatedDate: new Date(),
    }

    const locationRef = doc(db, 'locations', obj.ref.id)
    await setDoc(locationRef, updateFields, { merge: true })
      .then(() => notifyToast(`${pageTitle} guardada correctamente`, 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // Set the title based on the level
  let pageTitle = 'Planta'
  if (level === 1) pageTitle = 'Ubicación'
  if (level === 2) pageTitle = 'Sector'
  if (level === 3) pageTitle = 'Máquina'
  if (level === 4) pageTitle = 'Sistema'
  if (level === 5) pageTitle = 'Componente'

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={pageTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <Header justifyContent="space-between">
          <BranchSelect placeholder="Buscar ubicaciones" items={selectItemData} />
          {isMobile ? (
            <FloatingButton onClick={() => handleNewLocationModal()} />
          ) : (
            <Button onClick={() => handleNewLocationModal()}>Agregar {pageTitle}</Button>
          )}
        </Header>

        <Breadcrumbs pathname="/admin/ubicaciones" items={breadcrumbs} />

        {loaded === 'succeeded' ? (
          <Table
            header={tableHeader}
            items={locationsTableitems}
            //
            enableEdit
            onEdit={(obj) => handleEditLocationModal(obj)}
            enableShowQR
            onShowQR={(obj) => handleShowLocationQR(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminLocationsScreen
