// dependencies.
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
// components.
import { Body, Label, Pill } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import cardStatusFormat from '../../../../js/cardStatusFormat'
import dateFormat from '../../../../js/dateFormat'
import { getNameFull } from '../../../../js/usernameUtils'
import { useFocus } from '../../../../js/hooks'
import { theme } from '../../../../styleguide'
// constants.
import { DATE_TIME_FORMAT } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

const getCardInfo = {
  TM: theme.cards.tm,
  TO: theme.cards.to,
  TQ: theme.cards.tq,
  TS: theme.cards.ts,
}

export const COLUMN_LAYOUT = [
  { label: 'Solicitud', dbKey: 'createdDate', columns: 3 },
  { label: 'N° Tarjeta', dbKey: 'id', columns: 3 },
  { label: 'Estado', dbKey: 'status', columns: 2 },
  { label: 'Tipo', dbKey: 'type', columns: 2 },
  { label: 'Responsable', dbKey: 'responsibleRef', columns: 5 },
  { label: 'Realizado', dbKey: 'extremeDateReal', columns: 3 },
  { label: 'Acciones', columns: 2 },
]

// partials.
const Wrapper = styled.li({
  backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
  borderBottom: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: baseUnit * 3,
  transition: 'background 200ms ease',
  width: '100%',
  '&:focus': {
    backgroundColor: theme.opacity.primary20,
    outline: 'none',
  },
})

const Cell = styled.div({
  alignItems: 'center',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 2}px ${baseUnit}px`,
  width: (props) => `${props.width}%`,
})

const StyledType = styled(Body)({
  '&:before': {
    backgroundColor: (props) => props.background,
    borderRadius: baseUnit / 4,
    content: '""',
    display: 'inline-block',
    filter: `drop-shadow(${theme.shadows.smaller})`,
    height: baseUnit / 2,
    marginRight: baseUnit / 4,
    width: baseUnit / 2,
  },
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

const StyledEdit = styled(Label)({
  background: (props) => (props.disabled ? theme.colors.smoke : theme.colors.warning),
  border: (props) =>
    props.disabled ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.warning}`,
  borderRadius: baseUnit / 2 - 2,
  display: 'flex',
  fontSize: theme.fontSize.x14,
  gap: 4,
  padding: '3px 6px 3px 8px',
  width: 'fit-content',
})

// main component.
const CardsDesktopItem = ({ item, isEven, onEdit, onFocus = () => {} }) => {
  // handle focus event.
  const itemRef = useRef(null)

  const { focused } = useFocus(itemRef)

  useEffect(() => {
    onFocus(focused)
  }, [focused])

  // only show if there's items.
  if (!item) return null

  const { id, type, status, createdDate, extremeDateReal, responsible } = item

  const enableEdit = Boolean(!item.extremeDateReal && item.status < 2 && onEdit)

  const cellWidth = 5 // 5% (100% / 20 columns)

  // return content.
  return (
    <Wrapper ref={itemRef} isEven={isEven} tabIndex={0}>
      <Cell width={cellWidth * COLUMN_LAYOUT[0].columns}>
        <StyledBody nowrap>{dateFormat(createdDate, DATE_TIME_FORMAT)}</StyledBody>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT[1].columns}>
        <StyledBody nowrap>{id}</StyledBody>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT[2].columns}>
        <Pill type={status}>{cardStatusFormat(status)}</Pill>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT[3].columns}>
        <StyledType background={getCardInfo[type]} nowrap>
          {type}
        </StyledType>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT[4].columns}>
        <StyledBody nowrap>{getNameFull(responsible?.firstname, responsible?.lastname)}</StyledBody>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT[5].columns}>
        <StyledBody color={!extremeDateReal && 'grey'} nowrap>
          {extremeDateReal ? dateFormat(extremeDateReal, DATE_TIME_FORMAT) : ''}
        </StyledBody>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT[6].columns}>
        <StyledEdit color="white" disabled={!enableEdit} onClick={enableEdit && onEdit}>
          Editar
          <Icon name="edit" size={16} color={!enableEdit ? 'ash' : 'white'} />
        </StyledEdit>
      </Cell>
    </Wrapper>
  )
}

export default CardsDesktopItem
