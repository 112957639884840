// dependencies.
import { useRef, useState } from 'react'
import styled from 'styled-components'
// components.
import { Body, Label } from '../Typography'
import { Input } from '../Form'
import { Icon } from '../Icon'
// utils.
import { useDimensions, useWindowEvent } from '../../js/hooks'
import { theme } from '../../styleguide'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// helpers.
const search = (array, searchTerm) => {
  const result = []

  const searchInObject = (obj) => {
    if (obj.label && obj.label.toLowerCase().includes(searchTerm.toLowerCase())) {
      result.push({ name: obj.name, label: obj.label })
    }
  }

  array.forEach((obj) => {
    searchInObject(obj)
  })

  return result
}

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: (props) => (props.isTablet ? '8px' : '16px'),
  position: 'relative',
  width: (props) => (props.column ? '100%' : 'fit-content'),
})

const Close = styled.button({
  background: 'none',
  border: 'none',
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  height: '48px',
  justifyContent: 'center',
  position: 'absolute',
  right: 0,
  top: (props) => (props.column ? '34px' : '0px'),
  width: '48px',
  zIndex: 101,
})

const StyledInput = styled(Input)({
  width: '100%',
})

const Menu = styled.div({
  background: theme.colors.white,
  border: `1px solid ${theme.colors.ash}}`,
  borderRadius: '24px',
  boxShadow: theme.shadows.normal,
  height: 'auto',
  left: 0,
  minWidth: '100%',
  padding: '16px 16px 16px 0',
  position: 'absolute',
  top: 'calc(100% + 4px)',
  width: 'fit-content',
  zIndex: 100,
})

const List = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  width: '100%',
})

const Item = styled.li({
  display: 'block',
  paddingLeft: '16px',
  width: '100%',
})

const ItemContainer = styled.span({
  alignItems: 'center',
  borderBottom: (props) => (props.isLast ? 'none' : `1px solid ${theme.colors.ash}}`),
  display: 'flex',
  gap: '16px',
  height: '48px',
  justifyContent: 'space-between',
  padding: '8px 0',
})

const ItemLabel = styled(Label)({
  width: '100%',
})

const MenuItem = ({ data, isLast, onClick }) => {
  const { name, label } = data

  return (
    <Item>
      <ItemContainer isLast={isLast} onClick={() => onClick({ label, name })}>
        <ItemLabel>{label}</ItemLabel>
      </ItemContainer>
    </Item>
  )
}

// main component.
const FilterByAnomaly = ({
  label,
  anomalies,
  onFilterByAnomaly = () => {},
  placeholder = 'Seleccionar Anomalía',
}) => {
  const componentRef = useRef(null)
  const { isTablet } = useDimensions()
  const [selectedValue, setSelectedValue] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)

  const optionLabels = anomalies

  const [searched, setSearched] = useState(optionLabels)

  // search for items in the array.
  const handleSearch = (term) => {
    term !== '' ? setSearched(search(optionLabels, term)) : setSearched(optionLabels)
  }

  const handleOutsideClick = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setMenuOpen(false)
    }
  }

  useWindowEvent('mousedown', handleOutsideClick)

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // reset values.

  // close modal.
  const handleCloseModal = () => {
    setMenuOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // return content.
  return (
    <Wrapper column={!!label} isTablet={isTablet} ref={componentRef}>
      {label && (
        <Body type="caption" color="grey">
          {label}
        </Body>
      )}
      <div onClick={() => setMenuOpen(true)}>
        <StyledInput
          iconL="search"
          name="user-filter"
          placeholder={placeholder}
          defaultValue={selectedValue}
          onChange={(term) => handleSearch(term)}
        />
      </div>

      {menuOpen && searched?.length > 0 && (
        <>
          <Close column={!!label} onClick={() => handleCloseModal()}>
            <Icon name="close" size={16} color="black" />
          </Close>

          <Menu>
            <List>
              {searched?.length > 0 &&
                searched
                  .map((searchItem, i) => {
                    return (
                      <MenuItem
                        key={searchItem.name}
                        data={searchItem}
                        isLast={searched.length === i + 1}
                        onClick={({ label, name }) => {
                          setSelectedValue(label)
                          onFilterByAnomaly(name)
                          setMenuOpen(false)
                        }}
                      />
                    )
                  })
                  .slice(0, 3)}
            </List>
          </Menu>
        </>
      )}
    </Wrapper>
  )
}

export default FilterByAnomaly
