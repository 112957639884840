// dependencies.
import { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// components.
import { Body } from '../../components/Typography'
import { ScreenWrapper, Topbar, Wrapper } from '../../components/Layout'
// utils.
import { useAppSelector } from '../../js/store/hooks'
import { IS_DEV } from '../../constants'
import { selectUserData } from '../../js/auth/authSelectors'
// constants.

/***********************/
/*                     */
/*    Splash Screen    */
/*                     */
/***********************/

// partials.
const StyledBody = styled(Body)({
  margin: 'auto',
  maxWidth: 360,
  padding: 16,
  textAlign: 'center',
})

// main component.
const SplashScreen = () => {
  // contexts.
  const navigate = useNavigate()
  const userData = useAppSelector(selectUserData)
  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    if (IS_DEV) console.warn(userData)

    // handle logged out users.
    if (!userData || !userData.access) navigate('/signin')

    const userAccess = userData?.access
    // redirect user to the right panel depending on 'access'.
    if (userAccess === 'developer' || userAccess === 'superadmin' || userAccess === 'admin')
      navigate('/admin')
    else if (userAccess === 'operator') navigate('/funcionario')
  }, [userData.access])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <ScreenWrapper>
      <Topbar label="CCU" />

      <Wrapper background="white">
        <StyledBody color="grey">Bienvenido</StyledBody>
      </Wrapper>
    </ScreenWrapper>
  )
}

export default SplashScreen
