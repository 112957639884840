// dependencies.
import { doc, setDoc } from 'firebase/firestore'
import styled from 'styled-components'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Header from '../../components/Layout/Header'
import { Button } from '../../components/Button'
import { Form } from '../../components/Form'
// utils.
import notifyToast from '../../js/notifyToast'
import { auth, db, signOut } from '../../js/firebase/firebase'
// constants.
import { SETTINGS_FORM } from './constants'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../js/store/hooks'
import { logout, setCurrentUserData } from '../../js/auth/authSlice'
import { selectUserData } from '../../js/auth/authSelectors'

/********************************/
/*                              */
/*    Admins Settings Screen    */
/*                              */
/********************************/

// Partials:
const StyledForm = styled(Form)({
  padding: '40px 16px',
})

// Main component:
const AdminsSettingsScreen = ({ navMenu, navTitle, items = SETTINGS_FORM }) => {
  // contexts.
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle user information update.
  const handleUpdateUserClick = async (obj) => {
    const userRef = doc(db, 'users', userData.uid)
    const updateFields = {
      firstname: obj.firstname,
      lastname: obj.lastname,
      updatedDate: new Date(),
    }
    await setDoc(userRef, updateFields, { merge: true })
      .then(() => {
        dispatch(setCurrentUserData({ userData: obj }))
        notifyToast('Datos guardados correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))
  }

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        dispatch(logout())
        navigate('/signin')
      })
      .catch((error) => error.message)
  }
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show if there's items.
  if (!items) return null

  // return content.
  return (
    <AdminTemplate navigation={navMenu} sectionTitle={navTitle}>
      <Header>
        <Button onClick={() => handleLogout()}>Cerrar sesión</Button>
      </Header>

      <StyledForm
        height="calc(100vh - 120px)"
        items={items}
        defaultValues={{
          firstname: userData.firstname,
          lastname: userData.lastname,
          document: userData.document,
          email: userData.email !== `${userData.document}@ccu.uy` ? userData.email : '',
        }}
        submitLabel="Actualizar"
        onClick={(obj) => handleUpdateUserClick(obj)}
      />
    </AdminTemplate>
  )
}

export default AdminsSettingsScreen
