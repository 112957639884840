// dependencies.
import { useState, forwardRef } from 'react'
import styled from 'styled-components'

// components.
import { Label } from '../Typography'
// utils.
import { useDimensions } from '../../js/hooks'
import { theme } from '../../styleguide'

/*********************************/
/*                               */
/*    Development Home Screen    */
/*                               */
/*********************************/

// partials

const TabHeader = styled.div(
  {
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.ash}`,
    borderTop: `1px solid ${theme.colors.ash}`,
    display: 'flex',
    flexDirection: 'row',
    gap: 0,
    margin: 0,
    padding: 0,
    width: '100%',
  },
  `@media print { display: none; }`
)

const StyledItem = styled(Label)({
  height: '100%',
  padding: (props) => (props.isMobile ? '13px 8px 9px' : '13px 16px 9px'),
  borderBottom: (props) =>
    props.isActive ? `4px solid ${theme.colors.primary}` : '4px solid transparent',
  transition: 'all 200ms ease',
  whiteSpace: 'nowrap',
  width: (props) => (props.isMobile ? `${100 / 3}%` : 'fit-content'),
})

const TabBody = styled.div({
  height: '100%',
  overflow: 'hidden',
})

const Row = styled.div({
  display: 'flex',
  height: '100%',
})

// main component.
const Tabs = forwardRef(({ items, onClick }, ref) => {
  // hooks.
  const { isMobile } = useDimensions()
  // states.
  const [activeTab, setActiveTab] = useState(0)

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  if (!items.length) return null

  // return content.
  return (
    <>
      <TabHeader isMobile={isMobile}>
        {items.map(({ label }, i) => (
          <StyledItem
            key={i}
            isActive={activeTab === i}
            isMobile={isMobile}
            color={activeTab === i ? 'black' : 'grey'}
            onClick={() => setActiveTab(i)}
          >
            {label}
          </StyledItem>
        ))}
      </TabHeader>

      <TabBody ref={ref}>
        {items.map(({ component }, i) => (activeTab === i ? <Row key={i}>{component}</Row> : null))}
      </TabBody>
    </>
  )
})

export default Tabs
