const body = (cardTitle, cardDescription, creationDate, creatorName) => `
Le informamos que se ha generado una nueva ${cardTitle}. A continuación, se presentan los detalles:

- **Título:** ${cardTitle}
- **Descripción:** ${cardDescription}
- **Fecha:** ${creationDate}
- **Generado por:** ${creatorName}

Le recomendamos acceder al sistema para más detalles.`

const bodyHtml = (cardTitle, cardDescription, creationDate, creatorName) => `
<p>Le informamos que se ha generado una nueva ${cardTitle}. A continuación, se presentan los detalles:</p>
<ul>
    <li><strong>Título:</strong> ${cardTitle}</li>
    <li><strong>Descripción:</strong> ${cardDescription}</li>
    <li><strong>Fecha:</strong> ${creationDate}</li>
    <li><strong>Generado por:</strong> ${creatorName}</li>
</ul>
<p>Le recomendamos acceder al sistema para más detalles.</p>`

const mailNotificationsMessage = (cardType, cardDescription, creationDate, creatorName) => {
  const subject = `Nueva Tarjeta ${cardType} generada`
  let text
  let html
  switch (cardType) {
    case 'TS':
      text = body('Tarjeta de Seguridad', cardDescription, creationDate, creatorName)
      html = bodyHtml('Tarjeta de Seguridad', cardDescription, creationDate, creatorName)
      return {
        subject,
        text,
        html,
      }
    case 'TO':
      text = body('Tarjeta de Órden y Limpieza', cardDescription, creationDate, creatorName)
      html = bodyHtml('Tarjeta de Órden y Limpieza', cardDescription, creationDate, creatorName)
      return {
        subject,
        text,
        html,
      }
    case 'TM':
      text = body('Tarjeta de Mantenimiento', cardDescription, creationDate, creatorName)
      html = bodyHtml('Tarjeta de Mantenimiento', cardDescription, creationDate, creatorName)
      return {
        subject,
        text,
        html,
      }
    case 'TQ':
      text = body('Tarjeta de Calidad', cardDescription, creationDate, creatorName)
      html = bodyHtml('Tarjeta de Calidad', cardDescription, creationDate, creatorName)
      return {
        subject,
        text,
        html,
      }
  }
}

export default mailNotificationsMessage
