// dependencies.
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// components.
import AuthTemplate from '../../templates/AuthScreen'
// utils.
import notifyToast from '../../js/notifyToast'
// constants.
import { RESET_FORM } from './constants'
import { getUserByDocument } from '../../js/firebase/firebase'
import firebaseErrorMessage from '../../js/firebase/firebaseErrorTranslator'
import { sendReset } from '../../js/auth/authActions'
import { Loader } from '../../components/Icon'

/*******************************/
/*                             */
/*    Reset Password Screen    */
/*                             */
/*******************************/

// main component.
const ResetScreen = () => {
  // hooks.
  const navigate = useNavigate()
  // states.
  const [document, setDocument] = useState('')
  const [loaded, setLoaded] = useState(false)

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update value change.
  const handleValueChange = (values) => {
    setDocument(values.document)
  }

  // navigate to sign-in page.
  const handleSigninClick = () => {
    navigate('/signin')
  }

  const handleResetPasswordClick = async (document) => {
    // check if first signin
    if (!document) return notifyToast('Debe ingresar su documento', 'error')
    setLoaded(true)
    const userDoc = await getUserByDocument(document)
    if (!userDoc.exists) {
      setLoaded(false)
      return notifyToast('No existe un usuario con el documento ingresado', 'error')
    }
    const userData = userDoc.data()
    const defaultEmail = `${userData.document}@ccu.uy`
    if (userDoc) {
      if (userData.email && userData.email !== defaultEmail) {
        sendReset(userData.email).then((response) => {
          if (response === 'sent') {
            notifyToast(
              'Se le envió un email con instrucciones para cambiar su contraseña',
              'success'
            )
          } else {
            notifyToast(firebaseErrorMessage(response), 'error')
          }
          setLoaded(false)
        })
      } else {
        setLoaded(false)
        notifyToast(
          'El usuario no tiene un email asociado, se le debe solicitar a un administrador que restablezca la contraseña',
          'error'
        )
      }
    } else {
      setLoaded(false)
      notifyToast('No existe un usuario con el documento ingresado', 'error')
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AuthTemplate
      disbledSubmit={loaded}
      description="Ingrese el documento asociado al usuario.<br /> Se le enviará un correo con instrucciones para modificar su acceso."
      form={RESET_FORM}
      submitLabel="Restablecer"
      topbarIcon="caret-left"
      topbarTitle="Restablecer"
      onChange={(value) => handleValueChange(value)}
      onSubmit={() => handleResetPasswordClick(document)}
      onTopbarAction={() => handleSigninClick()}
    >
      {loaded && <Loader />}
    </AuthTemplate>
  )
}

export default ResetScreen
