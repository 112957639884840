// dependencies.
import { useRef } from 'react'
import styled from 'styled-components'
// components.
import Label from '../Typography/Label'
import { Icon } from '../Icon'
// utils.
import { theme } from '../../styleguide'
import { useDimensions, useHover } from '../../js/hooks'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const StyledFilterButton = styled.span({
  alignItems: 'center',
  background: 'transparent',
  border: (props) =>
    props.isActive || props.isMobile ? 'none' : `2px solid ${theme.colors.white}`,
  borderRadius: baseUnit / 2,
  cursor: 'pointer',
  display: 'flex',
  gap: baseUnit / 2,
  justifyContent: 'center',
  opacity: (props) => (props.isActive ? 0 : props.hovered ? 0.6 : 1),
  padding: '12px 16px',
  width: 'fit-content',
  textAlign: 'center',
  transition: 'all 200ms ease',
  '&:focus': {
    opacity: 0.8,
    outline: 'none',
  },
  zIndex: '50',
})

// main component.
const ToggleFiltersButton = ({ isActive, onChange = () => {} }) => {
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)
  const { isMobile } = useDimensions()

  // return content.
  return (
    <StyledFilterButton
      ref={hoverRef}
      hovered={isHover}
      isActive={isActive}
      isMobile={isMobile}
      tabIndex={0}
      onClick={() => onChange(!isActive)}
    >
      {!isActive && (
        <>
          <Icon name="filter" size={20} color="white" />
          <Label color="white">Filtrar</Label>
        </>
      )}
    </StyledFilterButton>
  )
}

export default ToggleFiltersButton
