// dependencies.
import { createAsyncThunk } from '@reduxjs/toolkit'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
// utils.
import {
  activateOperatorUser,
  activateUser,
  auth,
  db,
  sendPasswordReset,
  signOut,
  signUpEmailPassword,
} from '../firebase/firebase'
import { setCurrentUserData } from './authSlice'
import notifyToast from '../notifyToast'
import firebaseErrorMessage from '../firebase/firebaseErrorTranslator'
import { setCookie } from '../cookies'

export const sendReset = (email) => sendPasswordReset(email)

// log user out and remove localstorage data.
export const sendOut = () => {
  signOut(auth)
  setCurrentUserData({})
  setCookie('userData', '', -1)
}

// log user in using document and password.
export const signIn = createAsyncThunk(
  'user/signin',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password)
      const user = userCredential.user
      const docRef = doc(db, 'users', user.uid)
      const docSnap = await getDoc(docRef)

      if (!docSnap.exists()) {
        throw new Error('No user data found')
      }

      const userData = docSnap.data()
      dispatch(setCurrentUserData({ user: user, userData: userData }))
      return user
    } catch (error) {
      notifyToast(firebaseErrorMessage(error.message), 'error')
      return rejectWithValue(error.message)
    }
  }
)

// register new user using email and password.
export const signUp = (values) => signUpEmailPassword(values)

// activate user account from email validator.
export const activate = (values) => activateUser(values)
export const activateOperator = (values) => activateOperatorUser(values)
