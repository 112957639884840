// dependencies.
import { useEffect, useRef, useState } from 'react'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import {
  ChartsContainer,
  CardsByUserChart,
  CardsByStateChart,
  ReportsDesktopItem,
  ReportsDesktopSort,
  ReportsDesktopWrapper,
  ChartCard,
  CardsByTypeAndFTE,
} from '../../components/screens/Reports'
import Header from '../../components/Layout/Header'
import { FilterList } from '../../components/Typography/FilterChip'
import { Button } from '../../components/Button'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import ToggleFilterButton from '../../components/Filters/ToggleFilterAsideButton'
import SearchCardsById from '../../components/screens/Cards/SearchCardsById'
import FilterDate from '../../components/Filters/Date'
import FilterCardType from '../../components/Filters/CardType'
import FilterLocation from '../../components/Filters/Location'
import FilterResponsible from '../../components/Filters/Responsible'
import FilterStatus from '../../components/Filters/Status'
import FilterCreatedBy from '../../components/Filters/CreatedBy'
import FilterByAnomaly from '../../components/Filters/ByAnomaly'
import FilterOtif from '../../components/Filters/Otif'
import Export from '../../components/Filters/Export'
// utils.
import notifyToast from '../../js/notifyToast'
import { db, getAnomalyById, getLocationById } from '../../js/firebase/firebase'
import { useAppDispatch, useAppSelector } from '../../js/store/hooks'
import { selectUserData } from '../../js/auth/authSelectors'
import { selectUsersData, selectUsersForSelect } from '../../js/users/usersSelectors'
import {
  selectLocationsData,
  selectLocationsForSelect,
  selectLocationsTree,
} from '../../js/locations/locationsSelectors'
import { setFilters, removeFilter, clearFilters } from '../../js/cards/cardsSlice'
import { useDimensions } from '../../js/hooks'
import { selectAnomaliesForSelect } from '../../js/anomalies/anomaliesSelectors'
import {
  selectActiveFilters,
  selectCardTypeFilter,
  selectFilteredCards,
  selectFromDateFilter,
  selectLocationFilter,
  selectSearchTerm,
  selectToDateFilter,
  selectStatusFilter,
  selectCreatedByFilter,
  selectFilterByAnomaly,
  selectOtStatusFilter,
} from '../../js/cards/cardsSelectors'
// constants.
import {
  NAVIGATION_WIDTH,
  NAVIGATION_CONDENSED_WIDTH,
  FILTERS_ASIDE_WIDTH,
  HEADER_HEIGHT_DESKTOP,
  IS_DEV,
} from '../../constants'
import { COLUMN_LAYOUT } from '../../components/screens/Reports/DesktopItems/Item'
import { NEW_CARD_FORM } from './constants'
import useFetchCardsData from '../../js/cards/hooks/useFetchCardsData'
import { selectBudgetsStatus } from '../../js/budgets/budgetsSelectors'
import { fetchBudgets } from '../../js/budgets/budgetsSlice'
// helpers.
const selectCardType = [
  { label: 'Seguridad', name: 'TS' },
  { label: 'Órden y Limpieza', name: 'TO' },
  { label: 'Mantenimiento', name: 'TM' },
  { label: 'Calidad', name: 'TQ' },
]

// main component.
const ReportsScreen = ({ navMenu, navTitle }) => {
  const scrollRef = useRef()
  const chartWrapperRef = useRef(null)
  const tableWrapperRef = useRef(null)
  const { isMobile } = useDimensions()

  const userData = useAppSelector(selectUserData)
  const anomaliesSelectOptions = useAppSelector(selectAnomaliesForSelect)

  const dispatch = useAppDispatch()
  const users = useAppSelector(selectUsersData)
  const locationsSelect = useAppSelector(selectLocationsForSelect)
  const responsiblesSelectOptions = useAppSelector(selectUsersForSelect)
  const locations = useAppSelector(selectLocationsData)
  const anomalies = useAppSelector(selectAnomaliesForSelect)
  const filteredCardsSelector = selectFilteredCards(users, locations, anomalies)
  const filteredCards = useAppSelector(filteredCardsSelector)
  const locationsTree = useAppSelector(selectLocationsTree)

  // const usersStatus = useAppSelector(selectUsersStatus)
  // const locationsStatus = useAppSelector(selectLocationsStatus)
  // const anomaliesStatus = useAppSelector(selectAnomaliesStatus)

  // fetch reports data.
  // const loaded = useAppSelector(selectCardsStatus)
  const loaded = useFetchCardsData()

  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [offsetTop, setOffsetTop] = useState(0)
  const [tableOffsetDimensions, setTableOffsetDimensions] = useState({ left: 0, width: '100%' })
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [navIsCondensed, setNavIsCondensed] = useState(false)

  const searchTerm = useAppSelector(selectSearchTerm)
  const cardTypeFilter = useAppSelector(selectCardTypeFilter)
  const cardStatusFilter = useAppSelector(selectStatusFilter)
  const cardCreatedByFilter = useAppSelector(selectCreatedByFilter)
  const cardFilterByAnomaly = useAppSelector(selectFilterByAnomaly)
  const cardOtStatusFilter = useAppSelector(selectOtStatusFilter)

  const fromDateFilter = useAppSelector(selectFromDateFilter)
  const toDateFilter = useAppSelector(selectToDateFilter)
  const locationFilter = useAppSelector(selectLocationFilter)

  const activeFiltersSelector = selectActiveFilters(users, locationsTree, anomalies)
  const activeFilters = useAppSelector(activeFiltersSelector)

  const budgetsLoaded = useAppSelector(selectBudgetsStatus)
  // console.log(budgetsByDate)
  useEffect(() => {
    if (budgetsLoaded === 'idle') dispatch(fetchBudgets())
  }, [dispatch, budgetsLoaded])

  // open modal.
  const handleModalOpen = (open) => setModalOpen(open)

  // handle add new card modal.
  const handleNewCardModal = () => {
    setModalContent({
      title: 'Crear tarjeta',
      component: (
        <Form
          items={NEW_CARD_FORM(selectCardType, anomaliesSelectOptions, locationsSelect)}
          onClick={(obj) => handleCreateCardClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new card.
  const handleCreateCardClick = async (obj) => {
    const { locationId, anomaliesIds, cardType, ...cardObj } = obj
    cardObj.anomaliesRefs = []
    const anomalyIds = Object.keys(anomaliesIds).filter((anomalyId) => anomaliesIds[anomalyId])

    for (const anomalyId of anomalyIds) {
      const anomaly = await getAnomalyById(anomalyId)
      cardObj.anomaliesRefs.push(anomaly.ref)
    }

    const location = locationId ? await getLocationById(locationId) : null

    if (location) {
      cardObj.locationRef = location.ref
      cardObj.responsibleRef = location.responsibleRef
    } else {
      cardObj.locationRef = null
      cardObj.responsibleRef = null
    }

    cardObj.createdDate = new Date()
    cardObj.updatedDate = new Date()
    cardObj.status = 0
    const userRef = doc(db, 'users', userData.uid)
    cardObj.createdByRef = userRef
    await addDoc(collection(db, 'cards'), cardObj)
      .then(async (cardRef) => {
        await setDoc(cardRef, { ref: cardRef }, { merge: true })
        notifyToast('Tarjeta solicitada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  // update positions on scroll.
  useEffect(() => {
    scrollRef?.current &&
      scrollRef?.current.addEventListener('scroll', () =>
        setOffsetTop(scrollRef?.current.scrollTop)
      )
    return () =>
      scrollRef.current && scrollRef.current.removeEventListener('scroll', () => setOffsetTop(0))
  }, [scrollRef.current])

  useEffect(() => {
    tableWrapperRef?.current &&
      tableWrapperRef?.current.addEventListener('scroll', () =>
        setTableOffsetDimensions({
          left: tableWrapperRef?.current.scrollLeft,
          width: tableWrapperRef?.current.scrollWidth,
        })
      )

    return () => {
      tableWrapperRef.current &&
        tableWrapperRef.current.removeEventListener('scroll', () =>
          setTableOffsetDimensions({ left: 0, width: '100%' })
        )
    }
  }, [tableWrapperRef.current])

  const maybeMoveOnScroll =
    chartWrapperRef?.current &&
    offsetTop > HEADER_HEIGHT_DESKTOP + chartWrapperRef?.current?.clientHeight

  const usersWithCreatingCards = users
    .map(({ id }) => filteredCards.filter((card) => card.createdByRef.id === id).length)
    .filter((el) => el !== 0).length

  // return content.

  return (
    <>
      <AdminTemplate
        ref={scrollRef}
        sectionTitle={navTitle}
        navigation={navMenu}
        isMobile={isMobile}
        modalOpen={modalOpen}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        filterComponents={[
          <SearchCardsById
            key="in-cards-search"
            items={filteredCards}
            defaultValue={searchTerm}
            onSearch={(term) => {
              dispatch(setFilters({ searchTerm: term }))
            }}
          />,
          <FilterDate
            key="in-cards-date"
            label="Filtrar en rango de fechas"
            items={filteredCards}
            defaultValue={{ fromDate: fromDateFilter, toDate: toDateFilter }}
            onDateFilter={(fromDate, toDate) => dispatch(setFilters({ fromDate, toDate }))}
          />,
          <FilterCardType
            key="in-cards-type"
            label="Tipo de Tarjeta"
            items={filteredCards}
            defaultValue={cardTypeFilter}
            onTypeFilter={(type) => dispatch(setFilters({ type }))}
          />,
          <FilterStatus
            key="in-cards-status"
            label="Estado"
            items={filteredCards}
            defaultValue={cardStatusFilter}
            onStatusFilter={(status) => dispatch(setFilters({ status }))}
          />,
          <FilterLocation
            key="in-cards-location"
            label="Ubicación"
            placeholder="Buscar ubicaciones"
            value={locationFilter}
            locationsSelectTree={locationsTree}
            items={filteredCards}
            onLocationFilter={(locationId) => dispatch(setFilters({ locationId }))}
          />,
          <FilterCreatedBy
            key="in-cards-createdBy"
            label="Levantada Por"
            users={users}
            items={filteredCards}
            defaultValue={cardCreatedByFilter}
            placeholder="Levantada Por"
            onCreatedByFilter={(createdBy) => dispatch(setFilters({ createdBy }))}
          />,
          <FilterByAnomaly
            key="in-cards-byAnomaly"
            label="Anomalía"
            anomalies={anomaliesSelectOptions}
            defaultValue={cardFilterByAnomaly}
            placeholder="Buscar Anomalía"
            onFilterByAnomaly={(anomaly) => dispatch(setFilters({ anomaly }))}
          />,
          <FilterResponsible
            key="in-cards-responsible"
            label="Responsable"
            placeholder="Responsable"
            items={filteredCards}
            responsibles={responsiblesSelectOptions}
            onResponsibleFilter={(responsibleId) => dispatch(setFilters({ responsibleId }))}
          />,
          <FilterOtif
            key="in-cards-otif"
            label="OTIF"
            items={filteredCards}
            defaultValue={cardOtStatusFilter}
            onOtStatusFilter={(otStatus) => dispatch(setFilters({ otStatus }))}
          />,
        ]}
        onOverlayClick={() => setModalOpen(false)}
        onCondensedChange={(isCondensed) => setNavIsCondensed(isCondensed)}
      >
        <Header justifyContent="space-between">
          {!filtersOpen && (
            <ToggleFilterButton
              isActive={filtersOpen}
              onChange={(isOpen) => setFiltersOpen(isOpen)}
            />
          )}
          {!isMobile && (
            <>
              <FilterList
                items={activeFilters}
                onFilterClick={(filterValue) => dispatch(removeFilter(filterValue))}
                onClearAllClick={() => dispatch(clearFilters())}
              />
              <Export
                key="in-cards-export"
                header={COLUMN_LAYOUT}
                items={filteredCards}
                filename={`reporte_${new Date().toLocaleDateString()}`}
              />
              {IS_DEV && <Button onClick={() => handleNewCardModal()}>Nueva Tarjeta</Button>}
            </>
          )}
        </Header>

        {loaded === 'succeeded' && budgetsLoaded === 'succeeded' ? (
          <>
            {!isMobile && (
              <ChartsContainer ref={chartWrapperRef}>
                <ChartCard width="100%">
                  <CardsByTypeAndFTE
                    fromDate={fromDateFilter}
                    toDate={toDateFilter}
                    cards={filteredCards}
                  />
                </ChartCard>
                <ChartCard label={`Tarjetas levantadas (${filteredCards.length})`} width="35%">
                  <CardsByStateChart cards={filteredCards} />
                </ChartCard>
                <ChartCard
                  label={`Tarjetas levantadas por funcionario (${usersWithCreatingCards})`}
                  width="65%"
                >
                  <CardsByUserChart users={users} cards={filteredCards} />
                </ChartCard>
              </ChartsContainer>
            )}

            <ReportsDesktopWrapper isEmpty={filteredCards.length <= 0} ref={tableWrapperRef}>
              <ReportsDesktopSort
                style={{
                  ...(maybeMoveOnScroll && {
                    maxWidth: `${tableOffsetDimensions.width}px`,
                    left: `${
                      filtersOpen
                        ? navIsCondensed
                          ? `${
                              NAVIGATION_CONDENSED_WIDTH +
                              FILTERS_ASIDE_WIDTH -
                              tableOffsetDimensions.left
                            }px`
                          : `${
                              NAVIGATION_WIDTH + FILTERS_ASIDE_WIDTH - tableOffsetDimensions.left
                            }px`
                        : navIsCondensed
                          ? `${NAVIGATION_CONDENSED_WIDTH - tableOffsetDimensions.left}px`
                          : `${NAVIGATION_WIDTH - tableOffsetDimensions.left}px`
                    }`,
                    position: 'fixed',
                    width: `${tableOffsetDimensions.width}px`,
                  }),
                }}
                onSort={(e) => dispatch(setFilters({ sort: e }))}
              />

              {filteredCards.map((card, i) => {
                return (
                  <ReportsDesktopItem
                    key={card.id}
                    item={card}
                    anomalies={anomaliesSelectOptions}
                    isEven={i % 2 === 0}
                  />
                )
              })}
            </ReportsDesktopWrapper>
          </>
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default ReportsScreen
