export const NEW_ADMIN_FORM = [
  { type: 'headline', label: 'Datos del administrador:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'document', placeholder: 'Documento', required: true },
  { name: 'email', placeholder: 'Email', required: true },
]

export const NEW_ANOMALY_FORM = (types) => [
  { type: 'headline', label: 'Datos del tipo de anomalía:' },
  { name: 'name', placeholder: 'Nombre', required: true },
  { name: 'type', placeholder: 'Tipo', required: true, options: types },
]

export const NEW_BUDGET_FORM = (types) => [
  { type: 'headline', label: 'Datos del presupuesto:' },
  { name: 'startDate', placeholder: 'Fecha de inicio', required: true, type: 'date' },
  { name: 'type', placeholder: 'Tipo', required: true, options: types },
  { name: 'quantity', placeholder: 'Cantidad', required: true, type: 'number' },
]

export const NEW_LOCATION_FORM = (responsibles = []) => [
  { type: 'headline', label: 'Datos del área:' },
  { name: 'name', placeholder: 'Nombre', required: true },
  { name: 'responsible', placeholder: 'Responsable', options: responsibles },
]

export const NEW_CARD_FORM = (types, anomalies, locations) => [
  { name: 'cardType', placeholder: 'Tipo', options: types },
  { name: 'type', placeholder: 'Tipo', required: true, hidden: true },
  { name: 'locationId', placeholder: 'Ubicación', options: locations, required: true },
  {
    type: 'array',
    name: 'anomaliesIds',
    items: [
      anomalies.map((anomaly) => {
        return {
          name: anomaly.name,
          placeholder: anomaly.label,
          type: 'anomaly',
          anomalyType: anomaly.type,
        }
      }),
    ],
  },
  { name: 'description', placeholder: 'Descripción anomalía', required: true },
  { name: 'proposedImprovement', placeholder: 'Acción propuesta/mejora' },
  { name: 'file', placeholder: 'Adjuntar imagen', type: 'file' },
  { name: 'video', placeholder: 'Adjuntar video', type: 'video' },
]

export const EDIT_CARD_FORM = (types, anomalies, locations, priorities) => [
  { name: 'cardType', placeholder: 'Tipo', options: types, disabled: true },
  { name: 'locationId', placeholder: 'Ubicación', options: locations },
  {
    type: 'array',
    name: 'anomaliesIds',
    items: [
      anomalies.map((anomaly) => {
        return {
          name: anomaly.name,
          placeholder: anomaly.label,
          type: 'anomaly',
          anomalyType: anomaly.type,
        }
      }),
    ],
  },
  { name: 'description', placeholder: 'Descripción anomalía', required: true },
  { name: 'proposedImprovement', placeholder: 'Acción propuesta/mejora' },
  { name: 'file', placeholder: 'Adjuntar imagen', type: 'file' },
  { name: 'video', placeholder: 'Adjuntar video', type: 'video' },

  { name: 'priority', placeholder: 'Prioridad', options: priorities },
  {
    name: 'instalationStatus',
    placeholder: 'Estado instalación',
    options: [
      { label: 'detenido', name: 0 },
      { label: 'funcionando', name: 1 },
    ],
  },
  {
    name: 'executor',
    placeholder: 'Ejecutor',
    options: [
      { label: 'GTA', name: 'GTA' },
      { label: 'GTM', name: 'GTM' },
    ],
  },
  { name: 'extremeDatePlan', placeholder: 'Fecha extrema (plan)', required: true, type: 'date' },
  // { name: 'extremeDateReal', placeholder: 'Fecha extrema (real)', required: true, type: 'date' },

  { name: 'validationDate', placeholder: 'Fecha validación', required: true, type: 'date' },
  { name: 'otNumber', placeholder: 'N° OT', required: true },
  { name: 'improvementMade', placeholder: 'Acción realizada' },
]

export const EDIT_IN_PROCESS_CARD_FORM = (anomalies = [], priorities) => [
  { name: 'cardType', placeholder: 'Tipo', disabled: true },
  { name: 'location', placeholder: 'Ubicación', disabled: true },
  {
    type: 'array',
    name: 'anomalies',
    items: [
      anomalies.map((anomaly) => {
        return { name: anomaly, placeholder: anomaly, type: 'checkbox', disabled: true }
      }),
    ],
  },
  { name: 'description', placeholder: 'Descripción anomalía', disabled: true },
  { name: 'proposedImprovement', placeholder: 'Acción propuesta/mejora', disabled: true },
  { name: 'priority', placeholder: 'Prioridad', options: priorities, required: true },
  {
    name: 'instalationStatus',
    placeholder: 'Estado instalación',
    options: [
      { label: 'Detenido', name: 0 },
      { label: 'Funcionando', name: 1 },
    ],
    required: true,
  },
  { name: 'extremeDatePlan', placeholder: 'Fecha extrema (plan)', required: true, type: 'date' },
  {
    name: 'executor',
    placeholder: 'Ejecutor',
    options: [
      { label: 'GTA', name: 'GTA' },
      { label: 'GTM', name: 'GTM' },
    ],
    required: true,
  },
]

export const EDIT_COMPLETE_CARD_FORM = (anomalies = [], priorities) => [
  { name: 'cardType', placeholder: 'Tipo', disabled: true },
  { name: 'location', placeholder: 'Ubicación', disabled: true },
  {
    type: 'array',
    name: 'anomalies',
    items: [
      anomalies.map((anomaly) => {
        return { name: anomaly, placeholder: anomaly, type: 'checkbox', disabled: true }
      }),
    ],
  },
  { name: 'description', placeholder: 'Descripción anomalía', required: true, disabled: true },
  { name: 'proposedImprovement', placeholder: 'Acción propuesta/mejora', disabled: true },
  { name: 'priority', placeholder: 'Prioridad', options: priorities, disabled: true },
  {
    name: 'instalationStatus',
    placeholder: 'Estado instalación',
    options: [
      { label: 'Detenido', name: 0 },
      { label: 'Funcionando', name: 1 },
    ],
    disabled: true,
  },
  {
    name: 'executor',
    placeholder: 'Ejecutor',
    options: [
      { label: 'GTA', name: 'GTA' },
      { label: 'GTM', name: 'GTM' },
    ],
    disabled: true,
  },
  // { name: 'extremeDatePlan', placeholder: 'Fecha extrema (plan)', required: true, type: 'date', disabled: true},
  // { name: 'extremeDateReal', placeholder: 'Fecha extrema (real)', required: true, type: 'date' },
  // { name: 'validatorSelect', placeholder: 'Validador', options: operators},
  { name: 'validationDate', placeholder: 'Fecha validación', required: true, type: 'date' },
  { name: 'otNumber', placeholder: 'N° OT', required: true },
  { name: 'improvementMade', placeholder: 'Acción realizada', required: true },
  { name: 'comments', placeholder: 'Comentarios' },
  { name: 'file', placeholder: 'Adjuntar evidencia', type: 'file' },
  { name: 'video', placeholder: 'Adjuntar video', type: 'video' },
]

export const CANCEL_CARD_FORM = [
  // { name: 'selectAnomaly', placeholder: 'Anomalías', options: anomalies }
]

export const CHANGE_CARD_RESPONSIBLE_FORM = (operators) => [
  { name: 'responsibleId', placeholder: 'Responsable', options: operators },
]

export const NEW_USER_FORM = [
  { type: 'headline', label: 'Acceso del usuario:' },
  {
    name: 'access',
    placeholder: 'Acceso',
    required: true,
    options: [
      { label: 'Administrador', name: 'superadmin' },
      { label: 'Funcionario', name: 'operator' },
    ],
  },
  { type: 'headline', label: 'Datos del usuario:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'document', placeholder: 'Documento', required: true },
  { name: 'email', placeholder: 'Email' },
  { type: 'checkbox', name: 'isActive', placeholder: 'Activo' },
  { name: 'firstAccessCode', placeholder: 'Código de acceso', disabled: true },
]

export const EDIT_USER_FORM = [
  { type: 'headline', label: 'Acceso del usuario:' },
  {
    name: 'access',
    placeholder: 'Acceso',
    required: true,
    options: [
      { label: 'Administrador', name: 'superadmin' },
      { label: 'Funcionario', name: 'operator' },
    ],
  },
  { type: 'headline', label: 'Datos del usuario:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'document', placeholder: 'Documento', disabled: true },
  { name: 'email', placeholder: 'Email' },
  { type: 'checkbox', name: 'isActive', placeholder: 'Activo' },
  { name: 'firstAccessCode', placeholder: 'Código de acceso', disabled: true },
]

export const FORGOT_OPERATOR_PASSWORD_FORM = [
  {
    type: 'headline',
    label:
      'Sin un email asociado, el usuario estará pendiente de activación hasta que se establezca una nueva contraseña.',
  },
  {
    type: 'headline',
    label:
      'Se generará un nuevo código para que el usuario pueda iniciar sesión y establecer una nueva contraseña.',
  },
]

export const NEW_OPERATOR_FORM = [
  { type: 'headline', label: 'Datos del funcionario:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'document', placeholder: 'Documento', required: true },
  { type: 'checkbox', name: 'isActive', placeholder: 'Activo' },
  { name: 'firstAccessCode', placeholder: 'Código de acceso', disabled: true },
]

export const EDIT_OPERATOR_FORM = [
  { type: 'headline', label: 'Datos del funcionario:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'document', placeholder: 'Documento', disabled: true },
  { type: 'checkbox', name: 'isActive', placeholder: 'Activo' },
  { name: 'firstAccessCode', placeholder: 'Código de acceso', disabled: true },
]

export const SETTINGS_FORM = [
  { type: 'headline', label: 'Datos personales:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'document', placeholder: 'Documento', disabled: true },
  { name: 'email', placeholder: 'Sin email asociado', disabled: true },
]
