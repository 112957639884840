import { createSelector } from 'reselect'
import { getNameFull } from '../usernameUtils'

export const selectLocationsData = (state) => state.locations.data

// Selector for locations select options
export const selectLocationsForSelect = createSelector([selectLocationsData], (locations) =>
  locations.map((location) => ({
    label: location.name,
    name: location.id,
  }))
)

export const selectLocationsTree = createSelector([selectLocationsData], (locations) => {
  const hierarchy = []
  const locationMap = {}

  // Crear una copia mutable de las ubicaciones para poder añadir hijos
  const mutableLocations = locations.map((location) => ({ ...location, children: [] }))

  // Mapear las ubicaciones por su referencia en un objeto mutable
  mutableLocations.forEach((location) => {
    locationMap[location.id] = location
  })

  // Construir la jerarquía
  mutableLocations.forEach((location) => {
    if (location.parentRef) {
      const parent = locationMap[location.parentRef.id]
      if (parent) {
        parent.children.push(location)
      }
    } else {
      hierarchy.push(location)
    }
  })

  return hierarchy
})

export const makeSelectLocationsForTable = (parentRef, users) =>
  createSelector(
    [selectLocationsData], // Ensure this is correctly selecting the raw locations data from state
    (locations) => {
      const currentLocations = locations.filter((locationObj) => {
        if (parentRef === null) {
          return locationObj.parentRef === undefined
        }
        return locationObj.parentRef?.id === parentRef.id
      })
      return currentLocations.map((locationObj) => {
        const { id, name, responsibleRef } = locationObj

        const href = '/admin/ubicaciones/' + id
        const user = users.filter((user) => user.uid === responsibleRef?.id)
        // table items
        return {
          items: [
            {
              copy: name,
              grow: 2,
              to: href,
              icon: 'title',
            },
            {
              copy: user.length > 0 ? getNameFull(user[0].firstname, user[0].lastname) : '',
              grow: 2,
              label: 'Responsable:',
              icon: 'view',
            },
          ],
          disableEdit: false,
          raw: locationObj,
        }
      })
    }
  )

export const makeSelectLocationsByParentRef = (parentRef) =>
  createSelector([(state) => state.locations.list], (locations) =>
    locations.filter((location) => {
      if (parentRef === null) {
        return !location.parentRef
      }
      return location.parentRef?.id === parentRef
    })
  )

export const selectLocationsStatus = (state) => state.locations.status
