import styled from 'styled-components'
import { theme } from '../../../styleguide'
import { Body, Headline } from '../../Typography'

// partials.
export const Container = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '480px',
  width: '100%',
})

export const Wrapper = styled.div({
  alignItems: 'flex-start',
  background: (props) => props.theme || theme.colors.white,
  borderRadius: (props) => (props.isTablet && !props.isMinimal ? '16px 16px 0 0' : '16px'),
  border: (props) => `2px solid ${props.theme || theme.colors.ash}`,
  boxShadow: theme.shadows.larger,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  overflowY: (props) => (props.isTablet ? 'auto' : 'hidden'),
  padding: (props) => (props.isTablet && !props.isMinimal ? '8px 0 80px' : '8px 0'),
  position: 'relative',
  transition: 'all 200ms ease',
  width: '100%',
  zIndex: (props) => props.zEl || 1,
})

export const ShadowWrapper = styled.div({
  background: (props) => props.theme || theme.colors.white,
  borderRadius: '16px 16px 0 0',
  border: (props) => `2px solid ${props.theme || theme.colors.ash}`,
  boxShadow: theme.shadows.normal,
  height: (props) => `${20 + props.zEl + 1}px`,
  margin: '0 auto -16px',
  filter: (props) => `brightness(${1 - (props.length + 1) / 30 + props.zEl / 20 || 1})`,
  transition: 'all 200ms ease',
  width: (props) => `${100 - (props.length + 1) + props.zEl}%`,
  zIndex: (props) => props.zEl || 0,
})

export const StyledType = styled(Headline)({
  letterSpacing: '1px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
})

export const StyledCaption = styled(Body)({
  color: theme.opacity.white70,
  margin: '4px 0',
  textTransform: 'uppercase',
})

export const StyledCountWrapper = styled.div({
  alignItems: (props) => (props.isTablet ? 'center' : 'flex-end'),
  display: 'flex',
  flexDirection: (props) => (props.isTablet ? 'row' : 'column'),
  gap: (props) => (props.isTablet ? '8px' : 0),
})

export const StyledBody = styled(Body)({
  letterSpacing: '1px',
  margin: 0,
  textTransform: 'uppercase',
})

export const StyledBodyWrapper = styled.div({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  gap: '4px',
  width: '100%',
})

export const HeadingWrapper = styled.div({
  alignItems: 'flex-end',
  borderBottom: (props) => (props.isMinimal ? 'null' : `1px solid ${theme.opacity.black20}`),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: (props) => (props.isMinimal ? '16px 16px 24px' : '8px 8px 16px'),
  width: '100%',
})

export const ContentWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 0',
  width: '100%',
})

export const FooterWrapper = styled.div({
  alignItems: 'flex-end',
  bottom: (props) => (props.isTablet ? 0 : 'unset'),
  display: 'flex',
  justifyContent: 'center',
  left: (props) => (props.isTablet ? '50%' : 'unset'),
  margin: '0 auto',
  padding: '8px 16px 24px',
  width: (props) => (props.isTablet ? 'calc(100% - 120px)' : '100%'),
})

export const ContentColumn = styled.div({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  gap: '8px',
  padding: '8px 16px',
})

export const AuthorWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
})

export const DateWrapper = styled.div({
  background: theme.opacity.black20,
  border: `2px solid ${theme.opacity.black20}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  height: '48px',
  justifyContent: 'center',
  width: '64px',
})

export const DateBoxContent = styled.div({
  background: theme.opacity.white80,
  borderRadius: '0 0 5px 5px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'flex-end',
})

export const DescriptionWrapper = styled.div({
  alignItems: 'flex-start',
  borderTop: `1px solid ${theme.opacity.black20}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  justifyContent: 'space-between',
  padding: '16px',
  width: '100%',
})

export const DescriptionContent = styled.div({
  alignItems: 'flex-start',
  background: theme.opacity.black20,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '8px',
  width: '100%',
})

export const AnomaliesContent = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px 16px',
  justifyContent: 'flex-start',
  width: '100%',
})

export const Anomaly = styled(Body)({
  whiteSpace: 'nowrap',
})
