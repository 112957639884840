// dependencies.
import styled from 'styled-components'
// components.
import { Button } from '../Button'
import { Input } from '../Form'
import { Icon } from '../Icon'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// partials.
const Wrapper = styled.div(
  {
    alignItems: 'center',
    backgroundColor: theme.colors.smoke,
    borderBottom: (props) => (props.isMobile ? `1px solid ${theme.colors.ash}` : 'none'),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '-24px -16px 0',
    padding: (props) => (props.isMobile ? '8px' : '24px 16px 40px'),
    width: 'calc(100% + 32px)',
    gap: '8px',
  },
  `@media print {
    margin: 0;
    width: 100%;
  }`
)

const InputWrapper = styled.div({
  display: 'flex',
  width: (props) => (props.isMobile ? '100%' : '50%'),
})

const StyledInput = styled(Input)({
  width: '100%',
})

const StyledButton = styled(Button)({
  padding: 0,
  height: '48px',
  width: '48px',
  minWidth: '48px',
})

// main component.
const SearchHeader = ({ label = 'Nuevo', placeholder = 'Buscar...', onClick, onChange }) => {
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper isMobile={isMobile}>
      <InputWrapper isMobile={isMobile}>
        <StyledInput
          name="search"
          iconL="search"
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
        />
      </InputWrapper>

      {onClick &&
        (isMobile ? (
          <StyledButton onClick={onClick} hideLabel={isMobile}>
            {isMobile ? <Icon name="plus" color="white" size="24" /> : <>{label}</>}
          </StyledButton>
        ) : (
          <Button onClick={onClick}>{label}</Button>
        ))}
    </Wrapper>
  )
}

export default SearchHeader
