// dependencies.
import { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// components.
import AuthTemplate from '../../templates/AuthScreen'
import { Label } from '../../components/Typography'
// utils.
import notifyToast from '../../js/notifyToast'
// constants.
import { DEFAULT_FORM } from './constants'
import { SIGN_UP_ENABLED } from '../../constants'
import { getUserByDocument, getUserByDocumentAndCode } from '../../js/firebase/firebase'
import { Loader } from '../../components/Icon'
import { signIn } from '../../js/auth/authActions'
import { useAppDispatch } from '../../js/store/hooks'
/************************/
/*                      */
/*    Sign In Screen    */
/*                      */
/************************/

// partials.
const StyledButtons = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  justifyContent: 'center',
  margin: '40px auto',
  maxWidth: 360,
})

const SigninScreen = () => {
  const navigate = useNavigate()
  const [document, setDocument] = useState('')
  const [password, setPassword] = useState('')
  const [loaded, setLoaded] = useState(false)
  const dispatch = useAppDispatch()

  const handleValueChange = (values) => {
    const { document, password } = values
    setDocument(document)
    setPassword(password)
  }

  const handleRegister = () => {
    navigate('/signup')
  }

  const handleForgotPassword = () => {
    navigate('/forgot-password')
  }

  const handleSignInClick = async (document, password) => {
    // check if first signin
    if (!document) return notifyToast('Debe ingresar su documento', 'error')
    if (!password) return notifyToast('Debe ingesar su contraseña', 'error')
    setLoaded(true)
    let userDoc = await getUserByDocumentAndCode(document, password)
    if (userDoc) {
      navigate(`/activar-funcionario?document=${document}&code=${password}`)
    } else {
      userDoc = await getUserByDocument(document)
      if (userDoc) {
        const email = userDoc.data().email
        dispatch(signIn({ email, password }))
        setLoaded(false)
      } else {
        setLoaded(false)
        return notifyToast('Credenciales incorrectas', 'error')
      }
    }
  }

  return (
    <AuthTemplate
      disbledSubmit={loaded}
      description="Inicie sesión para acceder a su panel"
      form={DEFAULT_FORM}
      submitLabel="Ingresar"
      topbarTitle="Iniciar sesión"
      onChange={(value) => handleValueChange(value)}
      onSubmit={() => handleSignInClick(document, password)}
    >
      <StyledButtons>
        {SIGN_UP_ENABLED && (
          <Label color="grey" onClick={() => handleRegister()} nowrap underline>
            Registrarse como funcionario
          </Label>
        )}
        <Label color="grey" onClick={() => handleForgotPassword()} nowrap underline>
          Olvidé mi contraseña
        </Label>
      </StyledButtons>
      {loaded && <Loader />}
    </AuthTemplate>
  )
}

export default SigninScreen
