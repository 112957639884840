// helpers.
const splitInitial = (str, range = 1) => str.toUpperCase().slice(0, range)

// returns initials for first name and last name.
export const getNameInitials = (first, last) => {
  if (!first) return splitInitial(last, 2)
  if (!last) return splitInitial(first, 2)

  const firstName = splitInitial(first)
  const lastName = splitInitial(last)

  return `${firstName}${lastName}`
}

// returns full name string from first and last.
export const getNameFull = (first, last, middle = null) => {
  if (!first) return last
  if (!last) return first

  if (middle) return `${first} ${middle} ${last}`

  return `${first} ${last}`
}

// return object from full name string.
export const chunkNameFull = (name) => {
  const first = name.split(' ').slice(0, -1).join(' ')
  const last = name.split(' ').slice(-1).join(' ')

  return { first, last }
}
