// dependencies.
import { useRef } from 'react'
import styled from 'styled-components'
// components.
import { Icon } from '../Icon'
import { Headline, Body } from '../Typography'
// utils.
import { theme } from '../../styleguide'
import { useDimensions, useHover } from '../../js/hooks'

/************************/
/*                      */
/*    Card Component    */
/*                      */
/************************/

// helpers.
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div(
  {
    alignItems: 'center',
    background: theme.colors.white,
    borderRadius: `${baseUnit}px`,
    border: (props) => (props.flat ? `1px solid ${theme.colors.ash}` : 0),
    cursor: (props) => (props.onClick ? 'pointer' : 'default'),
    filter: (props) =>
      props.flat
        ? 'none'
        : props.isHover
          ? `drop-shadow(${theme.shadows.larger})`
          : `drop-shadow(${theme.shadows.normal})`,
    display: 'flex',
    flexDirection: 'column',
    padding: `${baseUnit / 2}px`,
    transition: 'all 200ms ease',
    width: (props) => (props.isMobile ? '100%' : `${props.width}px`),
  },
  `@media print {
    border-width: 0.5px;
  }`
)

const Header = styled.div({
  display: 'flex',
  gap: `${baseUnit}px`,
  height: `${baseUnit * 2.5}px`,
  padding: `${baseUnit / 2}px`,
  width: '100%',
})

const ContentWrapper = styled.div({
  background: theme.colors.smoke,
  borderRadius: `${baseUnit}px`,
  display: 'flex',
  flexWrap: 'wrap',
  gap: `${baseUnit / 2}px`,
  padding: `${baseUnit}px`,
  width: '100%',
})

const ChildrenWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit}px`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
})

const ItemWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  width: (props) => (props.grow ? '100%' : 'calc(50% - 4px)'),
})

const StyledBody = styled(Body)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
})

const ItemElement = ({ icon, copy, grow }) => (
  <ItemWrapper grow={grow}>
    <Icon name={icon} size={16} />
    <StyledBody>{copy}</StyledBody>
  </ItemWrapper>
)

const TitleElement = styled(Headline)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

// main component.
const Card = ({ item, width = 320, title, className, flat = false, children, onClick, onEdit }) => {
  // refs.
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle edit / card click.
  const hendleOnClick = () => {
    if (onEdit) return onEdit()
    return onClick()
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show if there's items.
  if (!title && !item?.name) return null

  // return content.
  return (
    <Wrapper
      ref={hoverRef}
      width={width}
      flat={flat}
      isHover={isHover}
      isMobile={isMobile}
      onClick={hendleOnClick}
    >
      <Header>
        <TitleElement className={className}>{title || item?.name}</TitleElement>

        {!!onEdit && <Icon name="edit" size={16} />}
      </Header>

      {children ? (
        <ChildrenWrapper>{children}</ChildrenWrapper>
      ) : (
        <ContentWrapper>
          {!!item.items &&
            item.items.map((item, i) => (
              <ItemElement key={i} icon={item.icon} copy={item.copy} grow={item.grow} />
            ))}
        </ContentWrapper>
      )}
    </Wrapper>
  )
}

export default Card
