export const sortingBudgets = (items, direction, sortDbKey) => {
  const sortedData = [...items]
  if (direction === null) return sortedData

  const isAscending = direction === 'caret-down'

  sortedData.sort((a, b) => {
    let aValue
    let bValue
    switch (sortDbKey) {
      case 'startDate':
        aValue = new Date(a.startDate)
        bValue = new Date(b.startDate)
        break
      default:
        aValue = a[sortDbKey]
        bValue = b[sortDbKey]
    }

    if (aValue < bValue) {
      return isAscending ? -1 : 1
    }
    if (aValue > bValue) {
      return isAscending ? 1 : -1
    }
    return 0
  })

  return sortedData
}
