// dependencies.
import { createGlobalStyle } from 'styled-components'
// utils.
import { theme } from '../../styleguide'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html,
  body,
  #root {
    background-color: ${(props) => props.background};
    min-height: ${(props) => props.height}px;
    overflow-x: hidden;

    @media print {
      background-color: ${theme.colors.white};
      margin: 0 auto;
      min-height: auto;
    }
  }

  body {
    @media print {
      max-width: 800px;
      padding: 16px;
    }
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  @media print {
    @page { margin: 0; }
  }
`

export default GlobalStyle
