import { createSelector } from 'reselect'
import { searchAnomaliesByNameType } from '../../components/screens/Anomalies/SearchAnomaliesByNameType'

export const selectAnomaliesData = (state) => state.anomalies.data
export const selectSearchTerm = (state) => state.anomalies.filters.searchTerm
export const selectAnomaliesSort = (state) => state.anomalies.filters.sort

export const selectAnomaliesForSelect = createSelector([selectAnomaliesData], (anomalies) => {
  return anomalies.reduce(function (filtered, anomalyObj) {
    const anomaly = {
      label: anomalyObj.name,
      name: anomalyObj.ref.id,
      type: anomalyObj.type,
    }
    filtered.push(anomaly)
    return filtered
  }, [])
})

export const selectAnomaliesForTable = createSelector([selectAnomaliesData], (anomalies) => {
  return anomalies.map((anomalyObj) => {
    const { name, type } = anomalyObj

    // table items.
    return {
      items: [
        { copy: name, grow: 2, label: 'Nombre:', icon: 'title' },
        { copy: type, grow: 2, label: 'Tipo:', icon: 'view' },
      ],
      disableEdit: false,
      raw: anomalyObj,
    }
  })
})

export const selectFilteredAnomalies = () =>
  createSelector(
    [selectAnomaliesData, selectSearchTerm, selectAnomaliesSort],
    (anomalies, searchTerm, sort) => {
      // Aplica los filtros uno por uno
      let filteredAnomalies = anomalies
      if (searchTerm) {
        filteredAnomalies = searchAnomaliesByNameType(filteredAnomalies, searchTerm)
      }

      // if (sort) {
      //   filteredAnomalies = sortingAnomalies(filteredAnomalies, sort.direction, sort.key)
      // }
      return filteredAnomalies
    }
  )

// Select the status of loading the anomalies.
export const selectAnomaliesStatus = (state) => state.anomalies.status
