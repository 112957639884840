// dependencies.
import { onSnapshot } from 'firebase/firestore'
// constants.
import { IS_DEV } from '../../constants'

const subscribeOnSnapshot = (q, access, userData, dispatch, collectionUpdated, name) => {
  if (!access.includes(userData.access)) return
  return onSnapshot(
    q,
    (snapshot) => {
      IS_DEV && console.log('Subscribe', name)
      const locations = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))

      dispatch(collectionUpdated(locations))
    },
    (error) => {
      IS_DEV && console.error('Error on snapshot:', error)
    }
  )
}

export default subscribeOnSnapshot
