// dependencies.
import styled from 'styled-components'
// utils.
import { useDimensions } from '../../js/hooks'
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'
// constants.
import { FILTERS_ASIDE_WIDTH, NAVIGATION_WIDTH, NAVIGATION_CONDENSED_WIDTH } from '../../constants'

// helpers.
const nav = {
  m: NAVIGATION_CONDENSED_WIDTH,
  d: NAVIGATION_WIDTH,
}

const filters = FILTERS_ASIDE_WIDTH

// partials.
const Wrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    margin: 0,
    minHeight: (props) => `${props.height}px`,
    marginLeft: (props) =>
      props.isMobile
        ? 0
        : props.condensed
          ? props.filtersAsideActive
            ? `${filters + nav.m}px`
            : `${nav.m}px`
          : props.filtersAsideActive
            ? `${filters + nav.d}px`
            : `${nav.d}px`,
    position: 'relative',
    top: 0,
    width: (props) =>
      props.isMobile
        ? '100%'
        : props.condensed
          ? props.filtersAsideActive
            ? `calc(100% - ${filters + nav.m}px)`
            : `calc(100% - ${nav.m}px)`
          : props.filtersAsideActive
            ? `calc(100% - ${filters + nav.d}px)`
            : `calc(100% - ${nav.d}px)`,
    transition: 'all 200ms ease',
    ...(props) => props.isMobile && props.showNavigation && { overflow: 'hidden' },
  },
  `@media print {
    margin-left: 0;
    top: 0;
    width: 100%;
  }`
)

const BoxWrapper = styled.div(
  {
    alignItems: 'center',
    background: (props) => props.background,
    boxShadow: theme.shadows.normal,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 0,
    minWidth: (props) => (props.isMobile ? '100vw' : 'unset'),
    overflow: 'hidden',
    width: '100%',
  },
  `@media print {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }`
)

const Content = styled.div({
  flex: 1,
  flexDirection: 'column',
  width: '100%',
})

// main component.
const ContentWrapper = ({
  className,
  background,
  showNavigation,
  condensed,
  filtersAsideActive,
  children,
}) => {
  const { height, isMobile } = useDimensions()

  // make sure color is included in the library.
  const { colors } = theme
  const backgroundColor = styleguideHasKey(background, colors, 'smoke')

  // return content.
  return (
    <Wrapper
      isMobile={isMobile}
      condensed={condensed}
      filtersAsideActive={filtersAsideActive}
      height={height}
      showNavigation={showNavigation}
    >
      <BoxWrapper className={className} isMobile={isMobile} background={colors[backgroundColor]}>
        <Content>{children}</Content>
      </BoxWrapper>
    </Wrapper>
  )
}

export default ContentWrapper
