import { collection, orderBy, query } from 'firebase/firestore'
import { db } from '../firebase/firebase'
import { createSlice } from '@reduxjs/toolkit'
import { createFetchThunk } from '../store/fetch'

const q = query(collection(db, 'anomalies'), orderBy('name', 'asc'))
const access = ['superadmin', 'operator', 'developer']

export const fetchAnomalies = createFetchThunk('anomalies', q, access)

const anomaliesSlice = createSlice({
  name: 'anomalies',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    filters: {
      searchTerm: '',
      sort: {
        key: 'name',
        direction: 'asc',
      },
    },
  },
  reducers: {
    anomalyAdded: (state, action) => {
      state.data.push(action.payload)
    },
    anomalyUpdated: (state, action) => {
      const index = state.data.findIndex((anomaly) => anomaly.id === action.payload.id)
      if (index !== -1) {
        state.data[index] = action.payload
      }
    },
    anomaliesUpdated: (state, action) => {
      state.data = action.payload
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnomalies.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAnomalies.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchAnomalies.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const { anomalyAdded, anomalyUpdated, anomaliesUpdated, setFilters } = anomaliesSlice.actions
export default anomaliesSlice.reducer
