// dependencies.
import { forwardRef, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
// components.
import { BottomBar, ContentWrapper, Navigation, ScreenWrapper } from '../components/Layout'
import Head from '../components/Meta/Head'
import FiltersAside from '../components/Filters/FiltersAside'
// utils.
import setBackgroundColor from '../js/setBackgroundColor'
import { useDimensions } from '../js/hooks'
import { useAppSelector } from '../js/store/hooks'
import { auth } from '../js/firebase/firebase'
import { selectUserData } from '../js/auth/authSelectors'

/******************************/
/*                            */
/*    Admin Panel Template    */
/*                            */
/******************************/

const AdminTemplate = (
  {
    navigation,
    modalOpen,
    filtersOpen,
    filterComponents,
    children,
    handleIsCondensed = () => {},
    onOverlayClick,
    onCondensedChange = () => {},
    setFiltersOpen,
  },
  ref
) => {
  // hooks.
  const navigate = useNavigate()
  const userData = useAppSelector(selectUserData)
  const { isMobile, isTablet } = useDimensions()
  // data objects.
  const cookies = new Cookies()
  const condensedCookie = cookies.get('navigation-condensed') === 'true'
  // states.
  const [isCondensed, setIsCondensed] = useState(condensedCookie || false)

  // ------------------------------------------------------------------------------------------- //
  // USER SESSION                                                                                //
  // ------------------------------------------------------------------------------------------- //
  // handle logged out users.
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) navigate('/signin')
    })

    return () => unsubscribe()
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // get navigation menu title and items.
  const { title, items } = navigation

  // set condensed as default for tablet screens.
  useEffect(() => {
    if (isTablet && !cookies.get('navigation-condensed')) setIsCondensed(true)
  }, [isTablet])

  useEffect(() => {
    cookies.remove('navigation-condensed')
    cookies.set('navigation-condensed', isCondensed, { path: '/' })
    handleIsCondensed(isCondensed)
  }, [isCondensed, isTablet])

  useEffect(() => {
    onCondensedChange(isCondensed)
  }, [isCondensed])

  // return content.
  return (
    <>
      <Head color={setBackgroundColor(userData.access)} />

      <ScreenWrapper
        ref={ref}
        background={setBackgroundColor(userData.access)}
        modalOpen={modalOpen}
        onClick={onOverlayClick}
      >
        {items.length && isMobile ? (
          <BottomBar items={items} />
        ) : (
          <Navigation
            title={title}
            items={items}
            condensed={isCondensed}
            onClick={isMobile ? () => {} : () => setIsCondensed(!isCondensed)}
          />
        )}

        {filtersOpen && (
          <FiltersAside isActive={filtersOpen} onClose={setFiltersOpen} condensedNav={isCondensed}>
            {filterComponents}
          </FiltersAside>
        )}

        <ContentWrapper background="white" condensed={isCondensed} filtersAsideActive={filtersOpen}>
          {children}
        </ContentWrapper>
      </ScreenWrapper>

      <ToastContainer />
    </>
  )
}

export default forwardRef(AdminTemplate)
