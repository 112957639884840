// dependencies.
import { Fragment } from 'react'
import styled from 'styled-components'
// components.
import { Body, Label } from '../../components/Typography'
import { Icon } from '../../components/Icon'
// utils.
import { useDimensions } from '../../js/hooks'

/*******************************/
/*                             */
/*    Breadcrumbs Component    */
/*                             */
/*******************************/

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  margin: '0',
  maxWidth: '100%',
  overflowX: 'auto',
  padding: (props) => (props.isMobile ? '9px 8px' : '9px 16px'),
  width: '100%',
})

// main component.
const Breadcrumbs = ({ pathname, items }) => {
  const { isMobile } = useDimensions()
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  if (!items || items.length < 1)
    return (
      <Wrapper isMobile={isMobile}>
        <Body nowrap>Inicio</Body>
      </Wrapper>
    )

  // return content.
  return (
    <Wrapper isMobile={isMobile}>
      <Label to={`${pathname}`} underline nowrap>
        Inicio
      </Label>
      <Icon name="caret-right" size={10} />

      {items.map(({ name, ref }, i) => {
        if (items.length === i + 1)
          return (
            <Body key={i} nowrap>
              {name}
            </Body>
          )
        return (
          <Fragment key={i}>
            <Label to={`${pathname}/${ref.id}`} underline nowrap>
              {name}
            </Label>
            <Icon name="caret-right" size={10} />
          </Fragment>
        )
      })}
    </Wrapper>
  )
}

export default Breadcrumbs
