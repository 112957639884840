import { getNameFull } from '../usernameUtils'
import { createSelector } from 'reselect'
import { sortingUsers } from '../userSorting'
import { usersFilterByName } from '../userFilters'

export const selectUsersData = (state) =>
  state.users.data.filter((user) => user.access !== 'developer')

export const selectOperators = createSelector([selectUsersData], (users) => {
  return users.filter((operatorObj) => operatorObj.access === 'operator')
})

export const selectSearchTerm = (state) => state.users.filters.searchTerm
export const selectUsersSort = (state) => state.users.filters.sort

export const selectAdmins = createSelector([selectUsersData], (users) => {
  return users.filter((adminObj) => adminObj.access === 'superadmin')
})

export const selectOperatorsForSelect = createSelector([selectUsersData], (users) => {
  const activeOperators = users.filter((operatorObj) => operatorObj.isActive)
  // set in the select only the active anomalies
  return activeOperators.reduce(function (filtered, operatorObj) {
    const operator = {
      label: getNameFull(operatorObj.firstname, operatorObj.lastname),
      name: operatorObj.uid,
    }
    filtered.push(operator)
    return filtered
  }, [])
})

export const selectUsersForSelect = createSelector([selectUsersData], (users) => {
  const activeUsers = users.filter((userObj) => userObj.isActive)
  // set in the select only the active anomalies
  return activeUsers.reduce(function (filtered, userObj) {
    const user = {
      label: getNameFull(userObj.firstname, userObj.lastname),
      name: userObj.uid,
    }
    filtered.push(user)
    return filtered
  }, [])
})

// TODO: remove table selectors.
export const selectOperatorsForTable = createSelector(
  [selectOperators], // Usar selectOperators aquí en lugar de selectUsersData
  (operators) =>
    operators.map((operatorObj) => {
      const { firstname, lastname, document, isActive } = operatorObj

      // table items.
      return {
        items: [
          { copy: getNameFull(firstname, lastname), grow: 2, label: 'Nombre', icon: 'user' },
          { copy: document, label: 'Documento:', icon: 'copy', grow: 2 },
          {
            copy: isActive ? 'Si' : 'No',
            label: 'Es activo:',
            icon: 'title',
            type: 'pill',
            pillBackground: isActive ? 1 : 0,
          },
        ],
        raw: operatorObj,
      }
    })
)

export const selectAdminsForTable = createSelector([selectAdmins], (admins) => {
  return admins.map((adminObj) => {
    const { firstname, lastname, email, isActive, document } = adminObj

    // table items.
    return {
      items: [
        { copy: getNameFull(firstname, lastname), grow: 2, label: 'Nombre:', icon: 'user' },
        { copy: email, grow: 2, label: 'E-Mail:', icon: 'copy' },
        { copy: document, grow: 2, label: 'Documento:', icon: 'copy' },
        {
          copy: isActive ? 'Si' : 'No',
          label: 'Es activo:',
          icon: 'title',
          type: 'pill',
          pillBackground: isActive ? 1 : 0,
        },
      ],
      raw: adminObj,
    }
  })
})

export const selectFilteredUsers = () =>
  createSelector(
    [selectUsersData, selectSearchTerm, selectUsersSort],
    (users, searchTerm, sort) => {
      // Aplica los filtros uno por uno
      let filteredUsers = users

      if (searchTerm) {
        filteredUsers = usersFilterByName(filteredUsers, searchTerm)
      }

      if (sort) {
        filteredUsers = sortingUsers(filteredUsers, sort.direction, sort.key)
      }
      return filteredUsers
    }
  )

export const selectUsersStatus = (state) => state.users.status
