// dependencies.
import moment from 'moment'
// constants.
import {
  DATE_FORMAT,
  US_DATE_FORMAT,
  MONTHS_ES,
  MONTHS_SHORT_ES,
  DAYS_ES,
  DAYS_SHORT_ES,
  DAYS_MIN_ES,
} from '../constants'

// us date specific.
export const dateFormatUS = (date, format = US_DATE_FORMAT) => {
  // if no date, then use current date.
  if (!date) return new Date()

  let d

  // parse date input
  if (date instanceof Date && !isNaN(date)) {
    d = date
  } else {
    d = new Date(date)
  }

  // parse date from firebase.
  if (date?.seconds !== null && date?.seconds !== undefined) d = new Date(date.seconds * 1000)

  // init moment.js instance.
  const localLocale = moment(d)

  return localLocale.format(format)
}

// object function.
export const dateFormatObject = (date) => {
  // if no date, then use current date.
  if (!date) return new Date()

  let d

  // parse date input
  if (date instanceof Date && !isNaN(date)) {
    d = date
  } else {
    d = new Date(`${date} 00:00:00`)
  }

  // parse date from firebase.
  if (date?.seconds !== null && date?.seconds !== undefined) d = date.seconds * 1000

  // init moment.js instance.
  const localLocale = moment(d)

  // update moment.js locale.
  moment.updateLocale('es', {
    months: MONTHS_ES.split('_'),
    monthsShort: MONTHS_SHORT_ES.split('_'),
    weekdays: DAYS_ES.split('_'),
    weekdaysShort: DAYS_SHORT_ES.split('_'),
    weekdaysMin: DAYS_MIN_ES.split('_'),
  })

  return {
    day: localLocale.format('D'),
    month: localLocale.format('MMM'),
    mm: localLocale.format('MM'),
    year: localLocale.format('YYYY'),
    yy: localLocale.format('YY'),
  }
}

// main function.
export const dateFormatMttr = (date1, date2) => {
  // if no date, then use current date.
  if (!date1 || !date2) return '--'

  let d1
  let d2

  // parse date input
  if (date1 instanceof Date && !isNaN(date1)) {
    d1 = date1
  } else {
    d1 = new Date(date1)
  }

  if (date2 instanceof Date && !isNaN(date2)) {
    d2 = date2
  } else {
    d2 = new Date(date2)
  }

  // parse date from firebase.
  if (date1?.seconds !== null && date1?.seconds !== undefined) d1 = date1.seconds * 1000
  if (date2?.seconds !== null && date2?.seconds !== undefined) d2 = date2.seconds * 1000

  // init moment.js instance.
  const localLocale1 = moment(d1).startOf('day')
  const localLocale2 = moment(d2).startOf('day')

  return localLocale1.diff(localLocale2, 'days')
}

// main function.
const dateFormat = (date, format = DATE_FORMAT) => {
  // if no date, then use current date.
  if (!date) return new Date()

  let d

  // parse date input
  if (date instanceof Date && !isNaN(date)) {
    d = date
  } else {
    d = new Date(date)
  }

  // parse date from firebase.
  if (date?.seconds !== null && date?.seconds !== undefined) d = date.seconds * 1000

  // init moment.js instance.
  const localLocale = moment(d)

  // update moment.js locale.
  moment.updateLocale('es', {
    months: MONTHS_ES.split('_'),
    monthsShort: MONTHS_SHORT_ES.split('_'),
    weekdays: DAYS_ES.split('_'),
    weekdaysShort: DAYS_SHORT_ES.split('_'),
    weekdaysMin: DAYS_MIN_ES.split('_'),
  })

  return localLocale.format(format)
}

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d)
}
export const setStartDate = (date) => {
  if (!isValidDate(new Date(`${date} 00:00:00`))) return null
  return new Date(`${date} 00:00:00`)
}

export const setEndDate = (date) => {
  if (!isValidDate(new Date(`${date} 23:59:59`))) return null
  return new Date(`${date} 23:59:59`)
}

export default dateFormat
