// dependencies.
import { useEffect, useState } from 'react'

import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import {
  AnomaliesDesktopItem,
  AnomaliesDesktopSort,
  AnomaliesDesktopWrapper,
  AnomaliesMobileItem,
  AnomaliesMobileSort,
  AnomaliesMobileWrapper,
} from '../../components/screens/Anomalies'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import Header from '../../components/Layout/Header'
import { Button } from '../../components/Button'
// utils.
import notifyToast from '../../js/notifyToast'
import { db } from '../../js/firebase/firebase'
import { useDimensions } from '../../js/hooks'
// constants.
import { NEW_ANOMALY_FORM } from './constants'
import FloatingButton from '../../components/Button/MobileButton'
import {
  anomalyAdded,
  anomalyUpdated,
  fetchAnomalies,
  setFilters,
} from '../../js/anomalies/anomaliesSlice'
import {
  selectAnomaliesData,
  selectAnomaliesStatus,
  selectFilteredAnomalies,
} from '../../js/anomalies/anomaliesSelectors'
import { useAppDispatch, useAppSelector } from '../../js/store/hooks'
import SearchAnomaliesByNameType from '../../components/screens/Anomalies/SearchAnomaliesByNameType'

/**********************************/
/*                                */
/*    Admin Anomalies Screen    */
/*                                */
/**********************************/

// helpers.
const selectCardType = [
  { label: 'Seguridad', name: 'TS' },
  { label: 'Órden y Limpieza', name: 'TO' },
  { label: 'Mantenimiento', name: 'TM' },
  { label: 'Calidad', name: 'TQ' },
]

// main component.
const AdminAnomaliesScreen = ({ navMenu, navTitle }) => {
  const dispatch = useAppDispatch()
  const anomalies = useAppSelector(selectAnomaliesData)
  const loaded = useAppSelector(selectAnomaliesStatus)
  const filteredAnomaliesSelector = selectFilteredAnomalies()
  const filteredAnomalies = useAppSelector(filteredAnomaliesSelector)

  const { isMobile } = useDimensions()

  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (loaded === 'idle') dispatch(fetchAnomalies())
  }, [dispatch, loaded])

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //
  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new anomaly modal.
  const handleNewAnomalyModal = () => {
    setModalContent({
      title: 'Agregar nueva anomalía',
      component: (
        <Form
          items={NEW_ANOMALY_FORM(selectCardType)}
          onClick={(obj) => handleCreateAnomalyClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected anomaly modal.
  const handleEditAnomalyModal = (obj) => {
    setModalContent({
      title: 'Actualizar anomalía',
      component: (
        <Form
          items={NEW_ANOMALY_FORM(selectCardType)}
          defaultValues={obj}
          onClick={(obj) => handleSaveAnomalyClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new anomaly.
  const handleCreateAnomalyClick = async (obj) => {
    obj.createdDate = new Date()
    obj.updatedDate = new Date()

    await addDoc(collection(db, 'anomalies'), obj)
      .then(async (anomalyRef) => {
        await setDoc(anomalyRef, { ref: anomalyRef }, { merge: true })
        notifyToast('Anomalía creada correctamente', 'success')
        const newAnomalyDoc = await getDoc(anomalyRef)
        const newAnomaly = { id: newAnomalyDoc.id, ...newAnomalyDoc.data() }
        dispatch(anomalyAdded(newAnomaly))
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // handle anomaly information update.
  const handleSaveAnomalyClick = async (obj) => {
    obj.updatedDate = new Date()
    const anomalyRef = doc(db, 'anomalies', obj.ref.id)
    await setDoc(anomalyRef, { ...obj })
      .then(async () => {
        const updatedAnomalyDoc = await getDoc(anomalyRef)
        const updatedAnomaly = { id: updatedAnomalyDoc.id, ...updatedAnomalyDoc.data() }
        dispatch(anomalyUpdated(updatedAnomaly))
        notifyToast('Anomalía guardada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <Header justifyContent="space-between">
          <SearchAnomaliesByNameType
            items={anomalies}
            onSearch={(term) => {
              dispatch(setFilters({ searchTerm: term }))
            }}
          />
          {isMobile ? (
            <FloatingButton onClick={() => handleNewAnomalyModal()} />
          ) : (
            <Button onClick={() => handleNewAnomalyModal()}>Agregar anomalía</Button>
          )}
        </Header>

        {loaded === 'succeeded' ? (
          isMobile ? (
            <AnomaliesMobileWrapper isEmpty={!!filteredAnomalies && !filteredAnomalies.length}>
              <AnomaliesMobileSort />

              {filteredAnomalies.map((anomaly, i) => (
                <AnomaliesMobileItem
                  key={anomaly.id}
                  item={anomaly}
                  isEven={i % 2 === 0}
                  onEdit={() => handleEditAnomalyModal(anomaly)}
                />
              ))}
            </AnomaliesMobileWrapper>
          ) : (
            <AnomaliesDesktopWrapper isEmpty={!!filteredAnomalies && !filteredAnomalies.length}>
              <AnomaliesDesktopSort />

              {filteredAnomalies.map((anomaly, i) => (
                <AnomaliesDesktopItem
                  key={anomaly.id}
                  item={anomaly}
                  isEven={i % 2 === 0}
                  onEdit={() => handleEditAnomalyModal(anomaly)}
                />
              ))}
            </AnomaliesDesktopWrapper>
          )
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminAnomaliesScreen
