// dependencies.
import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
// components.
import Label from '../Typography/Label'
import { Icon } from '../Icon'
// utils.
import { theme } from '../../styleguide'
import { useDimensions, useHover } from '../../js/hooks'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const StyledFilterButton = styled.span({
  alignItems: 'center',
  background: (props) => (props.isActive ? theme.colors.primary : 'transparent'),
  border: (props) =>
    props.isMobile
      ? 'none'
      : props.isActive
        ? `2px solid ${theme.colors.primary}`
        : `2px solid ${theme.colors.white}`,
  borderRadius: baseUnit / 2,
  cursor: 'pointer',
  display: 'flex',
  gap: baseUnit / 2,
  justifyContent: 'center',
  opacity: (props) => (props.hovered ? 0.6 : 1),
  padding: '12px 16px',
  width: 'fit-content',
  textAlign: 'center',
  transition: 'all 200ms ease',
  '&:focus': {
    opacity: 0.8,
    outline: 'none',
  },
})

// main component.
const ToggleSortingTooltipButton = ({ isActive, onChange = () => {} }) => {
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)
  const { isMobile } = useDimensions()

  const [isOpen, setIsOpen] = useState(false)

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // update value object.
  useEffect(() => {
    if (isActive) setIsOpen(isActive)
  }, [isActive])

  // propagate value.
  useEffect(() => {
    if (!onChange) return
    onChange(isOpen)
  }, [isOpen])

  // return content.
  return (
    <StyledFilterButton
      ref={hoverRef}
      hovered={isHover}
      isActive={isOpen}
      isMobile={isMobile}
      tabIndex={0}
      onClick={() => setIsOpen(!isOpen)}
    >
      {!isOpen && <Icon name="settings" size={20} color="white" />}
      <Label color="white">Ordenar</Label>
      {isOpen && <Icon name="close" size={20} color="white" />}
    </StyledFilterButton>
  )
}

export default ToggleSortingTooltipButton
