import { useEffect } from 'react'

import { fetchAnomalies } from '../../anomalies/anomaliesSlice'
import { fetchLocations } from '../../locations/locationsSlice'
import { fetchUsers } from '../../users/usersSlice'
import { fetchAllClosedCards, fetchClosedCards, fetchOpenCards } from '../cardsSlice'
import { selectAnomaliesStatus } from '../../anomalies/anomaliesSelectors'
import { selectLocationsStatus } from '../../locations/locationsSelectors'
import { selectUsersStatus } from '../../users/usersSelectors'
import { selectClosedCardsStatus, selectOpenCardsStatus } from '../cardsSelectors'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const useFetchCardsData = (limitClosedCards) => {
  const dispatch = useAppDispatch()

  const anomaliesStatus = useAppSelector(selectAnomaliesStatus)
  const locationsStatus = useAppSelector(selectLocationsStatus)
  const usersStatus = useAppSelector(selectUsersStatus)
  const openCardsStatus = useAppSelector(selectOpenCardsStatus)
  const closedCardsStatus = useAppSelector(selectClosedCardsStatus)

  useEffect(() => {
    const fetchData = async () => {
      if (anomaliesStatus === 'idle') dispatch(fetchAnomalies())
      if (usersStatus === 'idle') dispatch(fetchUsers())
      if (locationsStatus === 'idle') dispatch(fetchLocations())

      if (
        anomaliesStatus === 'succeeded' &&
        usersStatus === 'succeeded' &&
        locationsStatus === 'succeeded' &&
        openCardsStatus === 'idle'
      ) {
        dispatch(fetchOpenCards())
      } else if (openCardsStatus === 'succeeded' && closedCardsStatus === 'idle') {
        if (limitClosedCards) {
          dispatch(fetchClosedCards())
        } else {
          dispatch(fetchAllClosedCards())
        }
      }
    }
    fetchData()
  }, [dispatch, anomaliesStatus, locationsStatus, usersStatus, openCardsStatus, closedCardsStatus])

  return openCardsStatus === 'succeeded' && closedCardsStatus === 'succeeded'
    ? 'succeeded'
    : 'loading'
}

export default useFetchCardsData
