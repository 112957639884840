// dependencies.
import { useRef } from 'react'
import styled from 'styled-components'
// components.
import { Icon } from '../Icon'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'
import { useHover } from '../../js/hooks'

/**************************/
/*                        */
/*    Button Component    */
/*                        */
/**************************/

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.span(
  {
    alignItems: 'center',
    backgroundColor: (props) => props.background,
    borderRadius: '50%',
    boxShadow: (props) => (props.hovered ? theme.shadows.larger : theme.shadows.normal),
    cursor: (props) => (props.disabled ? 'not-allowed' : 'pointer'),
    display: 'flex',
    justifyContent: 'center',
    opacity: (props) => (props.hovered ? 0.6 : 1),
    width: '72px',
    height: '72px',
    position: 'fixed',
    bottom: '64px',
    right: '16px',
    textAlign: 'center',
    transition: 'all 200ms ease',
    zIndex: 9,
    '&:focus': {
      opacity: 0.8,
      outline: 'none',
    },
  },
  `@media print { display: none; }`
)

const StyledButton = styled.button({
  appearance: 'none',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  borderRadius: '36px',
  display: 'flex',
  flexDirection: 'row',
  gap: baseUnit / 2,
  height: '100%',
  justifyContent: 'center',
  width: '100%',
})

// main component.
const FloatingButton = ({
  className,
  variant,
  color = 'white',
  background,
  href,
  to,
  hideLabel,
  padding,
  disabled,
  children,
  onClick,
  width,
}) => {
  // refs.
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const variantBg = disabled ? 'grey' : variant === 'secondary' ? 'terciary' : 'primary'
  const backgroundColor = styleguideHasKey(background, colors, variantBg)

  // build props object.
  const props = {
    background: colors[backgroundColor],
    className,
    disabled,
    hovered: !disabled && isHover,
    ref: hoverRef,
    tabIndex: 0,
    width,
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper {...props} onClick={onClick}>
      {hideLabel ? (
        <StyledButton disabled={disabled} onClick={onClick}>
          {children}
        </StyledButton>
      ) : (
        <Icon name="plus" size={48} color={color} />
      )}
    </Wrapper>
  )
}

export default FloatingButton
