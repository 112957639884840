// dependencies.
import { useState } from 'react'
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import { Button } from '../Button'
import { Input } from '../Form'
// utils.
import { useDimensions } from '../../js/hooks'

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: (props) => (props.isTablet ? '8px' : '16px'),
  width: (props) => (props.column ? '100%' : 'fit-content'),
})

const InputContainer = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: 8,
  width: (props) => (props.column ? '100%' : 'fit-content'),
})

// main component.
const FilterDate = ({ label, onDateFilter = () => {}, defaultValue = {} }) => {
  const { isMobile, isTablet } = useDimensions()

  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()

  const handleFilterClick = () => {
    onDateFilter(fromDate, toDate)
  }

  // return content.
  return (
    <Wrapper column={!!label} isTablet={isTablet}>
      {label && (
        <Body type="caption" color="grey">
          {label}
        </Body>
      )}
      <InputContainer column={!!label}>
        <Input
          name="fromDate"
          placeholder="Desde"
          showLabel
          defaultValue={defaultValue.fromDate}
          onChange={(value) => setFromDate(value)}
          type="date"
          max={new Date().toISOString().split('T')[0]}
        />
        <Input
          name="toDate"
          placeholder="Hasta"
          showLabel
          defaultValue={defaultValue.toDate}
          onChange={(value) => setToDate(value)}
          type="date"
          max={new Date().toISOString().split('T')[0]}
        />
      </InputContainer>
      <Button isMobile={isMobile} onClick={handleFilterClick}>
        Filtrar fechas
      </Button>
    </Wrapper>
  )
}

export default FilterDate
