// dependencies.
import { useEffect, useState } from 'react'

// main function.
const useHover = (ref) => {
  // states.
  const [value, setValue] = useState(false)

  useEffect(() => {
    const onMouseOver = () => setValue(true)
    const onMouseOut = () => setValue(false)

    if (ref && ref.current) {
      ref.current.addEventListener('mouseover', onMouseOver)
      ref.current.addEventListener('mouseout', onMouseOut)
    }

    const { current } = ref

    return () => {
      if (current) {
        current.removeEventListener('mouseover', onMouseOver)
        current.removeEventListener('mouseout', onMouseOut)
      }
    }
  }, [ref])

  return value
}

export default useHover
