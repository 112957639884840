// depenmdencies.
import { useState } from 'react'
import styled from 'styled-components'
// components.
import { Input } from '../../../Form'
import { Body } from '../../../Typography'
// utils.
import { cardFilterByType } from '../../../../js/cardFilters'
import { theme } from '../../../../styleguide'
import moment from 'moment'
import { useAppSelector } from '../../../../js/store/hooks'
import { selectBudgetsData } from '../../../../js/budgets/budgetsSelectors'

// helpers.
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  gap: baseUnit * 2,
  height: '100%',
  width: '100%',
})

const InputWrapper = styled.div({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit,
  height: '100%',
  paddingTop: baseUnit / 2,
  width: '25%',
})

const StatsWrapper = styled.div({
  alignItems: 'stretch',
  border: `1px solid ${theme.opacity.ash40}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  padding: baseUnit / 2,
  width: '100%',
})

const StyledType = styled(Body)({
  '&:before': {
    backgroundColor: (props) => props.background,
    borderRadius: baseUnit / 4,
    content: '""',
    display: 'inline-block',
    filter: `drop-shadow(${theme.shadows.smaller})`,
    height: baseUnit / 2,
    marginRight: baseUnit / 4,
    width: baseUnit / 2,
  },
})

const StyledNumber = styled(Body)({
  alignItems: 'center',
  background: (props) => props.background,
  borderRadius: baseUnit,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  height: baseUnit * 2,
  justifyContent: 'center',
  maxHeight: baseUnit * 1.5,
  minWidth: baseUnit * 1.5,
  padding: baseUnit / 2,
})

const StatItem = styled.div({
  alignItems: 'center',
  borderBottom: (props) => (props.divider ? `1px solid ${theme.opacity.ash40}` : 'none'),
  display: 'flex',
  flexDirection: 'row',
  marginBottom: (props) => (props.divider ? `${baseUnit / 2}px` : 0),
  padding: (props) => `${props.divider ? baseUnit / 2 : 0}px 0`,
})

const Cell = styled.div({
  alignItems: (props) => props.align || 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 4}px ${baseUnit / 4}px`,
  width: (props) => `${props.width}%`,
})

const CardsByTypeAndFTE = ({ fromDate, toDate, cards = [] }) => {
  const [fteAmount, setFteAmount] = useState()
  const budgets = useAppSelector(selectBudgetsData)

  const tmArr = cardFilterByType(cards, 'TM')
  const toArr = cardFilterByType(cards, 'TO')
  const tqArr = cardFilterByType(cards, 'TQ')
  const tsArr = cardFilterByType(cards, 'TS')

  const tmLength = tmArr.length
  const toLength = toArr.length
  const tqLength = tqArr.length
  const tsLength = tsArr.length

  const buildFteBy100Index = (type) => (fteAmount ? Math.round((type / fteAmount) * 100) : null)
  const buildOtIndex = (arr, status) => arr.filter(({ otStatus }) => otStatus === status).length
  const buildClosedPercentage = (arr) => {
    if (arr.length === 0) return '0%'
    const percentage = (arr.filter(({ status }) => status === 2).length * 100) / arr.length || 0
    return `${percentage.toFixed(1)}%`
  }
  const buildOtifPercentage = (arr) => {
    if (arr.length === 0) return '0%'
    const closedStatusLength = arr.filter(({ status }) => status === 2).length || 0
    const otStatusLength = arr.filter(({ otStatus }) => otStatus === 2).length || 0
    const percentage = (otStatusLength * 100) / closedStatusLength || 0
    return `${percentage.toFixed(1)}%`
  }

  const getFTEBudgets = budgets.length > 0 ? budgets.filter(({ type }) => type === 'FTE') : []
  const getTMBudgets = budgets.length > 0 ? budgets.filter(({ type }) => type === 'TM') : []
  const getTOBudgets = budgets.length > 0 ? budgets.filter(({ type }) => type === 'TO') : []
  const getTQBudgets = budgets.length > 0 ? budgets.filter(({ type }) => type === 'TQ') : []
  const getTSBudgets = budgets.length > 0 ? budgets.filter(({ type }) => type === 'TS') : []

  const buildBudgetAvg = (bArr) => {
    if (!bArr || bArr.length === 0) return 0

    const fromDateObj = moment(fromDate)
    const toDateObj = moment(toDate)
    const daysForPeriod = toDateObj.diff(fromDateObj, 'days')
    let budgetsInPeriod = bArr.filter(({ startDate }) => {
      const startDateObj = moment(startDate)
      return startDateObj.isBetween(fromDateObj, toDateObj, null, '[]')
    })
    // if not found budgets in period -> get last budget
    if (budgetsInPeriod.length === 0) {
      // get last budget
      budgetsInPeriod = bArr
        .sort((a, b) => moment(b.startDate).diff(moment(a.startDate)))
        .slice(0, 1)
    }

    const previousBudget = bArr
      .filter(({ startDate }) => {
        const startDateObj = moment(startDate)
        return startDateObj.isBefore(fromDateObj)
      })
      .sort((a, b) => moment(b.startDate).diff(moment(a.startDate)))
      .slice(0, 1)[0]

    if (!previousBudget && budgetsInPeriod.length === 0) {
      return 0
    }

    if (!previousBudget) {
      if (budgetsInPeriod.length <= 1) {
        return budgetsInPeriod[0].quantity
      } else {
        const startingBudget = budgetsInPeriod[budgetsInPeriod.length - 1]
        const prevEndDateObj = moment(startingBudget.startDate)
        const daysForPrev = prevEndDateObj.diff(fromDateObj, 'days')
        const getDaysForPreviousBudget = Math.floor(startingBudget.quantity * daysForPrev)

        const avgNextBudgets = budgetsInPeriod.map(({ startDate, quantity }, i) => {
          const startDateObj = moment(startDate)
          const prevStartDateObj = budgetsInPeriod[i - 1]
            ? moment(budgetsInPeriod[i - 1].startDate)
            : toDateObj
          const daysForNext = prevStartDateObj.diff(startDateObj, 'days')

          return Math.floor(quantity * daysForNext)
        })
        const getDaysForNextBudget = avgNextBudgets.reduce((sum, days) => sum + days, 0)

        return Math.floor((getDaysForPreviousBudget + getDaysForNextBudget) / daysForPeriod)
      }
    } else {
      if (budgetsInPeriod.length <= 1) {
        const prevStartDateObj = moment(previousBudget.startDate)
        const prevEndDateObj = moment(budgetsInPeriod[0].startDate)
        const daysForPrev = prevEndDateObj.diff(prevStartDateObj, 'days')
        const getDaysForPreviousBudget = Math.floor(previousBudget.quantity * daysForPrev)

        const nextStartDateObj = moment(budgetsInPeriod[0].startDate)
        const daysForNext = toDateObj.diff(nextStartDateObj, 'days')
        const getDaysForNextBudget = Math.floor(budgetsInPeriod[0].quantity * daysForNext)

        return Math.floor((getDaysForPreviousBudget + getDaysForNextBudget) / daysForPeriod)
      } else {
        const prevStartDateObj = moment(previousBudget.startDate)
        const prevEndDateObj = moment(budgetsInPeriod[0].startDate)
        const daysForPrev = prevEndDateObj.diff(prevStartDateObj, 'days')
        const getDaysForPreviousBudget = Math.floor(previousBudget.quantity * daysForPrev)

        const avgNextBudgets = budgetsInPeriod.map(({ startDate, quantity }, i) => {
          const startDateObj = moment(startDate)
          const prevStartDateObj = budgetsInPeriod[i - 1]
            ? moment(budgetsInPeriod[i - 1].startDate)
            : toDateObj
          const daysForNext = prevStartDateObj.diff(startDateObj, 'days')

          return Math.floor(quantity * daysForNext)
        })
        const getDaysForNextBudget = avgNextBudgets.reduce((sum, days) => sum + days, 0)

        return Math.floor((getDaysForPreviousBudget + getDaysForNextBudget) / daysForPeriod)
      }
    }
  }

  const buildFTEBudgetAvg = buildBudgetAvg(getFTEBudgets)
  const buildTMBudgetAvg = buildBudgetAvg(getTMBudgets)
  const buildTOBudgetAvg = buildBudgetAvg(getTOBudgets)
  const buildTQBudgetAvg = buildBudgetAvg(getTQBudgets)
  const buildTSBudgetAvg = buildBudgetAvg(getTSBudgets)

  return (
    <Wrapper>
      <InputWrapper>
        <Body type="caption" color="grey">
          Ingresar cantidad de funcionarios
          <br />
          activos en el mes (FTE):
        </Body>
        <Input
          name="fte-amount"
          placeholder="Funcionarios activos..."
          defaultValue={buildFTEBudgetAvg}
          onChange={(value) => setFteAmount(value)}
        />
      </InputWrapper>

      <StatsWrapper>
        <StatItem divider>
          <Cell width={23} align="flex-start">
            <Body type="caption" color="grey">
              Tipo
            </Body>
          </Cell>
          <Cell width={11}>
            <Body type="caption" color="grey" align="center">
              Ppto.
            </Body>
          </Cell>
          <Cell width={11}>
            <Body type="caption" color="grey" align="center">
              Cantidad
            </Body>
          </Cell>
          <Cell width={11}>
            <Body type="caption" color="grey" align="center">
              Cantidad
              <br />
              (÷ FTE ×100)
            </Body>
          </Cell>
          <Cell width={11}>
            <Body type="caption" color="grey" align="center">
              Sin fecha compromiso
            </Body>
          </Cell>
          <Cell width={11}>
            <Body type="caption" color="grey" align="center">
              A tiempo
            </Body>
          </Cell>
          <Cell width={11}>
            <Body type="caption" color="grey" align="center">
              Con atraso
            </Body>
          </Cell>
          <Cell width={11}>
            <Body type="caption" color="grey" align="center">
              % de cierre
            </Body>
          </Cell>
          <Cell width={11}>
            <Body type="caption" color="grey" align="center">
              % OTIF
            </Body>
          </Cell>
        </StatItem>

        <StatItem divider>
          <Cell width={23} align="flex-start">
            <Body size="smaller">
              <strong>Tarjetas levantadas:</strong>
            </Body>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {Number(buildTMBudgetAvg) +
                Number(buildTOBudgetAvg) +
                Number(buildTQBudgetAvg) +
                Number(buildTSBudgetAvg)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {cards.length}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber
              background={theme.opacity.black10}
              size="smaller"
              color={!buildFteBy100Index(cards.length) && 'grey'}
            >
              {buildFteBy100Index(cards.length) || '∞'}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {buildOtIndex(cards, 0)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {buildOtIndex(cards, 2)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {buildOtIndex(cards, 1)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {buildClosedPercentage(cards)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {buildOtifPercentage(cards)}
            </StyledNumber>
          </Cell>
        </StatItem>

        <StatItem>
          <Cell width={23} align="flex-start">
            <StyledType background={theme.cards.ts} type="caption" nowrap>
              Seguridad:
            </StyledType>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildTSBudgetAvg}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {tsLength}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber
              background={theme.cards.ts10}
              size="smaller"
              color={!buildFteBy100Index(tsLength) && 'grey'}
            >
              {buildFteBy100Index(tsLength) || '∞'}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildOtIndex(tsArr, 0)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildOtIndex(tsArr, 2)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildOtIndex(tsArr, 1)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildClosedPercentage(tsArr)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildOtifPercentage(tsArr)}
            </StyledNumber>
          </Cell>
        </StatItem>

        <StatItem>
          <Cell width={23} align="flex-start">
            <StyledType background={theme.cards.to} type="caption" nowrap>
              Orden y Limpieza:
            </StyledType>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildTOBudgetAvg}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {toLength}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber
              background={theme.cards.to10}
              size="smaller"
              color={!buildFteBy100Index(toLength) && 'grey'}
            >
              {buildFteBy100Index(toLength) || '∞'}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildOtIndex(toArr, 0)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildOtIndex(toArr, 2)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildOtIndex(toArr, 1)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildClosedPercentage(toArr)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildOtifPercentage(toArr)}
            </StyledNumber>
          </Cell>
        </StatItem>

        <StatItem>
          <Cell width={23} align="flex-start">
            <StyledType background={theme.cards.tm} type="caption" nowrap>
              Mantenimiento:
            </StyledType>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildTMBudgetAvg}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {tmLength}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber
              background={theme.cards.tm10}
              size="smaller"
              color={!buildFteBy100Index(tmLength) && 'grey'}
            >
              {buildFteBy100Index(tmLength) || '∞'}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildOtIndex(tmArr, 0)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildOtIndex(tmArr, 2)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildOtIndex(tmArr, 1)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildClosedPercentage(tmArr)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildOtifPercentage(tmArr)}
            </StyledNumber>
          </Cell>
        </StatItem>

        <StatItem>
          <Cell width={23} align="flex-start">
            <StyledType background={theme.cards.tq} type="caption" nowrap>
              Calidad:
            </StyledType>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildTQBudgetAvg}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {tqLength}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber
              background={theme.cards.tq10}
              size="smaller"
              color={!buildFteBy100Index(tqLength) && 'grey'}
            >
              {buildFteBy100Index(tqLength) || '∞'}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildOtIndex(tqArr, 0)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildOtIndex(tqArr, 2)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildOtIndex(tqArr, 1)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildClosedPercentage(tqArr)}
            </StyledNumber>
          </Cell>
          <Cell width={11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildOtifPercentage(tqArr)}
            </StyledNumber>
          </Cell>
        </StatItem>
      </StatsWrapper>
    </Wrapper>
  )
}

export default CardsByTypeAndFTE
