// dependencies.
import { useRef } from 'react'
import styled from 'styled-components'
// components.
import Body from './Body'
import Label from './Label'
import { Icon } from '../Icon'
// utils.
import { theme } from '../../styleguide'
import { useHover } from '../../js/hooks'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const FilterListWrapper = styled.div({
  alignItems: 'center',
  background: (props) => (props.isEmpty ? 'none' : theme.opacity.black10),
  borderRadius: baseUnit / 4,
  display: 'flex',
  gap: baseUnit / 4,
  flexWrap: 'wrap',
  height: '120%',
  padding: baseUnit / 4,
  paddingRight: baseUnit * 8,
  position: 'relative',
  width: '100%',
})

const ClearAllButton = styled(Label)({
  position: 'absolute',
  right: baseUnit,
})

const Wrapper = styled.span({
  alignItems: 'center',
  background: theme.opacity.white90,
  border: `1px solid ${theme.colors.asphalt}`,
  borderRadius: baseUnit / 4,
  cursor: 'pointer',
  display: 'flex',
  filter: (props) =>
    props.hovered ? `drop-shadow(${theme.shadows.larger})` : `drop-shadow(${theme.shadows.normal})`,
  gap: baseUnit / 4,
  padding: '1px 6px',
  userSelect: 'none',
  width: 'fit-content',
})

export const FilterList = ({ className, items = [], onFilterClick, onClearAllClick }) => (
  <FilterListWrapper className={className} isEmpty={!items.length}>
    {items.length ? (
      <>
        {items.map((item, i) => {
          if (item.key === 'fromDate' || item.key === 'toDate')
            return <FilterChip key={i}>{item.label}</FilterChip>
          return (
            <FilterChip key={i} onClick={() => onFilterClick(item.key)}>
              {item.label}
            </FilterChip>
          )
        })}

        <ClearAllButton color="smoke" onClick={() => onClearAllClick()}>
          Borrar filtros
        </ClearAllButton>
      </>
    ) : null}
  </FilterListWrapper>
)

// main component.
const FilterChip = ({ className, children, onClick }) => {
  // refs.
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)

  // build props object.
  const props = {
    ref: hoverRef,
    hovered: isHover,
    tabIndex: 0,
    className,
    onClick,
  }

  const color = isHover ? 'asphalt' : 'grey'

  return (
    <Wrapper {...props}>
      <Body size="smallest" weight="bold" align="center" color={color} nowrap>
        {children}
      </Body>

      {onClick && <Icon name="close" size={12} color={color} />}
    </Wrapper>
  )
}

export default FilterChip
