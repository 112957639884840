import { createSlice } from '@reduxjs/toolkit'
import { setCookie, getCookie } from '../cookies'

const initialState = {
  userData: getCookie('userData') ? JSON.parse(getCookie('userData')) : {},
}

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUserData: (state, action) => {
      state.userData = action.payload.userData
      setCookie('userData', JSON.stringify(action.payload.userData), 30)
    },
    logout: (state) => {
      state.userData = {}
      setCookie('userData', '', -1) // Clear the user data cookie
    },
  },
})

export const { setCurrentUserData, logout } = authSlice.actions

// Selectors
export const selectUserData = (state) => state.user.userData

export default authSlice.reducer
