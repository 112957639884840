// components.
import { Body } from '../../Typography'
import { Button } from '../../Button'
import { Icon } from '../../Icon'
// utils.
import { dateFormatObject } from '../../../js/dateFormat'
import { useDimensions } from '../../../js/hooks'
import { useEffect, useState } from 'react'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { getNameFull } from '../../../js/usernameUtils'
import priorityFormat from '../../../js/priorityFormat'
import instalationStatusFormat from '../../../js/instalationStatusFormat'
import FileModal from '../../FileModal/FileModal'
import { useAppSelector } from '../../../js/store/hooks'
import { selectUserData } from '../../../js/auth/authSelectors'
import {
  Container,
  HeadingWrapper,
  StyledBodyWrapper,
  StyledType,
  StyledCountWrapper,
  StyledBody,
  StyledCaption,
  ContentColumn,
  ContentWrapper,
  AuthorWrapper,
  DateBoxContent,
  DescriptionWrapper,
  DescriptionContent,
  DateWrapper,
  FooterWrapper,
  AnomaliesContent,
  ShadowWrapper,
  Wrapper,
  Anomaly,
} from './ReportCardStyled'
import { getCardInfo } from './ReportCardHelpers'

const ReportCard = ({
  card,
  type,
  actionLabel,
  length,
  className,
  children,
  isMinimal = false,
  onClick,
  onActionClick,
}) => {
  const userData = useAppSelector(selectUserData)
  const { isTablet } = useDimensions()
  const [media, setMedia] = useState([])
  const [videoMedia, setVideoMedia] = useState([])
  const [evidenceMedia, setEvidenceMedia] = useState([])
  const [evidenceVideoMedia, setEvidenceVideoMedia] = useState([])

  const storage = getStorage()

  // drop shadow items.
  const array = Array.from({ length: length - 1 }, (_, index) => index)

  useEffect(() => {
    if (!card) return
    setMedia([])
    setVideoMedia([])
    setEvidenceMedia([])
    setEvidenceVideoMedia([])
    if (card.files) {
      card.files.forEach((file) => {
        const storageRef = ref(storage, file)
        getDownloadURL(storageRef)
          .then((url) => {
            setMedia((prevMedia) => [...prevMedia, url])
          })
          .catch((err) => {
            console.error(err)
          })
      })
    }

    if (card.videos) {
      card.videos.forEach((video) => {
        const storageRef = ref(storage, video)
        getDownloadURL(storageRef)
          .then((url) => {
            setVideoMedia((prevMedia) => [...prevMedia, url])
          })
          .catch((err) => {
            console.error(err)
          })
      })
    }

    if (card.evidenceFiles) {
      card.evidenceFiles.forEach((file) => {
        const storageRef = ref(storage, file)
        getDownloadURL(storageRef)
          .then((url) => {
            setEvidenceMedia((prevMedia) => [...prevMedia, url])
          })
          .catch((err) => {
            console.error(err)
          })
      })
    }
    if (card.evidenceVideos) {
      card.evidenceVideos.forEach((file) => {
        const storageRef = ref(storage, file)
        getDownloadURL(storageRef)
          .then((url) => {
            setEvidenceVideoMedia((prevMedia) => [...prevMedia, url])
          })
          .catch((err) => {
            console.error(err)
          })
      })
    }
  }, [card?.files, card?.videos, card?.evidenceFiles, card?.evidenceVideos])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  if (!type) return null

  // return content.
  return (
    <Container tabIndex={0} onClick={onClick}>
      {array.length > 0 &&
        array
          .map((shadowEl, i) => (
            <ShadowWrapper
              key={i + 1}
              zEl={i + 1}
              length={array.length <= 4 ? array.length : 4}
              className={className}
              theme={getCardInfo[type]?.theme}
            />
          ))
          .slice(0, 4)}

      <Wrapper
        zEl={length}
        className={className}
        theme={getCardInfo[type]?.theme}
        isTablet={isTablet}
        isMinimal={isMinimal}
      >
        <HeadingWrapper isMinimal={isMinimal}>
          <StyledBodyWrapper>
            <StyledType size="smallest" color="white">
              {getCardInfo[type]?.title}
            </StyledType>
            {isMinimal ? (
              <StyledCountWrapper isTablet={isTablet}>
                <StyledCaption type="caption" align="right">
                  {isTablet ? 'Cantidad' : 'Cantidad de tarjetas'}
                </StyledCaption>
                <StyledBody color="white" align="right">
                  {length}
                </StyledBody>
              </StyledCountWrapper>
            ) : (
              <StyledCaption type="caption" align="right">
                N° aviso de tarjeta
              </StyledCaption>
            )}
          </StyledBodyWrapper>
          {!isMinimal && (
            <StyledBody color="white" align="right">
              {card?.ref?.id}
            </StyledBody>
          )}
        </HeadingWrapper>

        {!isMinimal && (
          <ContentWrapper>
            <ContentColumn>
              <AuthorWrapper>
                <StyledCaption type="caption" align="center">
                  Autor:
                </StyledCaption>
                <Body align="center" size="smallest" color="white">
                  {card.createdBy
                    ? getNameFull(card.createdBy?.firstname, card.createdBy?.lastname)
                    : null}{' '}
                </Body>
              </AuthorWrapper>
              {card.responsible && (
                <AuthorWrapper>
                  <StyledCaption type="caption" align="center">
                    Responsable:
                  </StyledCaption>
                  <Body align="center" size="smallest" color="white">
                    {card.responsible
                      ? getNameFull(card.responsible?.firstname, card.responsible?.lastname)
                      : null}
                  </Body>
                </AuthorWrapper>
              )}
              {card.closingPerson && (
                <AuthorWrapper>
                  <StyledCaption type="caption" align="center">
                    Cerrada Por:
                  </StyledCaption>
                  <Body align="center" size="smallest" color="white">
                    {card.closingPerson && card.closingPerson.uid
                      ? getNameFull(card.closingPerson?.firstname, card.closingPerson?.lastname)
                      : card.closingPerson}
                  </Body>
                </AuthorWrapper>
              )}
            </ContentColumn>

            <ContentColumn pushRight>
              <DateWrapper>
                <Body align="center" type="caption" color="white">
                  {dateFormatObject(card.createdDate).month} {dateFormatObject(card.createdDate).yy}
                </Body>
                <DateBoxContent>
                  <Body align="center" size="larger">
                    {dateFormatObject(card.createdDate).day}
                  </Body>
                </DateBoxContent>
              </DateWrapper>
            </ContentColumn>
          </ContentWrapper>
        )}

        {!isMinimal && card.location && (
          <ContentColumn>
            <AuthorWrapper>
              <StyledCaption type="caption" align="center">
                Ubicación:
              </StyledCaption>{' '}
              <Body align="left" size="smallest" color="white">
                {card.location}

                {card.parentsArr &&
                  card.parentsArr.map((location, index) => (
                    <span key={location}>
                      {index > 0 ? ' > ' : ' ('}
                      {location}
                      {index === card.parentsArr.length - 1 ? ')' : ''}
                    </span>
                  ))}
              </Body>
            </AuthorWrapper>
          </ContentColumn>
        )}

        {!isMinimal && card.description && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Descripción anomalía</StyledCaption>
            <DescriptionContent>
              <Body size="smallest" color="white">
                {card.description}
              </Body>
            </DescriptionContent>
          </DescriptionWrapper>
        )}
        {!isMinimal && card.proposedImprovement && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Acción propuesta / mejora</StyledCaption>
            <DescriptionContent>
              <Body size="smallest" color="white">
                {card.proposedImprovement}
              </Body>
            </DescriptionContent>
          </DescriptionWrapper>
        )}

        {!isMinimal && card.anomalies && card.anomalies.length > 0 && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Tipo anomalía (codificación)</StyledCaption>
            <AnomaliesContent>
              {card.anomalies.map((anomaly) => (
                <Anomaly key={anomaly} type="caption" align="center" color="white">
                  <Icon name="warning" size={12} /> {anomaly}
                </Anomaly>
              ))}
            </AnomaliesContent>
          </DescriptionWrapper>
        )}
        {!isMinimal && ((media && media.length > 0) || (videoMedia && videoMedia.length > 0)) && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Media</StyledCaption>
            <FileModal media={media} videoMedia={videoMedia} />
          </DescriptionWrapper>
        )}

        {/* data card in progress */}
        {!isMinimal && card.priority && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Prioridad</StyledCaption>
            <DescriptionContent>
              <Body size="smallest" color="white">
                {priorityFormat(card.priority)}
              </Body>
            </DescriptionContent>
          </DescriptionWrapper>
        )}
        {!isMinimal && card.instalationStatus && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Estado instalación</StyledCaption>
            <DescriptionContent>
              <Body size="smallest" color="white">
                {instalationStatusFormat(card.instalationStatus)}
              </Body>
            </DescriptionContent>
          </DescriptionWrapper>
        )}

        {!isMinimal && card.executor && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Ejecutor</StyledCaption>
            <DescriptionContent>
              <Body size="smallest" color="white">
                {card.executor}
              </Body>
            </DescriptionContent>
          </DescriptionWrapper>
        )}
        {!isMinimal && card.extremeDatePlan && (
          <ContentWrapper>
            <ContentColumn>
              <DescriptionWrapper>
                <StyledCaption type="caption">Extrema (plan)</StyledCaption>
                <DateWrapper>
                  <Body align="center" type="caption" color="white">
                    {dateFormatObject(card.extremeDatePlan).month}{' '}
                    {dateFormatObject(card.extremeDatePlan).yy}
                  </Body>
                  <DateBoxContent>
                    <Body align="center" size="larger">
                      {dateFormatObject(card.extremeDatePlan).day}
                    </Body>
                  </DateBoxContent>
                </DateWrapper>
              </DescriptionWrapper>
            </ContentColumn>
            {!isMinimal && card.extremeDateReal && (
              <ContentColumn>
                <DescriptionWrapper>
                  <StyledCaption type="caption">Extrema (real)</StyledCaption>
                  <DateWrapper>
                    <Body align="center" type="caption" color="white">
                      {dateFormatObject(card.extremeDateReal).month}{' '}
                      {dateFormatObject(card.extremeDateReal).yy}
                    </Body>
                    <DateBoxContent>
                      <Body align="center" size="larger">
                        {dateFormatObject(card.extremeDateReal).day}
                      </Body>
                    </DateBoxContent>
                  </DateWrapper>
                </DescriptionWrapper>
              </ContentColumn>
            )}

            {!isMinimal && card.validationDate && (
              <ContentColumn>
                <DescriptionWrapper>
                  <StyledCaption type="caption">Fecha Validación</StyledCaption>
                  <DateWrapper>
                    <Body align="center" type="caption" color="white">
                      {dateFormatObject(card.validationDate).month}{' '}
                      {dateFormatObject(card.validationDate).yy}
                    </Body>
                    <DateBoxContent>
                      <Body align="center" size="larger">
                        {dateFormatObject(card.validationDate).day}
                      </Body>
                    </DateBoxContent>
                  </DateWrapper>
                </DescriptionWrapper>
              </ContentColumn>
            )}
          </ContentWrapper>
        )}

        {!isMinimal && card.otNumber && (
          <DescriptionWrapper>
            <StyledCaption type="caption">N° OT</StyledCaption>
            <DescriptionContent>
              <Body size="smallest" color="white">
                {card.otNumber}
              </Body>
            </DescriptionContent>
          </DescriptionWrapper>
        )}

        {!isMinimal && card.improvementMade && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Acción realizada</StyledCaption>
            <DescriptionContent>
              <Body size="smallest" color="white">
                {card.improvementMade}
              </Body>
            </DescriptionContent>
          </DescriptionWrapper>
        )}

        {!isMinimal && card.comments && (
          <DescriptionWrapper>
            <StyledCaption type="caption">Comentarios</StyledCaption>
            <DescriptionContent>
              <Body size="smallest" color="white">
                {card.comments}
              </Body>
            </DescriptionContent>
          </DescriptionWrapper>
        )}

        {!isMinimal &&
          ((evidenceMedia && evidenceMedia.length > 0) ||
            (evidenceVideoMedia && evidenceVideoMedia.length > 0)) && (
            <DescriptionWrapper>
              <StyledCaption type="caption">Evidencia</StyledCaption>
              <FileModal media={evidenceMedia} videoMedia={evidenceVideoMedia} />
            </DescriptionWrapper>
          )}

        {!isMinimal && children}

        {!isMinimal &&
          (userData.access === 'superadmin' ||
            userData.uid === card.responsible?.uid ||
            userData.access === 'operator') &&
          actionLabel && (
            <FooterWrapper isTablet={isTablet}>
              <Button background="asphalt" onClick={onActionClick}>
                <Icon name="edit" size={16} color="white" /> {actionLabel}
              </Button>
            </FooterWrapper>
          )}
      </Wrapper>
    </Container>
  )
}

export default ReportCard
