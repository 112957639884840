// dependencies.
import styleguideHasKey from './styleguideHasKey'
// utils.
import { theme } from '../styleguide'

// main function.
const setBackgroundColor = (access, opacity = false) => {
  // set admin panel backgrounds.
  const themeColor = () => {
    if (access === 'company') return opacity ? 'company95' : 'company'
    else if (access === 'developer') return opacity ? 'developer95' : 'developer'
    else return opacity ? 'admin95' : 'admin'
  }

  // make sure color is included in the library.
  const backgroundColor = styleguideHasKey(themeColor(), theme.admin, 'white')

  return theme.admin[backgroundColor]
}

export default setBackgroundColor
