// constants.
import data from './firebaseErrorList.json'

// main function.
const firebaseErrorMessage = (response) => {
  if (!response) return

  const res = data.find(({ firebaseKey, string }) => firebaseKey === response && string)

  return res.string
}

export default firebaseErrorMessage
