// dependencies.
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
// components.
import AuthTemplate from '../../templates/AuthScreen'
// utils.
import notifyToast from '../../js/notifyToast'
// constants.
import { ACTIVATE_FORM } from './constants'
import { activateUser } from '../../js/firebase/firebase'
import { Loader } from '../../components/Icon'
import { useAppDispatch } from '../../js/store/hooks'
import { signIn } from '../../js/auth/authActions'

/******************************/
/*                            */
/*    Activate User Screen    */
/*                            */
/******************************/

const ActivateScreen = () => {
  const [loaded, setLoaded] = useState(false)
  const dispatch = useAppDispatch()

  // hooks.
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  // states.
  const [values, setValues] = useState({})

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update value change.
  const handleValueChange = (value) => {
    setValues(value)
  }

  // handle update activate value for user.
  const handleActivateClick = async () => {
    setLoaded(true)
    if (!values.password) {
      setLoaded(false)
      return notifyToast('Debe ingresar una contraseña', 'error')
    }

    if (values.password !== values.password2) {
      setLoaded(false)
      return notifyToast('Las contraseñas no coinciden', 'error')
    }
    const password = values.password
    values.document = searchParams.get('document')
    values.firstAccessCode = searchParams.get('code')
    if (searchParams.get('document')) {
      values.email = `${searchParams.get('document')}@ccu.uy`
    } else {
      values.email = searchParams.get('email')
    }
    try {
      const userDoc = await activateUser(values)
      // Si la activación se completa con éxito, intenta iniciar sesión con las credenciales proporcionadas
      if (userDoc) {
        const email = userDoc.data().email
        dispatch(signIn({ email, password }))
        setLoaded(false)
      } else {
        setLoaded(false)
        return notifyToast('El usuario ya fue activado o venció el tiempo para activarse', 'error')
      }
    } catch (error) {
      console.error('Error al activar el usuario:', error)
      setLoaded(false)
      // Manejo del error aquí
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // USER SESSION                                                                                //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    // handle not signed up users.
    if (!searchParams.get('email') && !searchParams.get('document')) navigate('/signin')
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AuthTemplate
      disbledSubmit={loaded}
      description="Para activar su cuenta, ingrese una contraseña"
      form={ACTIVATE_FORM}
      submitLabel="Activar cuenta"
      topbarTitle="Activar cuenta"
      onChange={(value) => handleValueChange(value)}
      onSubmit={() => handleActivateClick()}
    >
      {loaded && <Loader />}
    </AuthTemplate>
  )
}

// TODO: create an auth screens tempate.

export default ActivateScreen
