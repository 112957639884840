// dependencies.
import { RotatingLines } from 'react-loader-spinner'
import styled from 'styled-components'

/*******************************/
/*                             */
/*    Loader Icon Component    */
/*                             */
/*******************************/

// partials.
const Wrapper = styled.div({
  width: 'fit-content',
  margin: '0 auto',
})

// main component.
const Loader = ({ className }) => {
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper className={className}>
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="56"
        visible="true"
      />
    </Wrapper>
  )
}

export default Loader
