// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// components.
import AuthTemplate from '../../templates/AuthScreen'
import { Headline, Body } from '../../components/Typography'
// utils.
import notifyToast from '../../js/notifyToast'
// constants.
import { SIGNUP_FORM } from './constants'
import { SIGN_UP_ENABLED } from '../../constants'
import { validateCi } from '../../js/validateInput'
import { signUpEmailPassword } from '../../js/firebase/firebase'
import { Loader } from '../../components/Icon'

/************************/
/*                      */
/*    Sign Up Screen    */
/*                      */
/************************/

// partials.
const StyledDisclaimer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: 0,
  justifyContent: 'center',
  margin: '24px auto',
  maxWidth: 360,
})

const StyledCode = styled(Headline)({
  letterSpacing: 10,
  margin: '8px auto',
  textAlign: 'center',
  textTransform: 'uppercase',
})

// main components.
const SignupScreen = () => {
  const [loaded, setLoaded] = useState(false)

  // hooks.
  const navigate = useNavigate()
  // states.
  const [values, setValues] = useState({})
  const [firstAccessCode, setFirstAccessCode] = useState('')

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update value change.
  const handleValueChange = (value) => {
    setValues(value)
  }

  // navigate to sign-in page.
  const handleSigninClick = () => {
    navigate('/signin')
  }

  // handle sign un with email, password, and user data.
  const handleRegisterClick = async () => {
    const { firstname, lastname, document } = values
    values.firstAccessCode = firstAccessCode
    if (!firstname) return notifyToast('Por favor ingrese su Nombre', 'error')
    if (!lastname) return notifyToast('Por favor ingrese su Apellido', 'error')
    if (!document) return notifyToast('Por favor ingrese su documento', 'error')
    if (!validateCi(document)) return notifyToast('El documento ingresado no es válido', 'error')

    try {
      setLoaded(true)
      const success = await signUpEmailPassword(values)
      if (success) {
        setLoaded(false)
        notifyToast('Registro exitoso, solicite al administrador que active su usuario', 'success')
        setTimeout(() => navigate('/signin'), 3000)
      } else {
        setLoaded(false)
        notifyToast('Error al registrar usuario', 'error')
      }
    } catch (error) {
      setLoaded(false)
      notifyToast(error.message, 'error')
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // USER SESSION                                                                                //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    // handle not signed up users.
    if (!SIGN_UP_ENABLED) navigate('/signin')
    setFirstAccessCode(Math.floor(100000 + Math.random() * 900000))
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AuthTemplate
      disbledSubmit={loaded}
      description="Crear nueva cuenta"
      form={SIGNUP_FORM(firstAccessCode)}
      submitLabel="Crear cuenta"
      topbarIcon="caret-left"
      topbarTitle="Registro"
      onChange={(value) => handleValueChange(value)}
      onSubmit={() => handleRegisterClick()}
      onTopbarAction={() => handleSigninClick()}
    >
      <StyledDisclaimer>
        <Body color="alert" align="center">
          Guarde su código de acceso:
        </Body>
        <StyledCode size="larger" color="alert">
          {firstAccessCode}
        </StyledCode>
      </StyledDisclaimer>
      {loaded && <Loader />}
    </AuthTemplate>
  )
}

// TODO: create an auth screens tempate.

export default SignupScreen
