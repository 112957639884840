export const DEFAULT_FORM = [
  {
    iconL: 'user',
    name: 'document',
    placeholder: 'Documento',
    required: true,
  },
  {
    iconL: 'edit',
    name: 'password',
    type: 'password',
    placeholder: 'Contraseña',
    required: true,
  },
]

export const RESET_FORM = [
  {
    iconL: 'user',
    name: 'document',
    placeholder: 'documento',
    required: true,
  },
]

export const SIGNUP_FORM = () => [
  {
    iconL: 'edit',
    name: 'firstname',
    placeholder: 'Nombre',
    required: true,
  },
  {
    iconL: 'edit',
    name: 'lastname',
    placeholder: 'Apellido',
    required: true,
  },
  {
    iconL: 'user',
    name: 'document',
    placeholder: 'Documento',
    required: true,
  },
  {
    iconL: 'title',
    name: 'email',
    placeholder: 'E-mail',
    required: false,
  },
]

export const ACTIVATE_FORM = [
  {
    iconL: 'edit',
    name: 'password',
    placeholder: 'Contraseña',
    type: 'password',
    required: true,
  },
  {
    iconL: 'edit',
    name: 'password2',
    placeholder: 'Repita la contraseña',
    type: 'password',
    required: true,
  },
]
