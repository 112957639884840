// dependencies.
import { useEffect, useState } from 'react'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
// import MobileTable from '../../components/Table/MobileTable'
// import Table from '../../components/Table'
// import UserCard from '../../components/Card/UserCard'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Button } from '../../components/Button'
import Header from '../../components/Layout/Header'
import Search from '../../components/Filters/Search'
// utils.
import notifyToast from '../../js/notifyToast'
import {
  createUser,
  db,
  getUserByDocument,
  getUserByEmail,
  sendSignInEmailLink,
} from '../../js/firebase/firebase'
import { Loader } from '../../components/Icon'
import { useDimensions } from '../../js/hooks'
// constants.
import { EDIT_USER_FORM, FORGOT_OPERATOR_PASSWORD_FORM, NEW_USER_FORM } from './constants'
import FloatingButton from '../../components/Button/MobileButton'
import { useAppDispatch, useAppSelector } from '../../js/store/hooks'
import { selectFilteredUsers, selectUsersStatus } from '../../js/users/usersSelectors'
import { fetchUsers, setFilters, userAdded, userUpdated } from '../../js/users/usersSlice'
import UsersMobileSort from '../../components/screens/Users/MobileItems/Sort'
import UsersMobileItem from '../../components/screens/Users/MobileItems/Item'
import UsersDesktopItem from '../../components/screens/Users/DesktopItems/Item'
import UsersDesktopWrapper from '../../components/screens/Users/DesktopItems/Wrapper'
import UsersDesktopSort from '../../components/screens/Users/DesktopItems/Sort'
import UsersMobileWrapper from '../../components/screens/Users/MobileItems/Wrapper'
import { sendReset } from '../../js/auth/authActions'
import firebaseErrorMessage from '../../js/firebase/firebaseErrorTranslator'

/******************************/
/*                            */
/*    Users Screen    */
/*                            */
/******************************/

// main component.
const UsersScreen = ({ navMenu, navTitle }) => {
  // hooks.
  const dispatch = useAppDispatch()
  const { isMobile } = useDimensions()
  // states.
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const loaded = useAppSelector(selectUsersStatus)
  const filteredUsersSelector = selectFilteredUsers()
  const filteredUsers = useAppSelector(filteredUsersSelector)
  // const [sortingOpen, setSortingOpen] = useState(false)
  useEffect(() => {
    if (loaded === 'idle') {
      dispatch(fetchUsers())
    }
  }, [dispatch, loaded])

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new user modal.
  const handleNewUserModal = () => {
    const firstAccessCode = Math.floor(100000 + Math.random() * 900000)
    const obj = {
      firstAccessCode: firstAccessCode,
    }
    setModalContent({
      title: 'Agregar nuevo usuario',
      component: (
        <Form
          items={NEW_USER_FORM}
          defaultValues={obj}
          onClick={(obj) => handleCreateUserClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected user modal.
  const handleEditUserModal = (obj) => {
    const user = { ...obj }
    if (obj.email === `${obj.document}@ccu.uy`) {
      delete user.email
    }
    setModalContent({
      title: 'Actualizar usuario',
      component: (
        <Form
          items={EDIT_USER_FORM}
          defaultValues={user}
          onClick={(user) => handleSaveUserClick(user)}
        />
      ),
    })

    setModalOpen(true)
  }

  const handleForgotPasswordModal = (obj) => {
    if (obj.email === `${obj.document}@ccu.uy`) {
      setModalContent({
        title: '¿Estás seguro?',
        component: (
          <Form
            submitLabel="Confirmar"
            items={FORGOT_OPERATOR_PASSWORD_FORM}
            defaultValues={obj}
            onClick={(obj) => handleForgotPassword(obj)}
          />
        ),
      })
      setModalOpen(true)
    } else {
      handleForgotPassword(obj)
    }
  }

  const handleForgotPassword = async (obj) => {
    if (obj.email === `${obj.document}@ccu.uy`) {
      // enable first access code
      const firstAccessCode = Math.floor(100000 + Math.random() * 900000)
      const userRef = doc(db, 'users', obj.uid)
      await updateDoc(userRef, { firstAccessCode, pendingActivation: true })
        .then(async () => {
          const updatedUserDoc = await getDoc(userRef)
          const updatedUser = { id: updatedUserDoc.id, ...updatedUserDoc.data() }
          dispatch(userUpdated(updatedUser))
          notifyToast(
            `Se le habilitó el código de acceso ${firstAccessCode} para iniciar sesión`,
            'success'
          )
          setModalOpen(false)
        })
        .catch(() => notifyToast('Ha ocurrido un error', 'error'))
    } else {
      // send email with reset password link
      sendReset(obj.email).then((response) => {
        if (response === 'sent') {
          notifyToast(
            'Se le envió un email con instrucciones para cambiar su contraseña',
            'success'
          )
        } else {
          notifyToast(firebaseErrorMessage(response), 'error')
        }
      })
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new user.
  const handleCreateUserClick = async (obj) => {
    const alreadyExists = await getUserByDocument(obj.document, false)
    if (alreadyExists) {
      notifyToast('Ya existe un usuario con la cédula ingresada', 'warning')
      return
    }
    if (obj.access === 'superadmin' && (!obj.email || obj.email === '')) {
      notifyToast('Debes ingresar un correo para el administrador', 'warning')
      return
    }
    await createUser(obj)
      .then(async (userRef) => {
        const newUserDoc = await getDoc(userRef)
        const newUser = { id: newUserDoc.id, ...newUserDoc.data() }
        dispatch(userAdded(newUser))
        notifyToast('Usuario creado correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // handle user information update.
  const handleSaveUserClick = async (obj) => {
    const userRef = doc(db, 'users', obj.uid)
    const { firstname, lastname, isActive, access } = obj
    const userData = await getDoc(userRef)
    const changeAccessToAdmin = obj.access !== userData.data().access && obj.access === 'superadmin'
    // const changeAccessToOperator = obj.access !== userData.data().access && obj.access === 'operator'
    if (!changeAccessToAdmin && obj.email && obj.email !== userData.data().email) {
      notifyToast('No puedes cambiar el correo de un usuario', 'warning')
      return
    }

    if (changeAccessToAdmin) {
      if (!obj.email || obj.email === '') {
        notifyToast('Debes ingresar un correo para el administrador', 'warning')
        return
      }

      const alreadyExists = await getUserByEmail(obj.email)
      if (alreadyExists) {
        notifyToast('Ya existe un usuario con el email ingresado', 'warning')
        return
      }
    }

    const updateFields = {
      firstname,
      lastname,
      isActive,
      pendingActivation: changeAccessToAdmin ? true : userData.data().pendingActivation || false,
      access,
      email: changeAccessToAdmin ? obj.email : userData.data().email,
    }
    // const operatorDoc = await getOperatorById(obj.ref.id)
    await updateDoc(userRef, updateFields)
      .then(async () => {
        const updatedUserDoc = await getDoc(userRef)
        const updatedUser = { id: updatedUserDoc.id, ...updatedUserDoc.data() }
        dispatch(userUpdated(updatedUser))

        if (changeAccessToAdmin) {
          sendSignInEmailLink(obj.uid, obj.email)
          notifyToast(
            'Se le envió un email con instrucciones para iniciar sesión como administrador',
            'success'
          )
        } else {
          notifyToast('Usuario actualizado correctamente', 'success')
        }
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <Header justifyContent="space-between">
          <Search
            placeholder="Buscar por nombre..."
            onChange={(term) => {
              dispatch(setFilters({ searchTerm: term }))
            }}
          />
          {isMobile ? (
            <>
              <FloatingButton onClick={() => handleNewUserModal()} />
              {/* <ToggleSortingButton onChange={(isOpen) => setSortingOpen(isOpen)} /> */}
            </>
          ) : (
            <Button onClick={() => handleNewUserModal()}>Agregar Usuario</Button>
          )}
        </Header>

        {loaded === 'succeeded' ? (
          isMobile ? (
            <UsersMobileWrapper isEmpty={!filteredUsers && !filteredUsers.length}>
              <UsersMobileSort />

              {filteredUsers.map((user, i) => (
                <UsersMobileItem
                  key={user.id}
                  item={user}
                  isEven={i % 2 === 0}
                  onEdit={() => handleEditUserModal(user)}
                  onForgotPassword={() => handleForgotPasswordModal(user)}
                />
              ))}
            </UsersMobileWrapper>
          ) : (
            <UsersDesktopWrapper isEmpty={!!filteredUsers && !filteredUsers.length}>
              <UsersDesktopSort
                items={filteredUsers}
                // isOpen={sortingOpen}
                onSort={(e) => dispatch(setFilters({ sort: e }))}
              />

              {filteredUsers.map((user, i) => (
                <UsersDesktopItem
                  key={user.id}
                  item={user}
                  isEven={i % 2 === 0}
                  onEdit={() => handleEditUserModal(user)}
                  onForgotPassword={() => handleForgotPasswordModal(user)}
                />
              ))}
            </UsersDesktopWrapper>
          )
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default UsersScreen
