// dependencies.
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getDocs } from 'firebase/firestore'
// constants.
import { IS_DEV } from '../../constants'

export function createFetchThunk(name, query, access) {
  return createAsyncThunk(`${name}/fetch`, async (_, thunkAPI) => {
    const userData = thunkAPI.getState().user.userData
    try {
      const querySnapshot = await getDocs(query)
      const items = []
      IS_DEV && console.error(`Fetch ${name}`)
      querySnapshot.forEach((doc) => {
        if (access.includes(userData.access)) {
          items.push({ id: doc.id, ...doc.data() })
        }
      })
      return items
    } catch (error) {
      IS_DEV && console.error(`Error fetching ${name}:`, error)
      throw error
    }
  })
}
