// dependencies.
import { useState } from 'react'
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import { theme } from '../../../../styleguide'
// constants.
import { CELL_WIDTH, COLUMN_LAYOUT } from './Item'
import { TABLE_HEADER_HEIGHT } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  borderBottom: `2px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: TABLE_HEADER_HEIGHT,
  maxWidth: '100%',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 8,
})

const Cell = styled.div({
  alignItems: 'center',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  cursor: (props) => (props.isSortable ? 'pointer' : 'default'),
  display: 'flex',
  flexDirection: 'row',
  gap: baseUnit / 2,
  height: '100%',
  justifyContent: 'center',
  minWidth: (props) => `${props.minWidth}px`,
  padding: `${baseUnit / 2}px ${baseUnit / 4}px`,
  width: (props) => `${props.width}%`,
  zIndex: 0,
  ...(props) =>
    props.isSortable && {
      '&:focus': {
        backgroundColor: theme.colors.smoke,
        filter: `drop-shadow(${theme.shadows.focus})`,
        outline: 'none',
        zIndex: 1,
      },
    },
})

const StyledSortLabel = styled(Body)({
  userSelect: 'none',
})

// main component.
const ReportsDesktopSort = ({ style, onSort }) => {
  const [sortingBy, setSortingBy] = useState({ label: '', key: 0 })

  const sortingDirectionIcon = [null, 'caret-down', 'caret-up']

  // update sorting toggles and sorted items lists.
  const handleSort = (sortLabel, sortDbKey) => {
    const index =
      sortingBy.label === sortLabel && sortingBy.direction === 'caret-down'
        ? 2
        : sortingBy.direction === 'caret-up'
          ? 0
          : 1
    const direction = sortingDirectionIcon[index]
    const sortingObj = { label: sortLabel, key: sortDbKey, direction }
    setSortingBy(sortingObj)
    onSort(sortingObj)
  }
  // return content.
  return (
    <Wrapper style={style}>
      {COLUMN_LAYOUT.map(({ label, dbKey, columns, minWidth }) => {
        const maybeSortable = !!dbKey
        const isSorting = maybeSortable && sortingBy.direction && label === sortingBy.label

        return (
          <Cell
            key={label}
            isSortable={maybeSortable}
            minWidth={minWidth}
            width={CELL_WIDTH * columns}
            tabIndex={maybeSortable ? 0 : undefined}
            onClick={() => maybeSortable && handleSort(label, dbKey)}
          >
            <StyledSortLabel type="caption" color={isSorting ? 'black' : 'grey'} nowrap>
              {label}
            </StyledSortLabel>

            {isSorting && <Icon name={sortingBy.direction} size={12} color="black" />}
          </Cell>
        )
      })}
    </Wrapper>
  )
}

export default ReportsDesktopSort
