// dependencies.
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
// components.
import { Body, Label, Pill } from '../Typography'
import { Icon } from '../Icon'
import { Group, Item } from '../Button'
// utils.
import { useFocus } from '../../js/hooks'
import { theme, constants } from '../../styleguide'

/***************************/
/*                         */
/*    Table Item Module    */
/*                         */
/***************************/

// helpers.
const { TABLE_CELL_MIN_WIDTH, TABLE_CELL_MID_WIDTH, TABLE_CELL_MAX_WIDTH } = constants

// partials.
const ContentItemWrapper = styled.li(
  {
    alignItems: 'stretch',
    backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
    borderBottom: (props) => !props.isLast && `1px solid ${theme.colors.ash}`,
    display: 'flex',
    flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
    minHeight: '64px',
    minWidth: '100%',
    padding: (props) => (props.isMobile ? '8px' : 0),
    transition: 'background 200ms ease',
    width: 'fit-content',
    '&:focus': {
      backgroundColor: theme.opacity.primary20,
      outline: 'none',
    },
  },
  `@media print {
    min-height: 40px;
    min-width: unset;
    padding: 0;
  }`
)

const ContentItem = styled(Body)(
  {
    alignItems: 'center',
    borderRight: (props) => !props.isLast && !props.isMobile && `1px solid ${theme.colors.ash}`,
    display: 'flex',
    flexGrow: (props) => (props.grow ? 'grow' : 0),
    gap: '8px',
    justifyContent: (props) => (props.isMobile ? 'flex-start' : 'center'),
    maxWidth: (props) =>
      props.isMobile
        ? '100%'
        : props.grow
          ? props.grow === 2
            ? '100%'
            : TABLE_CELL_MID_WIDTH
          : TABLE_CELL_MIN_WIDTH,
    minWidth: (props) =>
      props.isMobile
        ? '100%'
        : props.grow
          ? props.grow === 2
            ? TABLE_CELL_MAX_WIDTH
            : TABLE_CELL_MID_WIDTH
          : TABLE_CELL_MIN_WIDTH,
    padding: '2px 4px',
    width: (props) => (props.isMobile || props.grow ? '100%' : 'auto'),
  },
  `@media print {
    max-width: 108px;
    min-width: 108px;
    padding: 2px;

    &#action-cell {
      display: none;
    }
  }`
)

const IconElement = styled.span({
  display: 'block',
  paddingRight: '8px',
})

const ActionsElement = ({
  id,
  isMobile,
  //
  disableRequest,
  onRequest,
  //
  disableShowHistory,
  onShowHistory,
  //
  disableEdit,
  onEdit,
  //
  disableCancel,
  onCancel,
  //
  disableDelete,
  onDelete,
  //

  onShowQR,
  disableChangeResponsible,
  onChangeResponsible,
}) => {
  return (
    <ContentItem id={id} grow={2} isLast isMobile={isMobile}>
      {(onShowHistory || onRequest) && (
        <Group>
          {onRequest && (
            <Item icon="add" label="Agregar" disabled={disableRequest} onClick={onRequest} />
          )}
          {onShowHistory && (
            <Item label="Ver historial" disabled={disableShowHistory} onClick={onShowHistory} />
          )}
        </Group>
      )}

      {(onEdit || onCancel || onDelete || onShowQR || onChangeResponsible) && (
        <Group>
          {onEdit && (
            <Item
              icon="edit"
              // label="Editar"
              variant="primary"
              disabled={disableEdit}
              onClick={onEdit}
            />
          )}
          {onCancel && (
            <Item
              icon="delete"
              // label="Cancelar"
              variant="warning"
              disabled={disableCancel}
              onClick={onCancel}
            />
          )}
          {onDelete && (
            <Item
              icon="trash"
              // label="Eliminar"
              variant="alert"
              disabled={disableDelete}
              onClick={onDelete}
            />
          )}
          {onShowQR && <Item icon="" label="Mostrar QR" variant="warning" onClick={onShowQR} />}

          {onChangeResponsible && (
            <Item
              icon="edit"
              label="Responsable"
              variant="warning"
              onClick={onChangeResponsible}
              disabled={disableChangeResponsible}
            />
          )}
        </Group>
      )}
    </ContentItem>
  )
}

// main component.
const TableItem = ({
  items,
  //
  isMobile,
  isEven,
  isLast,
  //
  onDetail,
  //
  disableRequest,
  enableRequest,
  onRequest,
  //
  disableShowHistory,
  enableShowHistory,
  onShowHistory,
  //
  disableEdit,
  enableEdit,
  onEdit,
  //
  disableCancel,
  enableCancel,
  onCancel,
  //
  disableDelete,
  enableDelete,
  onDelete,
  //
  onFocus = () => {},
  //
  enableShowQR,
  onShowQR,
  //
  enableChangeResponsible,
  onChangeResponsible,
  disableChangeResponsible,
}) => {
  const itemRef = useRef(null)

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // add labels for mobile view.
  const hasMobileLabel = (label, value) => (isMobile ? `${label} ${value}` : value)

  // check show actions column.
  const actionsColumnShown =
    enableRequest ||
    enableShowHistory ||
    enableEdit ||
    enableCancel ||
    enableDelete ||
    enableChangeResponsible ||
    enableShowQR

  // handle focus events.
  // onFocus
  const { focused } = useFocus(itemRef)

  useEffect(() => {
    onFocus(focused)
  }, [focused])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show if there's items.
  if (!items) return null
  // return content.

  return (
    <ContentItemWrapper
      ref={itemRef}
      isMobile={isMobile}
      isEven={isEven}
      isLast={isLast}
      tabIndex={0}
    >
      {items.map((item, j) => {
        const text = item.label ? hasMobileLabel(item.label, item.copy) : item.copy

        return (
          <ContentItem
            key={j}
            align="center"
            type={item?.type}
            isMobile={isMobile}
            grow={item?.grow}
          >
            {item.icon && isMobile && (
              <IconElement>
                <Icon name={item.icon} size="16" />
              </IconElement>
            )}

            {(item.triggerDetail && onDetail) || item.href || item.to ? (
              <Label href={item.href} to={item.to} onClick={onDetail}>
                {text}
              </Label>
            ) : item?.type === 'pill' ? (
              <Pill key={j} type={item?.pillBackground} width="90px">
                {text}
              </Pill>
            ) : (
              text
            )}
          </ContentItem>
        )
      })}

      {actionsColumnShown && (
        <ActionsElement
          id="action-cell"
          isMobile={isMobile}
          //
          disableRequest={disableRequest}
          onRequest={enableRequest && onRequest}
          //
          disableShowHistory={disableShowHistory}
          onShowHistory={enableShowHistory && onShowHistory}
          //
          disableEdit={disableEdit}
          onEdit={enableEdit && onEdit}
          //
          disableCancel={disableCancel}
          onCancel={enableCancel && onCancel}
          //
          disableDelete={disableDelete}
          onDelete={enableDelete && onDelete}
          //
          onShowQR={enableShowQR && onShowQR}
          //
          disableChangeResponsible={disableChangeResponsible}
          onChangeResponsible={enableChangeResponsible && onChangeResponsible}
        />
      )}
    </ContentItemWrapper>
  )
}

export default TableItem
