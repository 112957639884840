// dependencies.
import styled from 'styled-components'
// components.
import { Body, Label, Pill } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import cardStatusFormat from '../../../../js/cardStatusFormat'
import dateFormat from '../../../../js/dateFormat'
import { getNameFull } from '../../../../js/usernameUtils'
import { theme } from '../../../../styleguide'
// constants.
import { DATE_TIME_FORMAT } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

const getCardInfo = {
  TM: theme.cards.tm,
  TO: theme.cards.to,
  TQ: theme.cards.tq,
  TS: theme.cards.ts,
}

// partials.
const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.opacity.ash30}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  flexDirection: 'column',
  gap: baseUnit / 2,
  minHeight: baseUnit * 4,
  padding: baseUnit / 2,
  width: '100%',
})

const Header = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.opacity.ash30}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: baseUnit / 2 - 1,
  width: '100%',
})

const Content = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
})

const ContentLeft = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit / 2 - 2,
  justifyContent: 'flex-start',
})

const ContentRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const Footer = styled.div({
  alignItems: 'center',
  border: `1px solid ${theme.opacity.ash50}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: 0,
  padding: '2px 2px 2px 8px',
  width: '100%',
})

const StyledType = styled(Body)({
  '&:before': {
    backgroundColor: (props) => props.background,
    borderRadius: baseUnit / 4,
    content: '""',
    display: 'inline-block',
    filter: `drop-shadow(${theme.shadows.smaller})`,
    height: baseUnit / 2,
    marginRight: baseUnit / 4,
    width: baseUnit / 2,
  },
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

const StyledEdit = styled(Label)({
  background: (props) => (props.disabled ? theme.colors.smoke : theme.colors.warning),
  border: (props) =>
    props.disabled ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.warning}`,
  borderRadius: baseUnit / 2 - 2,
  display: 'flex',
  fontSize: theme.fontSize.x12,
  gap: 4,
  padding: '3px 6px 3px 8px',
  width: 'fit-content',
})

// main component.
const CardsMobileItem = ({ item, onEdit }) => {
  // only show if there's items.
  if (!item) return null

  const { ref, type, status, createdDate, extremeDateReal, responsible } = item

  const enableEdit = Boolean(!item.extremeDateReal && item.status < 2 && onEdit)

  // return content.
  return (
    <Wrapper>
      <Header>
        <StyledType background={getCardInfo[type]} type="caption" nowrap>
          Tipo {type}
        </StyledType>
        <Body type="caption" nowrap>
          Nº de Tarjeta: {ref.id}
        </Body>
      </Header>

      <Content>
        <ContentLeft>
          <StyledBody size="smaller" nowrap>
            <Icon name="calendar" color="black" size={16} />
            <span>
              Solicitada: <b>{dateFormat(createdDate, DATE_TIME_FORMAT)}</b>
            </span>
          </StyledBody>
          <StyledBody size="smaller" color={!extremeDateReal && 'grey'} nowrap>
            <Icon name="calendar" color={extremeDateReal ? 'black' : 'grey'} size={16} />
            <span>
              Resuelta:{' '}
              <b>{extremeDateReal ? dateFormat(extremeDateReal, DATE_TIME_FORMAT) : ''}</b>
            </span>
          </StyledBody>
        </ContentLeft>

        <ContentRight>
          <Pill type={item?.status}>{cardStatusFormat(status)}</Pill>
        </ContentRight>
      </Content>

      <Footer>
        <Body size="smaller" nowrap>
          Responsable: {getNameFull(responsible?.firstname, responsible?.lastname)}
        </Body>

        <StyledEdit color="white" disabled={!enableEdit} onClick={enableEdit && onEdit}>
          Editar
          <Icon name="edit" size={16} color={!enableEdit ? 'ash' : 'white'} />
        </StyledEdit>
      </Footer>
    </Wrapper>
  )
}

export default CardsMobileItem
