// dependencies.
import styled from 'styled-components'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { icons, theme } from '../../styleguide'
// images.
import { Sprite } from './icon-sprite'

/************************/
/*                      */
/*    Icon Component    */
/*                      */
/************************/

// helpers.
const UNIT_SIZE = 32

// partials.
const Wrapper = styled.i({
  display: 'inline-block',
  height: (props) => `${props.size}px`,
  minHeight: (props) => `${props.size}px`,
  minWidth: (props) => `${props.size}px`,
  overflow: 'hidden',
  position: 'relative',
  width: (props) => `${props.size}px`,
})

const Img = styled(Sprite)({
  height: (props) => `${props.size * 5}px`,
  left: (props) => `${props.left}px`,
  objectFit: 'cover',
  position: 'absolute',
  top: (props) => `${props.top}px`,
  width: (props) => `${props.size * 14}px`,
  path: {
    fill: (props) => props.color,
  },
})

// main component.
const Icon = ({ size = UNIT_SIZE, color, name }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const iconName = styleguideHasKey(name, icons, 'na')
  const iconColor = styleguideHasKey(color, colors, 'black')

  // move sprite to show desired icon.
  const imgPosition = (coords) => {
    const y = coords[0] // index 0 of array
    const x = coords[1] // index 1 of array
    return { left: size * -x, top: size * -y }
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper size={size}>
      <Img size={size} color={colors[iconColor]} {...imgPosition(icons[iconName])} />
    </Wrapper>
  )
}

export default Icon
