// dependencies.
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import { theme } from '../../../../styleguide'
// constants.
import { COLUMN_LAYOUT } from '../DesktopItems/Item'
import { HEADER_HEIGHT_MOBILE } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.opacity.ash80}`,
  borderRadius: baseUnit,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.normal})`,
  flexDirection: 'column',
  height: 'fit-content',
  minWidth: '50%',
  padding: `${baseUnit / 2}px ${baseUnit}px`,
  position: 'fixed',
  right: (props) => (props.isOpen ? `${baseUnit / 2}px` : `${baseUnit * -3}px`),
  top: (props) => (props.isOpen ? `${HEADER_HEIGHT_MOBILE - baseUnit / 2}px` : 0),
  transition: 'all 200ms ease',
  width: 'fit-content',
  zIndex: 8,
})

const Cell = styled.div({
  alignItems: 'center',
  cursor: (props) => (props.isSortable ? 'pointer' : 'default'),
  display: 'flex',
  flexDirection: 'row',
  gap: baseUnit / 2,
  height: baseUnit * 2.5,
  justifyContent: 'center',
  padding: `${baseUnit / 2}px ${baseUnit}px`,
  width: '100%',
  zIndex: 0,
  ...(props) =>
    props.isSortable && {
      '&:focus': {
        backgroundColor: theme.colors.smoke,
        filter: `drop-shadow(${theme.shadows.focus})`,
        outline: 'none',
        zIndex: 1,
      },
    },
})

const StyledSortLabel = styled(Body)({
  userSelect: 'none',
})

// main component.
const UsersMobileSort = ({ items = [], responsibles = [], isOpen, onSort }) => {
  const componentRef = useRef(null)
  const [menuOpen, setMenuOpen] = useState(isOpen)

  const [sortingBy, setSortingBy] = useState({ label: '', key: 0 })

  const cellWidth = 5 // 5% (100% / 20 columns)
  const sortingDirectionIcon = [null, 'caret-down', 'caret-up']

  // update sorting toggles and sorted items lists.
  const handleSort = (sortLabel, sortDbKey) => {
    const index =
      sortingBy.label === sortLabel && sortingBy.direction === 'caret-down'
        ? 2
        : sortingBy.direction === 'caret-up'
          ? 0
          : 1
    const direction = sortingDirectionIcon[index]
    const sortingObj = { label: sortLabel, key: sortDbKey, direction }
    setSortingBy(sortingObj)
    onSort(sortingObj)
  }

  useEffect(() => {
    setMenuOpen(isOpen)
  }, [isOpen])

  // return content.
  return (
    menuOpen && (
      <Wrapper ref={componentRef} isOpen={menuOpen}>
        {COLUMN_LAYOUT.map(({ label, dbKey, columns }) => {
          const maybeSortable = !!dbKey
          const isSorting = maybeSortable && sortingBy.direction && label === sortingBy.label

          return (
            maybeSortable && (
              <Cell
                key={label}
                isSortable={maybeSortable}
                width={cellWidth * columns}
                tabIndex={maybeSortable ? 0 : undefined}
                onClick={() => maybeSortable && handleSort(label, dbKey)}
              >
                <StyledSortLabel type="caption" color={isSorting ? 'black' : 'grey'} nowrap>
                  {label}
                </StyledSortLabel>

                {isSorting && <Icon name={sortingBy.direction} size={12} color="black" />}
              </Cell>
            )
          )
        })}
      </Wrapper>
    )
  )
}

export default UsersMobileSort
