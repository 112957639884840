import { createSelector } from 'reselect'
import { searchBudgetsByType } from '../../components/screens/Budgets/SearchBudgetsByType'
import moment from 'moment'
import { sortingBudgets } from '../budgetSorting'

export const selectBudgetsData = (state) => state.budgets.data
export const selectSearchTerm = (state) => state.budgets.filters.searchTerm
export const selectSort = (state) => state.budgets.filters.sort

export const makeSelectBudgetsByDate = (fromDate, toDate) =>
  createSelector([selectBudgetsData], (budgetsSet) => {
    if (!fromDate || !toDate)
      return {
        budgetsSet,
      }
    return budgetsSet.filter((budgetObj) =>
      moment(new Date(budgetObj.startDate)).isBetween(
        moment(new Date(fromDate)),
        moment(new Date(toDate))
      )
    )
  })

export const selectFilteredBudgets = () =>
  createSelector([selectBudgetsData, selectSearchTerm, selectSort], (budgets, searchTerm, sort) => {
    // Aplica los filtros uno por uno
    let filteredBudgets = budgets
    if (searchTerm) {
      filteredBudgets = searchBudgetsByType(filteredBudgets, searchTerm)
    }
    if (sort) {
      filteredBudgets = sortingBudgets(filteredBudgets, sort.direction, sort.key)
    }
    return filteredBudgets
  })

// Select the status of loading the budgets.
export const selectBudgetsStatus = (state) => state.budgets.status
