// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import { Input } from '../Form'
// utils.
import { useDimensions } from '../../js/hooks'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: (props) => (props.isTablet ? '8px' : '16px'),
  width: (props) => (props.column ? '100%' : 'fit-content'),
})

const mapValueToIndex = (value) => {
  switch (value) {
    case 'Pendientes':
      return 0
    case 'Asignadas':
      return 1
    case 'Realizadas':
      return 2
    case 'Canceladas':
      return 3
    default:
      return null // Valor por defecto si no se encuentra
  }
}

// main component.
const FilterStatus = ({ label, onStatusFilter = () => {}, placeholder = 'Estado' }) => {
  // contexts.
  // hooks.
  const { isMobile, isTablet } = useDimensions()

  const statusOptions = ['Pendientes', 'Asignadas', 'Realizadas', 'Canceladas']

  const optionLabels = statusOptions.map((label, i) => ({ value: i, label }))

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // return content.

  return (
    <Wrapper column={!!label} isTablet={isTablet}>
      {label && (
        <Body type="caption" color="grey">
          {label}
        </Body>
      )}
      <Input
        isMobile={isMobile}
        options={optionLabels}
        name="status"
        iconL="bookmark"
        placeholder={placeholder}
        onChange={(e) => onStatusFilter(mapValueToIndex(e))}
      />
    </Wrapper>
  )
}

export default FilterStatus
