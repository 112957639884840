export const processCardsData = (users, locations, anomalies, cardsSet) => {
  if (!cardsSet || cardsSet.length === 0) return null
  // Mapea y procesa cada tarjeta en el conjunto de tarjetas
  const processedCards = cardsSet
    .map((card) => {
      const cardObj = { ...card } // Crea una copia superficial del objeto

      if (cardObj.createdByRef) {
        const operator = users.find((p) => p.uid === cardObj.createdByRef.id)
        if (operator) {
          const { firstname, lastname } = operator
          cardObj.createdBy = { firstname, lastname }
        }
      }
      if (cardObj.responsibleRef) {
        const responsible = users.find((p) => p.uid === cardObj.responsibleRef.id)
        if (responsible) {
          const { firstname, lastname, uid } = responsible
          cardObj.responsible = { firstname, lastname, uid }
        }
      }
      if (cardObj.closingPersonRef) {
        const closingPerson = users.find((p) => p.uid === cardObj.closingPersonRef.id)
        if (closingPerson) {
          const { firstname, lastname, uid } = closingPerson
          cardObj.closingPerson = { firstname, lastname, uid }
        }
      }
      if (cardObj.locationRef && locations.length > 0) {
        const location = locations.find((loc) => loc.ref.id === cardObj.locationRef.id)
        const locationArr = []
        if (location) {
          let parentRef = location.parentRef
          while (parentRef) {
            const parent = locations.find((loc) => loc.ref.id === parentRef.id)
            if (parent) {
              locationArr.push(parent.name)
              parentRef = parent.parentRef
            } else {
              break
            }
          }
          cardObj.location = location.name
        }
        cardObj.parentsArr = locationArr.reverse()
      }

      if (anomalies && cardObj.anomaliesRefs && cardObj.anomaliesRefs.length > 0) {
        cardObj.anomaliesIds = cardObj.anomaliesRefs.reduce((result, anomaly) => {
          result[anomaly.id] = true
          return result
        }, {})
        cardObj.anomalies = cardObj.anomaliesRefs.map((anomaly) => {
          const anomalyObj = anomalies.find((p) => p.ref.id === anomaly.id)
          if (anomalyObj) {
            const { name } = anomalyObj
            return name
          }
          return null
        })
      }
      return cardObj
    })
    .filter((el) => el !== null)

  return processedCards
}

export const filterCardsByLocation = (cardsSet, locationId) => {
  if (!cardsSet || !locationId) return null
  // filter only 'pending' and 'active' cards.
  const newSet = cardsSet.filter((cardObj) => cardObj.locationRef?.id === locationId)
  return newSet
}

export const cardsStatus = (status = 0, cardsSet) => {
  if (!cardsSet) return null
  return cardsSet.filter((cardObj) => {
    if (cardsSet.length === 0 || cardObj.status !== status || typeof cardObj.status !== 'number') {
      return null
    }
    return cardObj
  })
}
