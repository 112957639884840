import Search from '../../Filters/Search'

// search function.
export const searchBudgetsByType = (array, searchTerm) => {
  if (!array) return

  return array.filter((data) => {
    let str = `${data.name}`
    if (data.type) str = `${str} ${data.type}`

    const processStr = str.toUpperCase().includes(searchTerm.trim('^').toUpperCase())
    return processStr
  })
}

// main component.
const SearchBudgetsByType = ({ defaultValue = '', onSearch = () => {} }) => {
  // return content.
  return <Search placeholder="Buscar por tipo..." onChange={(term) => onSearch(term)} />
}

export default SearchBudgetsByType
