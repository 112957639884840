// dependencies.
import { initializeApp } from 'firebase/app'
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

// Firebase DEVELOP config.
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// helpers.
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage()

const { v4: uuidv4 } = require('uuid')

const activateUrl = `https://${firebaseConfig.authDomain}/activar`

// get superadmin user by id.
const getAdminById = async (id) => {
  return await getUserById(id, 'superadmin')
}

// get auth user by email.
const getUserByEmail = async (email) => {
  const usersRef = collection(db, 'users')
  const userQuery = query(usersRef, where('email', '==', email))
  const querySnapshot = await getDocs(userQuery)

  if (querySnapshot.docs.length === 0) {
    return false
  }

  return querySnapshot.docs[0]
}

const getUserByDocumentAndCode = async (document, firstAccessCode) => {
  const usersRef = collection(db, 'users')
  const userQuery = query(
    usersRef,
    where('document', '==', document),
    where('firstAccessCode', '==', parseInt(firstAccessCode)),
    where('pendingActivation', '==', true),
    where('isActive', '==', true)
  )
  const querySnapshot = await getDocs(userQuery)
  if (querySnapshot.docs.length === 0) {
    return false
  }
  return querySnapshot.docs[0]
}

const getUserByDocument = async (document, isActive = true) => {
  const usersRef = collection(db, 'users')

  let userQuery
  if (isActive) {
    userQuery = query(
      usersRef,
      where('document', '==', document),
      where('isActive', '==', isActive)
    )
  } else {
    userQuery = query(usersRef, where('document', '==', document))
  }

  const querySnapshot = await getDocs(userQuery)
  if (querySnapshot.docs.length === 0) {
    return false
  }
  return querySnapshot.docs[0]
}

// get auth user by id.
const getUserById = async (id, access = null, isActive = null) => {
  const usersRef = collection(db, 'users')
  let userQuery

  if (access) {
    userQuery = query(usersRef, where('uid', '==', id), where('access', '==', access))
  } else {
    userQuery = query(usersRef, where('uid', '==', id))
  }
  const querySnapshot = await getDocs(userQuery)

  return querySnapshot.docs.length > 0 ? querySnapshot.docs[0] : false
}

// --------------------------------------------------------------------------------------------- //
// AUTH FUNCTIONS                                                                                //
// --------------------------------------------------------------------------------------------- //

// SIGN-IN: firebase's signInWithEmailAndPassword().
const signInEmailPassword = async (email, password) => {
  let message = ''

  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    message = err.message
    console.error(err, err.message)
  }

  // return toast message.
  return message
}

// SIGN-IN: firebase's sendSignInLinkToEmail().
const sendSignInEmailLink = async (id, email) => {
  const actionCodeSettings = { url: `${activateUrl}?email=${email}`, handleCodeInApp: true }

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
  } catch (err) {
    console.error(err, err.message)
    await deleteDoc(doc(db, 'users', id))
    throw new Error('Ha ocurrido un error al enviar el email al usuario')
  }
}

// SIGN-IN-RESET: firebase's sendPasswordResetEmail().
const sendPasswordReset = async (email) => {
  let message = ''

  try {
    await sendPasswordResetEmail(auth, email)
    message = 'sent'
  } catch (err) {
    message = err.message
  }

  // return toast message.
  return message
}

// SIGN-UP: firebase's createUserWithEmailAndPassword().
const signUpEmailPassword = async (values) => {
  values.access = 'operator'
  try {
    await createUser(values)
    return true
  } catch (err) {
    throw new Error(err.message)
  }
}

// SIGN-UP-ACTIVATE: firebase's createUserWithEmailAndPassword().
const activateUser = async (values) => {
  const { email, password } = values
  try {
    const usersRef = collection(db, 'users')
    const userQuery = query(
      usersRef,
      where('email', '==', email),
      where('pendingActivation', '==', true)
    )
    const querySnapshot = await getDocs(userQuery)

    if (querySnapshot.docs.length === 0) {
      return console.error('No matching user')
    }

    const userDoc = querySnapshot.docs[0]
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const newUser = res.user

    await copyUser(userDoc, newUser.uid)

    // Fetch user
    const user = await getUserById(newUser.uid)

    // Set new user ref
    await setDoc(doc(db, 'users', newUser.uid), { ...user.data(), ref: user.ref })
    await updateReferencesInCollection('cards', 'responsibleRef', userDoc.ref, user.ref)
    await updateReferencesInCollection('cards', 'createdByRef', userDoc.ref, user.ref)
    await updateReferencesInCollection('cards', 'closingPerson', userDoc.ref, user.ref)
    await updateReferencesInCollection('locations', 'responsibleRef', userDoc.ref, user.ref)
    return user
  } catch (err) {
    console.error(err, err.message)
  }
}

const activateOperatorUser = async (values) => {
  const { document, password, firstAccessCode } = values
  try {
    const userDoc = await getUserByDocumentAndCode(document, firstAccessCode)
    if (userDoc) {
      const email = userDoc.data().email
      const res = await createUserWithEmailAndPassword(auth, email, password)
      const newUser = res.user
      await copyUser(userDoc, newUser.uid)

      // Fetch user
      const user = await getUserById(newUser.uid)
      // Set new user ref
      await setDoc(doc(db, 'users', newUser.uid), { ...user.data(), ref: user.ref })
      await updateReferencesInCollection('cards', 'responsibleRef', userDoc.ref, user.ref)
      await updateReferencesInCollection('cards', 'createdByRef', userDoc.ref, user.ref)
      await updateReferencesInCollection('cards', 'closingPerson', userDoc.ref, user.ref)
      await updateReferencesInCollection('locations', 'responsibleRef', userDoc.ref, user.ref)
    } else {
      console.error('No matching user')
    }
  } catch (err) {
    console.error(err, err.message)
  }
}

// --------------------------------------------------------------------------------------------- //
// CMS FUNCTIONS                                                                                 //
// --------------------------------------------------------------------------------------------- //

// CMS-CREATE-USER: generate uid, check if email exists
const createUser = async (values, allowDuplicates = false) => {
  const { firstname, lastname, document, access } = values
  const email = values.email || `${document}@ccu.uy`
  const firstAccessCode = values.firstAccessCode
  const isActive = values.isActive || false
  // generate uid.
  const uid = uuidv4()
  // check if email exists.
  const userAlreadyExists = await getUserByEmail(email)
  const documentAlreadyExists = await getUserByDocument(document, false)
  if (!allowDuplicates && (userAlreadyExists || documentAlreadyExists)) {
    throw new Error('Ya existe un usuario con el email o documento ingresado')
  }

  try {
    // use the generated uid.
    const userRef = doc(collection(db, 'users'), uid)

    await setDoc(userRef, {
      uid,
      firstname,
      lastname,
      email,
      document,
      access,
      isActive,
      pendingActivation: true,
      ...(firstAccessCode ? { firstAccessCode } : {}), // Agrega firstAccessCode solo si existe
      createdDate: new Date(),
      updatedDate: new Date(),
    })

    if (values.email && access !== 'operator') {
      await sendSignInEmailLink(uid, email)
    }
    return userRef
  } catch (err) {
    console.error(err, err.message)
    throw new Error('Ha ocurrido un error al crear el usuario')
  }
}

// CMS-COPY-USER: get the data of temp user and activate user.
const copyUser = async (oldUserDoc, newUserId) => {
  const oldUserData = oldUserDoc.data()
  oldUserData.pendingActivation = false
  oldUserData.uid = newUserId
  delete oldUserData.firstAccessCode

  // create new user in db.
  await setDoc(doc(db, 'users', newUserId), oldUserData)
  // delete user temp.
  await deleteDoc(doc(db, 'users', oldUserDoc.id))
}

const getAnomalyById = async (id) => {
  const anomalyRef = doc(db, 'anomalies', id)
  const docSnapshot = await getDoc(anomalyRef)
  if (docSnapshot.exists()) {
    return docSnapshot.data()
  }

  return false
}

const getLastCardNumberByType = async (type) => {
  const q = query(
    collection(db, 'cards'),
    where('type', '==', type),
    orderBy('number', 'desc'),
    limit(1) // Only retrieve the latest card
  )

  const querySnapshot = await getDocs(q)

  if (!querySnapshot.empty) {
    const lastCard = querySnapshot.docs[0].data()
    return lastCard.number
  } else {
    return 0 // Return 0 if no cards of the type exist
  }
}

// get operator user by id.
const getOperatorById = async (id) => {
  return await getUserById(id, 'operator')
}

const getLocationById = async (id) => {
  const locationRef = doc(db, 'locations', id)
  const docSnapshot = await getDoc(locationRef)

  if (docSnapshot.exists()) {
    return docSnapshot.data()
  }

  return false
}

const updateReferencesInCollection = async (collectionName, field, oldValue, newValue) => {
  const q = query(collection(db, collectionName), where(field, '==', oldValue))
  const querySnapshot = await getDocs(q)

  const updatePromises = []

  querySnapshot.forEach((d) => {
    const docRef = doc(db, collectionName, d.id)
    const updatePromise = updateDoc(docRef, { [field]: newValue })
    updatePromises.push(updatePromise)
  })

  await Promise.all(updatePromises)
}

const deleteUserAuth = async (uid) => {
  try {
    await deleteUser(auth, uid)
  } catch (error) {
    console.error(error)
  }
}
export {
  auth,
  db,
  storage,
  onAuthStateChanged,
  signOut,
  getAdminById,
  signInEmailPassword,
  sendSignInEmailLink,
  sendPasswordReset,
  signUpEmailPassword,
  activateUser,
  activateOperatorUser,
  createUser,
  getAnomalyById,
  getOperatorById,
  getUserById,
  getLocationById,
  getUserByDocumentAndCode,
  getUserByDocument,
  getLastCardNumberByType,
  updateReferencesInCollection,
  deleteUserAuth,
  getUserByEmail,
}
