// dependencies.
import styled from 'styled-components'
// utils.
import setBackgroundColor from '../../js/setBackgroundColor'
import { useAppSelector } from '../../js/store/hooks'
import { selectUserData } from '../../js/auth/authSelectors'
import { useDimensions } from '../../js/hooks'
import { Fragment } from 'react'
import { theme } from '../../styleguide'
// constants.
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from '../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  backgroundColor: (props) => props.background,
  display: 'flex',
  flexDirection: 'row',
  height: (props) => (props.isMobile ? `${HEADER_HEIGHT_MOBILE}px` : `${HEADER_HEIGHT_DESKTOP}px`),
  justifyContent: (props) => (props.isMobile ? 'space-between' : props.justifyContent),
  margin: 0,
  padding: (props) => (props.isMobile ? '0 8px' : '12px 16px'),
  width: '100%',
  gap: (props) => (props.isTablet ? '8px' : '16px'),
})

const Divider = styled.hr({
  backgroundColor: theme.colors.ash,
  border: 0,
  height: baseUnit * 2,
  width: 1,
})

// main component.
const Header = ({ justifyContent = 'flex-end', withDivider = false, children }) => {
  const userData = useAppSelector(selectUserData)
  const { isMobile, isTablet } = useDimensions()

  // return content.
  return (
    <Wrapper
      background={setBackgroundColor(userData.access, true)}
      isMobile={isMobile}
      isTablet={isTablet}
      justifyContent={justifyContent}
    >
      {children.length > 1
        ? children.map((child, index) => (
            <Fragment key={index}>
              {child}
              {index < children.length - 1 && withDivider && <Divider />}
            </Fragment>
          ))
        : children}
    </Wrapper>
  )
}

export default Header
