import { createSelector } from '@reduxjs/toolkit'
import { cardsStatus, filterCardsByLocation, processCardsData } from '../cardsUtils'
import { dateFormatMttr, setEndDate } from '../dateFormat'
import { getNameFull } from '../usernameUtils'
import moment from 'moment'
import cardStatusFormat from '../cardStatusFormat'
import otStatusFormat from '../reportOtStatus'
import {
  cardFilterByDates,
  cardFilterById,
  cardFilterByLocation,
  cardFilterByResponsible,
  cardFilterByType,
  cardFilterByStatus,
  cardFilterByCreatedBy,
  cardFilterByAnomaly,
  cardFilterByOtStatus,
} from '../cardFilters'
import { sortingCards } from '../cardSorting'

export const selectCardsData = (state) => state.cards.data.open

export const selectOpenCards = (state) => state.cards.data.open
export const selectClosedCards = (state) => state.cards.data.closed

export const selectAllCards = createSelector(
  [selectOpenCards, selectClosedCards],
  (openCards, closedCards) => [...openCards, ...closedCards]
)
export const selectCardsResponsiblesData = (users, locations, anomalies) =>
  createSelector([selectAllCards], (cards) => {
    return cards.map((cardObj) => {
      const responsible = cardObj.responsibleRef
        ? users.filter((d) => d.uid === cardObj.responsibleRef.id)[0]
        : null
      const author = cardObj.createdByRef
        ? users.filter((d) => d.uid === cardObj.createdByRef.id)[0]
        : null
      const anomaly = cardObj.anomaliesRefs
        ? anomalies?.filter((d) => d.name === cardObj.anomaliesRefs[0]?.id)[0]
        : null

      const extremeDatePlanDate = cardObj.extremeDatePlan ? setEndDate(cardObj.extremeDatePlan) : ''
      const realDate = cardObj.extremeDateReal
        ? new Date(cardObj.extremeDateReal.seconds * 1000)
        : ''

      const otStatus = realDate ? (extremeDatePlanDate < realDate ? 2 : 1) : 0

      const location = cardObj.locationRef
        ? locations.filter((loc) => loc.ref.id === cardObj.locationRef.id)[0]
        : null

      return {
        ...cardObj,
        responsible,
        author,
        anomaly,
        otStatus,
        extremeDatePlanDate,
        mttr: dateFormatMttr(cardObj.extremeDateReal, cardObj.createdDate),
        location: location ? location.name : '',
      }
    })
  })

export const makeSelectFilteredCardsByDate = (fromDate, toDate) =>
  createSelector([selectAllCards], (cardsSet) => {
    if (!fromDate || !toDate)
      return {
        all: cardsSet,
        pending: cardsStatus(0, cardsSet),
        assigned: cardsStatus(1, cardsSet),
        done: cardsStatus(2, cardsSet),
        cancelled: cardsStatus(3, cardsSet),
      }

    const newSet = cardsSet.filter((cardObj) =>
      moment(cardObj.createdDate.seconds * 1000).isBetween(moment(fromDate), moment(toDate))
    )

    return {
      all: newSet,
      pending: cardsStatus(0, newSet),
      assigned: cardsStatus(1, newSet),
      done: cardsStatus(2, newSet),
      cancelled: cardsStatus(3, newSet),
    }
  })

export const makeSelectCardsByStatus = (users, anomalies, locations) =>
  createSelector([selectAllCards, (state) => state.cards.filters], (cardsSet, filters) => {
    const allData = processCardsData(users, locations, anomalies, cardsSet)
    let filteredData = []
    if (filters.locationId && locations.length > 0) {
      filteredData = filterCardsByLocation(allData, filters.locationId)
    } else {
      filteredData = allData
    }
    return {
      pending: cardsStatus(0, filteredData),
      inProgress: cardsStatus(1, filteredData),
      done: cardsStatus(2, filteredData),
      cancelled: cardsStatus(3, filteredData),
    }
  })

export const selectCardsById = createSelector(
  [(array) => array, (_, searchTerm) => searchTerm],
  (array, searchTerm) => {
    if (!array) return []

    return array.filter((data) => {
      if (!data.id) return data

      const str = `${data.id}`
      const processStr = str.toUpperCase().includes(searchTerm.trim('^').toUpperCase())
      return processStr
    })
  }
)

export const selectSearchTerm = (state) => state.cards.filters.searchTerm
export const selectFromDateFilter = (state) => state.cards.filters.fromDate
export const selectToDateFilter = (state) => state.cards.filters.toDate
export const selectResponsibleFilter = (state) => state.cards.filters.responsibleId
export const selectCardTypeFilter = (state) => state.cards.filters.type
export const selectCardsSort = (state) => state.cards.filters.sort
export const selectLocationFilter = (state) => state.cards.filters.locationId
export const selectStatusFilter = (state) => state.cards.filters.status
export const selectCreatedByFilter = (state) => state.cards.filters.createdBy
export const selectFilterByAnomaly = (state) => state.cards.filters.anomaly
export const selectOtStatusFilter = (state) => state.cards.filters.otStatus

export const selectFilteredCards = (users, locations, anomalies) =>
  createSelector(
    [
      selectCardsResponsiblesData(users, locations, anomalies),
      selectSearchTerm,
      selectFromDateFilter,
      selectToDateFilter,
      selectResponsibleFilter,
      selectCardTypeFilter,
      selectLocationFilter,
      selectCardsSort,
      selectStatusFilter,
      selectCreatedByFilter,
      selectFilterByAnomaly,
      selectOtStatusFilter,
    ],
    (
      cards,
      searchTerm,
      fromDate,
      toDate,
      responsibleId,
      type,
      locationId,
      sort,
      status,
      createdBy,
      anomaly,
      otStatus
    ) => {
      // Aplica los filtros uno por uno
      let filteredCards = cards

      if (searchTerm) {
        filteredCards = cardFilterById(filteredCards, searchTerm)
      }

      if (fromDate && toDate) {
        filteredCards = cardFilterByDates(filteredCards, fromDate, toDate)
      }

      if (responsibleId) {
        filteredCards = cardFilterByResponsible(filteredCards, responsibleId)
      }

      if (type) {
        filteredCards = cardFilterByType(filteredCards, type)
      }

      if (locationId) {
        filteredCards = cardFilterByLocation(filteredCards, locationId)
      }

      if (sort) {
        filteredCards = sortingCards(filteredCards, users, locations, sort.direction, sort.key)
      }

      if (typeof status === 'number') {
        filteredCards = cardFilterByStatus(filteredCards, status)
      }

      if (createdBy) {
        filteredCards = cardFilterByCreatedBy(filteredCards, createdBy)
      }

      if (anomaly) {
        filteredCards = cardFilterByAnomaly(filteredCards, anomaly)
      }

      if (typeof otStatus === 'number') {
        filteredCards = cardFilterByOtStatus(filteredCards, otStatus)
      }

      return filteredCards
    }
  )

// Select the status of loading the cards.
export const selectCardsStatus = (state) =>
  state.cards.status.open === 'succeeded' && state.cards.status.closed === 'succeeded'
    ? 'succeeded'
    : 'loading'
export const selectOpenCardsStatus = (state) => state.cards.status.open
export const selectClosedCardsStatus = (state) => state.cards.status.closed

// Select the filters of the cards.
export const selectCardsFilters = (state) => state.cards.filters

export const selectActiveFilters = (users, locations, anomalies) =>
  createSelector(
    [
      selectSearchTerm,
      selectLocationFilter,
      selectFromDateFilter,
      selectToDateFilter,
      selectResponsibleFilter,
      selectCardTypeFilter,
      selectStatusFilter,
      selectCreatedByFilter,
      selectFilterByAnomaly,
      selectOtStatusFilter,
    ],
    (
      cardId,
      locationId,
      fromDate,
      toDate,
      responsibleId,
      cardType,
      status,
      createdBy,
      anomaly,
      otStatus
    ) => {
      const activeFilters = []

      if (cardId) {
        activeFilters.push({
          label: `N°: ${cardId}`,
          key: 'searchTerm',
        })
      }

      if (locationId) {
        const location = locations.filter((loc) => loc.ref.id === locationId)[0]
        if (location) {
          activeFilters.push({
            label: `Ubicación: ${location.name}`,
            key: 'locationId',
          })
        }
      }

      if (fromDate && toDate) {
        activeFilters.push({
          label: `Fecha: ${fromDate} - ${toDate}`,
          key: 'fromDate',
          value: {
            fromDate,
            toDate,
          },
        })
      }

      if (responsibleId) {
        const responsible = users.filter((d) => d.uid === responsibleId)[0]
        if (responsible) {
          activeFilters.push({
            label: `Responsable: ${getNameFull(responsible.firstname, responsible.lastname)}`,
            key: 'responsibleId',
          })
        }
      }

      if (cardType) {
        activeFilters.push({
          label: `Tipo: ${cardType}`,
          key: 'type',
        })
      }

      if (typeof status === 'number') {
        activeFilters.push({
          label: `Estado: ${cardStatusFormat(status)}`,
          key: 'status',
        })
      }

      if (createdBy) {
        const author = users.filter((d) => d.uid === createdBy)[0]

        activeFilters.push({
          label: `Levantada Por: ${getNameFull(author.firstname, author.lastname)}`,
          key: 'createdBy',
        })
      }

      if (anomaly) {
        const anomalyObj = anomalies?.filter((d) => d.name === anomaly)[0]

        activeFilters.push({
          label: `Anomalía: ${anomalyObj.label}`,
          key: 'anomaly',
        })
      }

      if (typeof otStatus === 'number') {
        activeFilters.push({
          label: `OTIF: ${otStatusFormat(otStatus)}`,
          key: 'otStatus',
        })
      }

      return activeFilters
    }
  )
