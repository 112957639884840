// dependencies.
import { useEffect, useState } from 'react'

import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import {
  BudgetsDesktopItem,
  BudgetsDesktopSort,
  BudgetsDesktopWrapper,
  BudgetsMobileItem,
  BudgetsMobileSort,
  BudgetsMobileWrapper,
} from '../../components/screens/Budgets'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import Header from '../../components/Layout/Header'
import { Button } from '../../components/Button'
// utils.
import notifyToast from '../../js/notifyToast'
import { db } from '../../js/firebase/firebase'
import { useDimensions } from '../../js/hooks'
// constants.
import { NEW_BUDGET_FORM } from './constants'
import FloatingButton from '../../components/Button/MobileButton'
import { budgetAdded, budgetUpdated, fetchBudgets, setFilters } from '../../js/budgets/budgetsSlice'
import {
  selectBudgetsData,
  selectBudgetsStatus,
  selectFilteredBudgets,
} from '../../js/budgets/budgetsSelectors'
import { useAppDispatch, useAppSelector } from '../../js/store/hooks'
import SearchBudgetsByType from '../../components/screens/Budgets/SearchBudgetsByType'

/**********************************/
/*                                */
/*    Admin Budgets Screen    */
/*                                */
/**********************************/

// helpers.
const selectBudgetType = [
  { label: 'Seguridad', name: 'TS' },
  { label: 'Órden y Limpieza', name: 'TO' },
  { label: 'Mantenimiento', name: 'TM' },
  { label: 'Calidad', name: 'TQ' },
  { label: 'Full Time Employees', name: 'FTE' },
]

// main component.
const AdminBudgetsScreen = ({ navMenu, navTitle }) => {
  const dispatch = useAppDispatch()
  const budgets = useAppSelector(selectBudgetsData)
  const loaded = useAppSelector(selectBudgetsStatus)

  const { isMobile } = useDimensions()

  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const filteredBudgetsSelector = selectFilteredBudgets()
  const filteredBudgets = useAppSelector(filteredBudgetsSelector)
  useEffect(() => {
    if (loaded === 'idle') dispatch(fetchBudgets())
  }, [dispatch, loaded])

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //
  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new budget modal.
  const handleNewBudgetModal = () => {
    setModalContent({
      title: 'Agregar nuevo presupuesto',
      component: (
        <Form
          items={NEW_BUDGET_FORM(selectBudgetType)}
          onClick={(obj) => handleCreateBudgetClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected budget modal.
  const handleEditBugdetModal = (obj) => {
    setModalContent({
      title: 'Actualizar presupuesto',
      component: (
        <Form
          items={NEW_BUDGET_FORM(selectBudgetType)}
          defaultValues={obj}
          onClick={(obj) => handleSaveBudgetClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new budget.
  const handleCreateBudgetClick = async (obj) => {
    obj.createdDate = new Date()
    obj.updatedDate = new Date()

    await addDoc(collection(db, 'budgets'), obj)
      .then(async (budgetRef) => {
        await setDoc(budgetRef, { ref: budgetRef }, { merge: true })
        notifyToast('Presupuesto creado correctamente', 'success')
        const newBudgetDoc = await getDoc(budgetRef)
        const newBudget = { id: newBudgetDoc.id, ...newBudgetDoc.data() }
        dispatch(budgetAdded(newBudget))
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // handle budget information update.
  const handleSaveBudgetClick = async (obj) => {
    obj.updatedDate = new Date()
    const budgetRef = doc(db, 'budgets', obj.ref.id)
    await setDoc(budgetRef, { ...obj })
      .then(async () => {
        const updatedBudgetDoc = await getDoc(budgetRef)
        const updatedBudget = { id: updatedBudgetDoc.id, ...updatedBudgetDoc.data() }
        dispatch(budgetUpdated(updatedBudget))

        notifyToast('Presupuesto guardado correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <Header justifyContent="space-between">
          <SearchBudgetsByType
            defaultValue={budgets}
            onSearch={(term) => {
              dispatch(setFilters({ searchTerm: term }))
            }}
          />
          {isMobile ? (
            <FloatingButton onClick={() => handleNewBudgetModal()} />
          ) : (
            <Button onClick={() => handleNewBudgetModal()}>Agregar Presupuesto</Button>
          )}
        </Header>

        {loaded === 'succeeded' ? (
          isMobile ? (
            <BudgetsMobileWrapper isEmpty={!!filteredBudgets && !filteredBudgets.length}>
              <BudgetsMobileSort />

              {filteredBudgets.map((budget, i) => (
                <BudgetsMobileItem
                  key={budget.id}
                  item={budget}
                  isEven={i % 2 === 0}
                  onEdit={() => handleEditBugdetModal(budget)}
                />
              ))}
            </BudgetsMobileWrapper>
          ) : (
            <BudgetsDesktopWrapper isEmpty={!!filteredBudgets && !filteredBudgets.length}>
              <BudgetsDesktopSort />

              {filteredBudgets.map((budget, i) => (
                <BudgetsDesktopItem
                  key={budget.id}
                  item={budget}
                  isEven={i % 2 === 0}
                  onEdit={() => handleEditBugdetModal(budget)}
                />
              ))}
            </BudgetsDesktopWrapper>
          )
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminBudgetsScreen
