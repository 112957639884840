// dependencies.
import { Fragment } from 'react'
import styled from 'styled-components'
// components.
import Headline from '../Typography/Headline'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'
import { Icon } from '../Icon'

/************************/
/*                      */
/*    Toggle Filters    */
/*                      */
/************************/

// helpers
const HEADER_HEIGHT = 72

const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.aside({
  alignItems: 'flex-start',
  background: theme.colors.white,
  borderRight: `1px solid ${theme.colors.ash}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  left: (props) =>
    props.isMobile
      ? props.isActive
        ? 0
        : `${baseUnit * -20}px`
      : props.condensedNav
        ? '56px'
        : '168px',
  maxHeight: '100vh',
  overflowY: 'auto',
  padding: 0,
  position: 'fixed',
  top: 0,
  transition: (props) => (props.isMobile ? 'left 200ms ease' : 'all 200ms ease'),
  width: (props) => (props.isMobile ? '100%' : props.isActive ? `${baseUnit * 20}px` : 0),
  zIndex: 10,
})

const ScrollWrapper = styled.div({
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: theme.colors.white,
  justifyContent: 'flex-start',
  padding: 0,
  width: '100%',
})

const Header = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.ash}`,
  display: 'flex',
  height: HEADER_HEIGHT,
  justifyContent: 'flex-start',
  padding: (props) =>
    props.isActive ? `${baseUnit * 1.75}px ${baseUnit}px` : `${baseUnit * 1.75}px 0`,
  width: '100%',
})

const Content = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit,
  height: '100%',
  justifyContent: 'flex-start',
  padding: (props) => (props.isActive ? `${baseUnit}px` : `${baseUnit}px 0`),
  width: '100%',
})

const Divider = styled.hr({
  backgroundColor: theme.opacity.ash50,
  border: 0,
  height: 1,
  width: '100%',
})

const CloseElement = styled.button({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
})

// main component.
const FiltersAside = ({
  isActive = false,
  condensedNav = false,
  withDivider = true,
  children,
  onClose = () => {},
}) => {
  const { isMobile } = useDimensions()

  const handleClose = () => {
    onClose(!isActive)
  }
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  if (!children) return null

  // return content.
  return (
    <Wrapper isMobile={isMobile} isActive={isActive} condensedNav={condensedNav}>
      <ScrollWrapper>
        <Header isActive={isActive}>
          <Headline>Filtrar resultados</Headline>
          <CloseElement onClick={handleClose}>
            <Icon name="close" size={24} />
          </CloseElement>
        </Header>

        <Content isActive={isActive}>
          {children.length > 1
            ? children.map((child, index) => (
                <Fragment key={index}>
                  {child}
                  {index < children.length - 1 && withDivider && <Divider />}
                </Fragment>
              ))
            : children}
        </Content>
      </ScrollWrapper>
    </Wrapper>
  )
}

export default FiltersAside
