// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
// constants.
import { COLUMN_LAYOUT } from './Item'
import { TABLE_HEADER_HEIGHT } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  borderBottom: `2px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: TABLE_HEADER_HEIGHT,
  maxWidth: '100%',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 8,
})

const Cell = styled.div({
  alignItems: 'center',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  cursor: 'default',
  display: 'flex',
  flexDirection: 'row',
  gap: baseUnit / 2,
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 2}px ${baseUnit}px`,
  width: (props) => `${props.width}%`,
  zIndex: 0,
})

const StyledSortLabel = styled(Body)({
  userSelect: 'none',
})

// main component.
const BudgetsMobileSort = () => {
  const cellWidth = 10 // 10% (100% / 10 columns)

  // return content.
  return (
    <Wrapper>
      {COLUMN_LAYOUT.map(({ label, columns }) => (
        <Cell key={label} width={cellWidth * columns}>
          <StyledSortLabel type="caption" color="grey" nowrap>
            {label}
          </StyledSortLabel>
        </Cell>
      ))}
    </Wrapper>
  )
}

export default BudgetsMobileSort
