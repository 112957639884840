// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
// utils.
import { theme, constants } from '../../styleguide'

/*****************************/
/*                           */
/*    Table Header Module    */
/*                           */
/*****************************/

// helpers.
const { TABLE_CELL_MIN_WIDTH, TABLE_CELL_MID_WIDTH, TABLE_CELL_MAX_WIDTH } = constants

// partials.
const HeaderWrapper = styled.div(
  {
    backgroundColor: theme.colors.white,
    borderBottom: `2px solid ${theme.colors.ash}`,
    display: 'flex',
    margin: 0,
    minHeight: '32px',
    minWidth: '100%',
    width: 'fit-content',
  },
  `@media print { min-width: unset; }`
)

const HeaderItem = styled(Body)(
  {
    borderRight: (props) => !props.isLast && `1px solid ${theme.colors.ash}`,
    flexGrow: (props) => (props.grow ? 'grow' : 0),
    maxWidth: (props) =>
      props.grow ? (props.grow === 2 ? '100%' : TABLE_CELL_MID_WIDTH) : TABLE_CELL_MIN_WIDTH,
    minWidth: (props) =>
      props.grow
        ? props.grow === 2
          ? TABLE_CELL_MAX_WIDTH
          : TABLE_CELL_MID_WIDTH
        : TABLE_CELL_MIN_WIDTH,
    padding: '7px 0',
    width: (props) => (props.grow ? '100%' : 'auto'),
  },
  `@media print {
    max-width: 108px;
    min-width: 108px;
    padding: 2px;

    &#action-cell {
      display: none;
    }
  }`
)

// main component.
const TableHeader = ({ items }) => {
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show if there's items.
  if (!items) return null

  // return content.
  return (
    <HeaderWrapper>
      {items.map((head, h) => (
        <HeaderItem
          key={h}
          id={h === items.length - 1 && head.label === 'Acciones' ? 'action-cell' : null}
          type="caption"
          color="grey"
          align="center"
          grow={head.grow}
          isLast={h === items.length - 1}
        >
          {head.label}
        </HeaderItem>
      ))}
    </HeaderWrapper>
  )
}

export default TableHeader
