export const sortingCards = (items, responsibles, locations, direction, sortDbKey) => {
  const sortedData = [...items]
  if (direction === null) return sortedData

  const isAscending = direction === 'caret-down'

  sortedData.sort((a, b) => {
    let aValue
    let bValue
    switch (sortDbKey) {
      case 'responsibleRef': {
        const aResponsible = responsibles.find(
          (responsible) => responsible.uid === a.responsibleRef?.id
        )
        const bResponsible = responsibles.find(
          (responsible) => responsible.uid === b.responsibleRef?.id
        )
        aValue = `${aResponsible?.firstname || 'zzz'} ${
          aResponsible?.lastName || 'zzz'
        }`.toLowerCase()
        bValue = `${bResponsible?.firstname || 'zzz'} ${
          bResponsible?.lastName || 'zzz'
        }`.toLowerCase()
        break
      }
      case 'createdByRef': {
        const aResponsible = responsibles.find(
          (responsible) => responsible.uid === a.createdByRef?.id
        )
        const bResponsible = responsibles.find(
          (responsible) => responsible.uid === b.createdByRef?.id
        )
        aValue = `${aResponsible?.firstname || 'zzz'} ${
          aResponsible?.lastName || 'zzz'
        }`.toLowerCase()
        bValue = `${bResponsible?.firstname || 'zzz'} ${
          bResponsible?.lastName || 'zzz'
        }`.toLowerCase()
        break
      }
      case 'createdDate':
        aValue = a.createdDate
        bValue = b.createdDate
        break
      case 'extremeDatePlanDate':
        aValue = a.extremeDatePlanDate || (isAscending ? Infinity : -Infinity)
        bValue = b.extremeDatePlanDate || (isAscending ? Infinity : -Infinity)
        break
      case 'extremeDateReal':
        aValue = a.extremeDateReal || (isAscending ? Infinity : -Infinity)
        bValue = b.extremeDateReal || (isAscending ? Infinity : -Infinity)
        break
      case 'locationRef':
        aValue = locations.find((location) => location.id === a.locationRef?.id)?.name
        bValue = locations.find((location) => location.id === b.locationRef?.id)?.name
        break
      default:
        aValue = a[sortDbKey]
        bValue = b[sortDbKey]
    }
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return isAscending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
    } else {
      return isAscending ? aValue - bValue : bValue - aValue
    }
  })

  return sortedData
}
