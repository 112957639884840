// dependencies.
import styled from 'styled-components'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'

/**********************************/
/*                                */
/*    Paragraph Text Component    */
/*                                */
/**********************************/

// helpers.
const fontSize = (size) => {
  switch (size) {
    case 'larger':
      return `${theme.fontSize.x18}px`
    case 'smaller':
      return `${theme.fontSize.x14}px`
    case 'smallest':
      return `${theme.fontSize.x12}px`
    default:
      return `${theme.fontSize.x16}px`
  }
}

const lineHeight = (size) => {
  switch (size) {
    case 'larger':
      return `${theme.lineHeight.x18}px`
    case 'smaller':
      return `${theme.lineHeight.x14}px`
    case 'smallest':
      return `${theme.lineHeight.x12}px`
    default:
      return `${theme.lineHeight.x16}px`
  }
}

// partials.
const Wrapper = styled.p(
  {
    color: (props) => props.color,
    fontSize: (props) =>
      props.type === 'caption' ? `${theme.fontSize.x12}px` : fontSize(props.size),
    fontWeight: (props) => (props.weight || props.type === 'caption' ? 'bold' : 'normal'),
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: (props) => (props.type === 'caption' ? '0.02em' : 0),
    lineHeight: (props) =>
      props.type === 'caption' ? `${theme.lineHeight.x14}px` : lineHeight(props.size),
    overflowX: (props) => (props.nowrap ? 'hidden' : 'auto'),
    textAlign: (props) => props.align,
    textOverflow: (props) => (props.nowrap ? 'ellipsis' : 'unset'),
    whiteSpace: (props) => (props.nowrap ? 'nowrap' : 'normal'),
  },
  `@media print { font-size: 11px; }`
)

// main component.
const Body = ({
  id,
  color,
  type,
  size,
  align = 'left',
  weight,
  nowrap,
  className,
  setContent = false,
  children,
}) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const textColor = styleguideHasKey(color, colors, 'black')

  // build props object.
  const props = {
    id,
    align,
    className,
    color: colors[textColor],
    size,
    type,
    weight,
    nowrap,
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return !setContent ? (
    <Wrapper {...props}>{children}</Wrapper>
  ) : (
    <Wrapper {...props} dangerouslySetInnerHTML={{ __html: children }} />
  )
}

export default Body
