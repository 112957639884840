// dependencies.
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
// components.
import App from './App'
import { Provider } from 'react-redux'
import store from './js/store/store'

// define the root for the app.
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

// render app.
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
)
