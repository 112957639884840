// dependencies.
import styled from 'styled-components'
// components.
import { Body, Label } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import { theme } from '../../../../styleguide'

// helpers
const baseUnit = theme.fontSize.x16

const getCardInfo = {
  TM: theme.cards.tm,
  TO: theme.cards.to,
  TQ: theme.cards.tq,
  TS: theme.cards.ts,
}

export const COLUMN_LAYOUT = [
  { label: 'Nombre', columns: 5.5 },
  { label: 'Tipo', columns: 2 },
  { label: 'Acciones', columns: 2.5 },
]

// partials.
const Wrapper = styled.li({
  backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
  borderBottom: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: baseUnit * 2.5,
  transition: 'background 200ms ease',
  width: '100%',
  '&:focus': {
    backgroundColor: theme.opacity.primary20,
    outline: 'none',
  },
})

const Cell = styled.div({
  alignItems: 'center',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `0 ${baseUnit / 2}px`,
  width: (props) => `${props.width}%`,
})

const StyledType = styled(Body)({
  '&:before': {
    backgroundColor: (props) => props.background,
    borderRadius: baseUnit / 4,
    content: '""',
    display: 'inline-block',
    filter: `drop-shadow(${theme.shadows.smaller})`,
    height: baseUnit / 2,
    marginRight: baseUnit / 4,
    width: baseUnit / 2,
  },
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

const StyledEdit = styled(Label)({
  background: (props) => (props.disabled ? theme.colors.smoke : theme.colors.warning),
  border: (props) =>
    props.disabled ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.warning}`,
  borderRadius: baseUnit / 2 - 2,
  display: 'flex',
  fontSize: theme.fontSize.x14,
  gap: 4,
  padding: '3px 6px 3px 8px',
  width: 'fit-content',
})

// main component.
const AnomaliesMobileItem = ({ item, isEven, onEdit }) => {
  // only show if there's items.
  if (!item) return null

  console.warn(item)

  const { name, type } = item

  const enableEdit = Boolean(onEdit)

  const cellWidth = 10 // 10% (100% / 10 columns)

  // return content.
  return (
    <Wrapper isEven={isEven} tabIndex={0}>
      <Cell width={cellWidth * COLUMN_LAYOUT[0].columns}>
        <StyledBody size="smaller" align="center">
          {name}
        </StyledBody>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT[1].columns}>
        <StyledType background={getCardInfo[type]} type="caption" nowrap>
          {type}
        </StyledType>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT[2].columns}>
        <StyledEdit color="white" disabled={!enableEdit} onClick={enableEdit && onEdit}>
          Editar
          <Icon name="edit" size={16} color={!enableEdit ? 'ash' : 'white'} />
        </StyledEdit>
      </Cell>
    </Wrapper>
  )
}

export default AnomaliesMobileItem
