import React, { useState } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import { theme } from '../../styleguide'
import { Icon } from '../Icon'

Modal.setAppElement('#root')
const MediaContent = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  justifyContent: 'flex-start',
  width: '100%',
})

const MediaItem = styled.div({
  alignItems: 'center',
  background: theme.opacity.black20,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  padding: '8px',
})

const CloseElement = styled.button({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  position: 'absolute',
  top: '30px',
  right: '30px',
  zIndex: '10',
})

const FileModal = ({ media, videoMedia }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)

  const openModalWithContent = (content) => {
    setModalContent(content)
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <MediaContent>
      {media.length > 0 &&
        media.map((media, i) => (
          <MediaItem
            key={i}
            onClick={() => openModalWithContent(<img src={media} alt="Thumbnail" />)}
          >
            <img
              src={media}
              alt="Thumbnail"
              style={{
                maxWidth: '70px',
                maxHeight: '70px',
                width: 'auto',
                height: 'auto',
                cursor: 'pointer',
              }}
            />
          </MediaItem>
        ))}

      {videoMedia.length > 0 &&
        videoMedia.map((media, i) => (
          <MediaItem key={i} onClick={() => openModalWithContent(<video src={media} controls />)}>
            <video
              src={media}
              alt="Thumbnail"
              style={{
                maxWidth: '70px',
                maxHeight: '70px',
                width: 'auto',
                height: 'auto',
                cursor: 'pointer',
              }}
            />
          </MediaItem>
        ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Media in fullscreen"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            border: 'none',
            padding: '20px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '90vw',
            maxHeight: '90vh',
          },
          overlay: {
            zIndex: '9999',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
      >
        <CloseElement onClick={() => closeModal()}>
          <Icon name="close" size={24} color="white" />
        </CloseElement>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {modalContent &&
            React.cloneElement(modalContent, { style: { maxWidth: '100%', maxHeight: '100%' } })}
        </div>
      </Modal>
    </MediaContent>
  )
}

export default FileModal
