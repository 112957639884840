// dependencies.
import React from 'react'
import { Helmet } from 'react-helmet-async'
// components.
import GlobalStyle from './GlobalStyle'
// utils.
import { useDimensions } from '../../js/hooks'
// constants.
import { PUBLIC, SITE_URL, TWITTER_USERNAME } from '../../constants'

// helpers.
const faviconImages = [
  {
    rel: 'shortcut icon',
    type: 'image/x-icon',
    href: '/favicon.ico',
  },
  {
    rel: 'apple-touch-icon',
    href: 'favicon/apple-icon.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '57x57',
    href: '/favicon/apple-icon-57x57.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '72x72',
    href: '/favicon/apple-icon-72x72.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '76x76',
    href: '/favicon/apple-icon-76x76.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '114x114',
    href: '/favicon/apple-icon-114x114.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '120x120',
    href: '/favicon/apple-icon-120x120.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '144x144',
    href: '/favicon/apple-icon-144x144.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '152x152',
    href: '/favicon/apple-icon-152x152.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/favicon/apple-icon-180x180.png',
  },

  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon/favicon-16x16.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '96x96',
    href: '/favicon/favicon-96x96.png',
  },
]

// main components.
const Head = ({
  path,
  title,
  description,
  keywords,
  robots,
  color,
  thumbnail,
  nocache,
  noindex,
  nocanonical,
  children,
}) => {
  // hooks.
  const { height } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // build canonical paths.
  const getPath = () => {
    if (['', 'index'].includes(path)) return `${SITE_URL}/`
    return `${SITE_URL}/${path}/`
  }

  const canonicalUrl = getPath()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="robots" content={noindex ? 'noindex' : 'index'} />
        <meta name="googlebot-news" content="noindex" />
        <meta name="thumbnail" content={thumbnail} />
        <meta name="application-name" content={title} />
        {!nocanonical && <link rel="canonical" href={canonicalUrl} />}
        {/* iOS */}
        <meta name="apple-mobile-web-app-title" content={title} />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={`@${TWITTER_USERNAME}`} />
        <meta name="twitter:creator" content={`@${TWITTER_USERNAME}`} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image:src" content={thumbnail} />
        {/* Facebook OG */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={thumbnail} />
        <meta property="og:image:secure_url" content={thumbnail} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="3200" />
        <meta property="og:image:height" content="1674" />
        {/* No Cache. 'key' prevents console warning */}
        {nocache && [
          <meta key={1} httpEquiv="cache-control" content="no-cache, no-store, must-revalidate" />,
          <meta key={2} httpEquiv="expires" content="0" />,
          <meta key={3} httpEquiv="pragma" content="no-cache" />,
        ]}
        {/* Favicon */}
        {faviconImages.map(({ rel, type, sizes, href }, i) => (
          <link key={i} rel={rel} type={type} sizes={sizes} href={`${PUBLIC}${href}`} />
        ))}
        <link rel="manifest" href={`${PUBLIC}/favicon/manifest.json`} />
        <meta name="msapplication-config" content={`${PUBLIC}/favicon/browserconfig.xml`} />
        {/* Other meta */}
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content={color} />
        <meta name="msapplication-TileColor" content={color} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />

        {/* Any extra <head> elements */}
        {children}
      </Helmet>

      {/* Global styles */}
      <GlobalStyle background={color} height={height} />
    </>
  )
}

export default Head
