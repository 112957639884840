// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import { Input } from '../Form'
// utils.
import { useDimensions } from '../../js/hooks'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: (props) => (props.isTablet ? '8px' : '16px'),
  width: (props) => (props.column ? '100%' : 'fit-content'),
})

// main component.
const FilterCardType = ({
  label,
  onTypeFilter = () => {},
  placeholder = 'Tipo de Tarjeta',
  defaultValue = '',
}) => {
  // contexts.
  // hooks.
  const { isMobile, isTablet } = useDimensions()

  const typeOptions = {
    Mantenimiento: 'TM',
    'Orden y Limpieza': 'TO',
    Calidad: 'TQ',
    Seguridad: 'TS',
  }

  const optionEntries = Object.entries(typeOptions)
  const options = optionEntries.map(([name, value], index) => ({
    name: value,
    label: name,
  }))

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // return content.

  return (
    <Wrapper column={!!label} isTablet={isTablet}>
      {label && (
        <Body type="caption" color="grey">
          {label}
        </Body>
      )}
      <Input
        isMobile={isMobile}
        options={options}
        name="type"
        iconL="copy"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(e) => onTypeFilter(e)}
      />
    </Wrapper>
  )
}

export default FilterCardType
