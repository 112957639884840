// dependencies.
import styled from 'styled-components'
// components.
import Headline from './Headline'
import { Button } from '../../components/Button'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

/***********************/
/*                     */
/*    Module Header    */
/*                     */
/***********************/

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderBottom: `2px solid ${theme.colors.ash}`,
  display: 'flex',
  flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
  margin: '0 -16px',
  padding: '16px',
  width: 'calc(100% + 32px)',
})

// main component.
const Header = ({ label, onClick, children }) => {
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper isMobile={isMobile}>
      <Headline size="smaller">{children}</Headline>

      {onClick && <Button onClick={onClick}>{label}</Button>}
    </Wrapper>
  )
}

export default Header
