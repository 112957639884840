import moment from 'moment'

export const cardFilterByDates = (array, fromDate, toDate) => {
  if (!array) return
  return array.filter(
    (card) =>
      moment(card.createdDate.seconds * 1000).isSameOrAfter(moment(fromDate).startOf('day')) &&
      moment(card.createdDate.seconds * 1000).isSameOrBefore(moment(toDate).endOf('day'))
  )
}

export const cardFilterById = (array, searchTerm) => {
  if (!array) return

  return array.filter((card) =>
    card.id.toString().toUpperCase().includes(searchTerm.trim('^').toUpperCase())
  )
}

export const cardFilterByLocation = (array, location) => {
  if (!array) return []

  return array
    .filter((item) => {
      if (location === item?.locationRef?.id) return item
      return null
    })
    .filter((el) => el !== null)
}

export const cardFilterByResponsible = (array, responsibleId) => {
  if (!array) return

  return array
    .filter((item) => {
      if (responsibleId === item?.responsible?.id) return item
      return null
    })
    .filter((el) => el !== null)
}

export const cardFilterByType = (array, type) => {
  if (!array) return
  return array
    .filter((item) => {
      if (item.type === type) return item
      return null
    })
    .filter((el) => el !== null)
}

export const cardFilterByStatus = (array, status) => {
  if (!array) return
  return array
    .filter((item) => {
      if (item?.status === status) return item
      return null
    })
    .filter((el) => el !== null)
}

export const cardFilterByCreatedBy = (array, createdBy) => {
  if (!array) return
  return array
    .filter((item) => {
      if (item?.createdByRef?.id === createdBy) return item
      return null
    })
    .filter((el) => el !== null)
}

export const cardFilterByAnomaly = (array, anomaly) => {
  if (!array) return
  return array
    .filter((item) => {
      if (item?.anomaliesRefs?.some((ref) => ref.id === anomaly)) {
        return item
      }
      return null
    })
    .filter((el) => el !== null)
}

export const cardFilterByOtStatus = (array, otStatus) => {
  if (!array) return
  return array
    .filter((item) => {
      if (item?.otStatus === otStatus) return item
      return null
    })
    .filter((el) => el !== null)
}
