// dependencies.
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// components.
import Input from '../Form/Input'
import { Icon } from '../Icon'
import { Body, Label } from '../Typography'
// utils.
import { theme } from '../../styleguide'
import { useWindowEvent } from '../../js/hooks'

/**************************/
/*                        */
/*    Select Component    */
/*                        */
/**************************/

// helpers.
const search = (array, searchTerm) => {
  const result = []

  const searchInObject = (obj) => {
    if (obj.name && obj.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      result.push({ ref: obj.ref, name: obj.name })
    }

    if (obj.children && obj.children.length > 0) {
      obj.children.forEach((child) => {
        searchInObject(child)
      })
    }
  }

  array.forEach((obj) => {
    searchInObject(obj)
  })

  return result
}

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: 0,
  position: 'relative',
  width: '100%',
  maxWidth: '400px',
})

const Close = styled.button({
  background: 'none',
  border: 'none',
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  height: '48px',
  justifyContent: 'center',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '48px',
  zIndex: 101,
})

const StyledInput = styled(Input)({
  width: '100%',
})

const Menu = styled.div({
  background: theme.colors.white,
  border: `1px solid ${theme.colors.ash}}`,
  borderRadius: '24px',
  boxShadow: theme.shadows.normal,
  height: 'auto',
  left: 0,
  minWidth: '100%',
  padding: '16px 16px 16px 0',
  position: 'absolute',
  top: 'calc(100% + 4px)',
  width: 'fit-content',
  zIndex: 100,
})

const List = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  width: '100%',
})

const Item = styled.li({
  display: 'block',
  paddingLeft: '16px',
  width: '100%',
})

const ItemContainer = styled.span({
  alignItems: 'center',
  borderBottom: (props) => (props.isLast ? 'none' : `1px solid ${theme.colors.ash}}`),
  display: 'flex',
  gap: '16px',
  height: '48px',
  justifyContent: 'space-between',
  padding: '8px 0',
})

const ItemLabel = styled(Label)({
  width: '100%',
})

const Toggle = styled.button({
  background: (props) => (props.isOpen ? theme.colors.primary : theme.colors.ash),
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'block',
  height: '32px',
  minWidth: '32px',
  width: '32px',
})

const MenuItem = ({ data, isLast, isOpen, onClick, children }) => {
  const { name, ref } = data
  const urlObject = new URL(window.location.href)
  const pathname = urlObject.pathname
  let toPath = ''
  if (pathname.startsWith('/admin', 0)) {
    if (pathname.startsWith('/admin/ubicaciones', 0)) {
      toPath = `/admin/ubicaciones/${ref.id}`
    } else {
      toPath = `/admin/${ref.id}`
    }
  } else if (pathname.startsWith('/funcionario', 0)) {
    toPath = `/funcionario/${ref.id}`
  }

  return (
    <Item isOpen={isOpen}>
      <ItemContainer isLast={isLast}>
        {data.children?.length > 0 ? (
          <>
            <Toggle isOpen={isOpen} onClick={onClick}>
              <Icon
                name={isOpen ? 'caret-up' : 'caret-down'}
                size={16}
                color={isOpen ? 'white' : 'asphalt'}
              />
            </Toggle>
            <ItemLabel to={toPath}>{name}</ItemLabel>
          </>
        ) : (
          <ItemLabel to={toPath}>{name}</ItemLabel>
        )}
      </ItemContainer>
      {children}
    </Item>
  )
}

const BranchSelect = ({ label, placeholder = 'Filtro de ubicaciones', items = [], value = '' }) => {
  const componentRef = useRef(null)

  const [menuOpen, setMenuOpen] = useState(false)
  const [lvl0Open, setLvl0Open] = useState('')
  const [lvl1Open, setLvl1Open] = useState('')
  const [lvl2Open, setLvl2Open] = useState('')
  const [lvl3Open, setLvl3Open] = useState('')
  const [lvl4Open, setLvl4Open] = useState('')
  const [searched, setSearched] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term = value) => {
    term !== '' ? setSearched(search(items, term)) : setSearched([])
  }

  const handleOutsideClick = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setMenuOpen(false)
    }
  }

  useWindowEvent('mousedown', handleOutsideClick)

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // reset values.
  const handleToggleClick = (level, id) => {
    switch (level) {
      case lvl0Open:
        return lvl0Open === id ? setLvl0Open('') : setLvl0Open(id)
      case lvl1Open:
        return lvl1Open === id ? setLvl1Open('') : setLvl1Open(id)
      case lvl2Open:
        return lvl2Open === id ? setLvl2Open('') : setLvl2Open(id)
      case lvl3Open:
        return lvl3Open === id ? setLvl3Open('') : setLvl3Open(id)
      case lvl4Open:
        return lvl4Open === id ? setLvl4Open('') : setLvl4Open(id)
      default:
        return null
    }
  }

  // close modal.
  const handleCloseModal = () => {
    setLvl0Open('')
    setLvl1Open('')
    setLvl2Open('')
    setLvl3Open('')
    setLvl4Open('')
    setMenuOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [items])

  // only show content if there's items.
  if (!items) return null

  // return content.
  return (
    <Wrapper ref={componentRef}>
      {label && (
        <Body type="caption" color="grey">
          {label}
        </Body>
      )}
      <div onClick={() => setMenuOpen(true)}>
        <StyledInput
          iconL="search"
          name="location-filter"
          placeholder={placeholder}
          defaultValue={value}
          onChange={(term) => handleSearch(term)}
        />
      </div>

      {menuOpen && items?.length > 0 && (
        <>
          <Close onClick={() => handleCloseModal()}>
            <Icon name="close" size={16} color="black" />
          </Close>

          <Menu>
            <List>
              {searched?.length > 0
                ? searched.map((searchItem, i) => (
                    <MenuItem
                      key={searchItem.ref.id}
                      data={searchItem}
                      isLast={searched.length === i + 1}
                    />
                  ))
                : items.map((lvl0, lvl0i) => (
                    <MenuItem
                      key={lvl0.ref.id}
                      data={lvl0}
                      isLast={items.length === lvl0i + 1}
                      isOpen={lvl0Open === lvl0.ref.id}
                      onClick={() => handleToggleClick(lvl0Open, lvl0.ref.id)}
                    >
                      {lvl0Open === lvl0.ref.id && (
                        <List>
                          {lvl0.children.map((lvl1, lvl1i) => (
                            <MenuItem
                              key={lvl1.ref.id}
                              data={lvl1}
                              isOpen={lvl1Open === lvl1.ref.id}
                              onClick={() => handleToggleClick(lvl1Open, lvl1.ref.id)}
                            >
                              {lvl1Open === lvl1.ref.id && (
                                <List>
                                  {lvl1.children.map((lvl2, lvl2i) => (
                                    <MenuItem
                                      key={lvl2.ref.id}
                                      data={lvl2}
                                      isOpen={lvl2Open === lvl2.ref.id}
                                      onClick={() => handleToggleClick(lvl2Open, lvl2.ref.id)}
                                    >
                                      {lvl2Open === lvl2.ref.id && (
                                        <List>
                                          {lvl2.children.map((lvl3, lvl3i) => (
                                            <MenuItem
                                              key={lvl3.ref.id}
                                              data={lvl3}
                                              isOpen={lvl3Open === lvl3.ref.id}
                                              onClick={() =>
                                                handleToggleClick(lvl3Open, lvl3.ref.id)
                                              }
                                            >
                                              {lvl3Open === lvl3.ref.id && (
                                                <List>
                                                  {lvl3.children.map((lvl4, lvl4i) => (
                                                    <MenuItem
                                                      key={lvl4.ref.id}
                                                      data={lvl4}
                                                      isOpen={lvl4Open === lvl4.ref.id}
                                                      onClick={() =>
                                                        handleToggleClick(lvl4Open, lvl4.ref.id)
                                                      }
                                                    >
                                                      {lvl4Open === lvl4.ref.id && (
                                                        <List>
                                                          {lvl4.children.map((lvl5, lvl5i) => (
                                                            <MenuItem
                                                              key={lvl5.ref.id}
                                                              data={lvl5}
                                                            />
                                                          ))}
                                                        </List>
                                                      )}
                                                    </MenuItem>
                                                  ))}
                                                </List>
                                              )}
                                            </MenuItem>
                                          ))}
                                        </List>
                                      )}
                                    </MenuItem>
                                  ))}
                                </List>
                              )}
                            </MenuItem>
                          ))}
                        </List>
                      )}
                    </MenuItem>
                  ))}
            </List>
          </Menu>
        </>
      )}
    </Wrapper>
  )
}

export default BranchSelect
