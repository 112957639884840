// dependencies.
import styled from 'styled-components'
// components.
import Body from './Body'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'

/*****************************/
/*                           */
/*    Pill Text Component    */
/*                           */
/*****************************/

// partials.
const Wrapper = styled.span(
  {
    background: (props) => props.background,
    border: (props) => `1px solid ${props.color}`,
    borderRadius: '4px',
    display: 'block',
    padding: '3px 8px',
    userSelect: 'none',
    width: (props) => props.width || 'fit-content',
  },
  `@media print { padding: 1px 5px; }`
)

// main component.
const Pill = ({ type = 'alert', align = 'center', width, className, children }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  const parseTypeProp = (opacity) => {
    if (typeof type === 'number' && type === 0) return opacity ? 'alert10' : 'alert'
    if (typeof type === 'number' && type === 1) return opacity ? 'warning10' : 'warning'
    if (typeof type === 'number' && type === 2) return opacity ? 'success10' : 'success'
    return opacity ? `${type}10` : type
  }

  // make sure background is included in the library.
  const { colors, opacity } = theme
  const textColor = styleguideHasKey(parseTypeProp(), colors, 'info')
  const backgroundColor = styleguideHasKey(parseTypeProp(true), opacity, 'info10')

  // build props object.
  const props = {
    className,
    color: colors[textColor],
    background: opacity[backgroundColor],
    width,
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper {...props}>
      <Body size="smallest" weight="bold" align={align} color={parseTypeProp()} nowrap>
        {children}
      </Body>
    </Wrapper>
  )
}

export default Pill
